import styled from '@emotion/styled'
import {
  AspectRatio,
  Box,
  Container,
  Skeleton,
  typographyClasses,
} from '@mui/joy'
import React, { PropsWithChildren, ReactNode, useState } from 'react'

import HeroImageWrapper from './HeroImageWrapper'
import HeroVideoWrapper from './HeroVideoWrapper'

interface IProps {
  isFocused: boolean
  hasFocused: boolean
  setHasFocused: (focused: boolean) => void
  fullHeight?: boolean
  heroWebm?: string
  heroImage?: string
  heroElement?: ReactNode
  heroHeaderImage?: string
  heroVideo?: string
  isReversed?: boolean
  noImageBorder?: boolean
}

const TwoSidedLayout = ({
  children,
  isFocused,
  hasFocused,
  setHasFocused,
  fullHeight,
  heroWebm,
  heroImage,
  heroElement,
  heroHeaderImage,
  heroVideo,
  isReversed,
  noImageBorder,
}: PropsWithChildren<IProps>) => {
  const [isImageLoading, setIsImageLoading] = useState(true)

  const isMobile = window.innerWidth < 834

  return (
    <Container
      sx={(theme) => ({
        position: 'relative',
        minHeight: fullHeight ? '100vh' : '',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 10,
        gap: 4,
        [theme.breakpoints.down(834)]: {
          justifyContent: 'space-evenly',
        },
        [theme.breakpoints.up(834)]: {
          flexDirection: isReversed ? 'row-reverse' : 'row',
          gap: 6,
        },
        [theme.breakpoints.up(1199)]: {
          gap: 12,
          flexDirection: isReversed ? 'row-reverse' : 'row',
        },
      })}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="1rem"
        maxWidth="50ch"
        minWidth="50%"
        textAlign="center"
        flexShrink={999}
        zIndex={1}
        sx={(theme) => ({
          [theme.breakpoints.up(834)]: {
            alignItems: 'flex-start',
            textAlign: 'initial',
            pl: isReversed ? 8 : 0,
            pr: isReversed ? 0 : 2,
          },
          [`& .${typographyClasses.root}`]: {
            textWrap: 'balance',
          },
        })}
      >
        {children}
      </Box>
      {heroElement ? (
        heroElement
      ) : heroWebm && !isMobile ? (
        <WebmWrapperVideo autoPlay loop muted>
          <source src={heroWebm} type="video/webm" />
        </WebmWrapperVideo>
      ) : heroHeaderImage && !isMobile ? (
        <Box
          height="100vh"
          position="absolute"
          width="100vw"
          left="50%"
          sx={{
            backgroundImage: `url(${heroHeaderImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'round',
          }}
        />
      ) : (
        <AspectRatio
          ratio={600 / 520}
          variant="plain"
          maxHeight={300}
          sx={(theme) => ({
            minWidth: heroVideo ? 270 : 300,
            alignSelf: 'stretch',
            borderRadius: 'sm',
            flexBasis: '50%',
            filter:
              isImageLoading || isMobile
                ? ''
                : 'drop-shadow(#000 2px 4px 10px)',
            mt: 4,
            mb: 4,
            '--AspectRatio-maxHeight': '260px',
            '& .MuiAspectRatio-content': {
              overflow: 'visible',
              mt: isMobile ? 12 : 0,
              padding: isMobile ? '0 25px' : '',
              [theme.breakpoints.down(834)]: {
                height: '100%',
              },
            },
            [theme.breakpoints.up(834)]: {
              alignSelf: 'initial',
              flexGrow: 1,
              mt: 0,
              mb: 0,
              '--AspectRatio-maxHeight': heroVideo ? '270px' : '300px',
              '--AspectRatio-minHeight': heroVideo ? '270px' : '300px',
            },
          })}
        >
          <Skeleton animation="wave" loading={isImageLoading}>
            {heroVideo ? (
              <HeroVideoWrapper
                video={heroVideo}
                isFocused={isFocused}
                hasFocused={hasFocused}
                setHasFocused={setHasFocused}
                setIsImageLoading={setIsImageLoading}
                isMobile={isMobile}
              />
            ) : (
              <HeroImageWrapper
                image={heroImage}
                noImageBorder={noImageBorder}
                isFocused={isFocused}
                hasFocused={hasFocused}
                setHasFocused={setHasFocused}
                setIsImageLoading={setIsImageLoading}
                isMobile={isMobile}
                isReversed={isReversed}
              />
            )}
          </Skeleton>
        </AspectRatio>
      )}
    </Container>
  )
}

const WebmWrapperVideo = styled.video`
  position: absolute;
  top: 0;
  left: 50%;
  width: auto;
  height: 100vh;
`

export default TwoSidedLayout
