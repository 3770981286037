import { AnimatePresence } from 'framer-motion'
import React, { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'

import { CurrentTurn, Position, TurnStatus } from '../../enums'
import { selectCurrentTurn } from '../../state/gameStateSelectors'
import {
  IActionStatus,
  ICard,
  IDamageResult,
  IPlayerActionResponse,
} from '../../types'
import CardWrapper from './CardWrapper'
import Correction from './Correction'
import CorrectionButton from './CorrectionButton'
import CorrectionLabel from './CorrectionLabel'
import FeedbackWrapper from './FeedbackWrapper'
import HandCards from './HandCards'
import HandContainer from './HandContainer'
import HandMask from './HandMask'
import ReponseText from './ResponseText'

interface IProps {
  actionStatus: IActionStatus
  activeCards: ICard[]
  aiAttackResponse?: string
  currentInput: string | undefined
  damageResult: IDamageResult
  hand: ICard[]
  handleReviewContinue: () => void
  lastInput: string
  position: Position
  playerActionResponse: IPlayerActionResponse | undefined
  setCurrentInput: Dispatch<SetStateAction<string>>
  setPlayerActiveCards: Dispatch<SetStateAction<ICard[]>>
  showFeedback: boolean
}

const Hand = ({
  actionStatus,
  activeCards,
  aiAttackResponse,
  currentInput = '',
  damageResult,
  hand,
  handleReviewContinue,
  lastInput,
  position,
  playerActionResponse,
  setCurrentInput,
  setPlayerActiveCards,
  showFeedback,
}: IProps) => {
  const currentTurn = useSelector(selectCurrentTurn)

  const showFeedbackWrapper =
    showFeedback &&
    ((damageResult.damageDealer === CurrentTurn.AI &&
      position === Position.Top) ||
      (damageResult.damageDealer === CurrentTurn.Player &&
        position === Position.Bottom))
  const showCorrections =
    actionStatus.turnStatus === TurnStatus.Paused && !showFeedback

  return (
    <HandContainer damageResult={damageResult} position={position}>
      <HandMask
        show={
          (currentTurn === CurrentTurn.AI &&
            (actionStatus.turnStatus === TurnStatus.Started ||
              actionStatus.turnStatus === TurnStatus.Paused)) ||
          (currentTurn === CurrentTurn.Player &&
            actionStatus.turnStatus === TurnStatus.Paused) ||
          showFeedbackWrapper
        }
      >
        <Correction show={!!playerActionResponse?.correction && !showFeedback}>
          <CorrectionLabel
            show={showCorrections}
            position={Position.Top}
            text={
              currentTurn === CurrentTurn.AI
                ? 'Your translation:'
                : 'Your sentence:'
            }
          />
          <ReponseText show={showCorrections} text={lastInput} />
          <CorrectionLabel
            show={showCorrections}
            position={Position.Bottom}
            text={'Correction:'}
          />
          <ReponseText
            show={showCorrections}
            text={playerActionResponse?.correction ?? ''}
          />
          <CorrectionButton
            show={showCorrections}
            onClick={handleReviewContinue}
            text={'Got it!'}
          />
        </Correction>
        <FeedbackWrapper
          damageResult={damageResult}
          show={showFeedbackWrapper}
        />
        <ReponseText
          positionAbsolute
          show={
            currentTurn === CurrentTurn.AI &&
            actionStatus.turnStatus === TurnStatus.Started
          }
          text={aiAttackResponse ?? ''}
        />
      </HandMask>
      <HandCards>
        <AnimatePresence>
          {hand.map(({ dealt = false, difficulty, show, title }, index) => (
            <CardWrapper
              key={`${title}`}
              activeCards={activeCards}
              actionStatus={actionStatus}
              currentInput={currentInput}
              currentTurn={currentTurn}
              dealt={dealt}
              difficulty={difficulty}
              hand={hand}
              index={index}
              position={position}
              setCurrentInput={setCurrentInput}
              setPlayerActiveCards={setPlayerActiveCards}
              show={show}
              title={title}
            />
          ))}
        </AnimatePresence>
      </HandCards>
    </HandContainer>
  )
}

export default Hand
