import { ICard } from '../../types'

export const getRandomCardsFromPool = (cardPool: ICard[], amount: number) => {
  if (amount > cardPool.length) {
    return cardPool
  }

  const shuffledCards = getShuffleCards(cardPool)
  const selectedCards = shuffledCards.slice(0, amount)

  return selectedCards
}

export const getCardsFromDeck = (
  deck: ICard[],
  amount: number,
  startIndex: number = 0
) => {
  let cards: ICard[] = []

  cards =
    amount <= deck.length
      ? deck.slice(startIndex, amount)
      : deck.slice(0, deck.length)

  return cards
}

export const getActiveCards = (input: string, cards: ICard[]) => {
  const inputWords = input
    .replaceAll(/[.,?!]/g, '')
    .toLowerCase()
    .split(' ')

  const activeCards: ICard[] = []

  // Handle words containing apostrophes (there's, we're, etc)
  inputWords.forEach((word) => {
    const foundWord = cards.find((card) =>
      word.includes("'") || word.includes('’')
        ? word.includes(card.title.toLowerCase())
        : word === card.title.toLowerCase()
    )

    foundWord && activeCards.push(foundWord)
  })

  return activeCards
}

export const removeActiveCardsFromHand = (
  hand: ICard[],
  playerActiveCards: ICard[]
) =>
  hand.filter(
    (card) =>
      !playerActiveCards.find((activeCard) => activeCard.title === card.title)
  )

export const getAttackDamage = (playerActiveCards: ICard[]) => {
  const totalDamage = playerActiveCards.reduce(
    (accumulator, current) => accumulator + current.difficulty,
    0
  )

  return totalDamage
}

export const getShuffleCards = (cards: ICard[]) => {
  const shuffledArray = [...cards]
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))

    const temp = shuffledArray[i]
    shuffledArray[i] = shuffledArray[j]
    shuffledArray[j] = temp
  }

  return shuffledArray
}
