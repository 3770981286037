import axios from 'axios'
import { isNotEmpty, pathOr } from 'ramda'

import { IGetDeckResult } from './types'

interface ICardsResponse {
  id: number
  title: string
  difficulty: number
}

interface IUserDeckResponse {
  id: number
  user_id: number
  language_id: number
  name: string
}

interface IGetDeck {
  token: string
  setGetDeckResult: (props: IGetDeckResult) => void
}

export const getActiveDeck = ({ token, setGetDeckResult }: IGetDeck) => {
  axios
    .get('https://scrai-930ca0ac412a.herokuapp.com/api/get-active-deck', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const cards: ICardsResponse[] = pathOr([], ['data', 'cards'], res)
      // const userDeck: Partial<IUserDeckResponse> = pathOr(
      //   {},
      //   ['data', 'userDeck'],
      //   res,
      // )

      if (isNotEmpty(cards)) {
        setGetDeckResult({
          error: '',
          deck: cards.map((item) => ({
            ...item,
            dealt: false,
            show: false,
          })),
        })
      } else {
        setGetDeckResult({
          error: 'Failed to fetch deck',
          deck: [],
        })
      }
    })
    .catch((err) => {
      const error = pathOr(
        'Failed to fetch deck',
        ['response', 'data', 'error'],
        err,
      )
      setGetDeckResult({ error, deck: [] })
      console.error(error)
    })
}
