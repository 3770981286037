import styled from '@emotion/styled'
import React from 'react'

import { CardRarity } from '../../enums'

interface IProps {
  rarity: CardRarity
}

const CardFaceImage = ({ rarity }: IProps) =>
  rarity === CardRarity.Common ? (
    <CardFaceImageImg src={require('../../assets/images/mace.png')} />
  ) : rarity === CardRarity.Uncommon ? (
    <CardFaceImageImg src={require('../../assets/images/sword.png')} />
  ) : (
    <CardFaceImageImg src={require('../../assets/images/meteor.png')} />
  )

const CardFaceImageImg = styled.img`
  align-self: center;
  filter: invert(1);
  position: absolute;
  height: 100%;
  opacity: 0.1;
`

export default CardFaceImage
