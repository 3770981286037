import { createSelector } from 'reselect'

import { IState } from './types'

const state = (state: IState) => state

export const selectUserProfile = createSelector(
  state,
  ({ userState }) => userState.userProfile,
)

export const selectWasRedirected = createSelector(
  state,
  ({ userState }) => userState.wasRedirected,
)
