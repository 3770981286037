import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { CurrentTurn, RequestStatus, TurnStatus } from '../../enums'
import { IActionStatus } from '../../types'

interface IProps {
  aiActionStatus: IActionStatus
  children: ReactNode
  currentTurn: CurrentTurn
  handleAttack: () => void
  handleDefence: () => void
  isActiveCards: boolean
  isInput: boolean
  playerActionStatus: IActionStatus
}

interface IInputButtonDivProps {
  aiActionStatus: IActionStatus
  isInput: boolean
  currentTurn: CurrentTurn
  isActiveCards: boolean
  playerActionStatus: IActionStatus
}

const getBackgroundColor = (
  aiActionStatus: IActionStatus,
  currentTurn: CurrentTurn,
  isActiveCards: boolean,
  isInput: boolean,
  playerActionStatus: IActionStatus
) => {
  const attackColor = '#3bb78f'
  const defenceColor = '#bd4f6c'
  const defaultColor = '#383838'

  if (
    currentTurn === CurrentTurn.Player &&
    playerActionStatus.requestStatus === RequestStatus.Idle &&
    playerActionStatus.turnStatus === TurnStatus.Started &&
    isActiveCards
  ) {
    return attackColor
  }

  if (
    currentTurn === CurrentTurn.AI &&
    aiActionStatus.requestStatus === RequestStatus.Idle &&
    playerActionStatus.requestStatus === RequestStatus.Idle &&
    aiActionStatus.turnStatus === TurnStatus.Started &&
    isInput
  ) {
    return defenceColor
  }

  return defaultColor
}

const getCursor = (
  aiActionStatus: IActionStatus,
  currentTurn: CurrentTurn,
  isActiveCards: boolean,
  isInput: boolean,
  playerActionStatus: IActionStatus
) => {
  if (
    aiActionStatus.requestStatus === RequestStatus.Idle &&
    playerActionStatus.requestStatus === RequestStatus.Idle &&
    ((currentTurn === CurrentTurn.AI && isInput) ||
      (currentTurn === CurrentTurn.Player && isActiveCards))
  ) {
    return 'pointer'
  }

  return 'default'
}

const InputButton = ({
  aiActionStatus,
  children,
  currentTurn,
  handleAttack,
  handleDefence,
  isActiveCards,
  isInput,
  playerActionStatus,
}: IProps) => {
  const handleButtonClick = () => {
    if (
      aiActionStatus.requestStatus !== RequestStatus.Idle ||
      playerActionStatus.requestStatus !== RequestStatus.Idle ||
      aiActionStatus.turnStatus === TurnStatus.Paused ||
      playerActionStatus.turnStatus === TurnStatus.Paused
    ) {
      return
    }

    if (currentTurn === CurrentTurn.Player && isActiveCards) {
      handleAttack()
    }

    if (currentTurn === CurrentTurn.AI && isInput) {
      handleDefence()
    }
  }

  return (
    <InputButtonDiv
      aiActionStatus={aiActionStatus}
      currentTurn={currentTurn}
      onClick={handleButtonClick}
      isActiveCards={isActiveCards}
      isInput={isInput}
      playerActionStatus={playerActionStatus}
    >
      {children}
    </InputButtonDiv>
  )
}

const InputButtonDiv = styled.div<IInputButtonDivProps>(
  ({
    aiActionStatus,
    playerActionStatus,
    isActiveCards,
    isInput,
    currentTurn,
  }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    color: '#fff',
    backgroundColor: getBackgroundColor(
      aiActionStatus,
      currentTurn,
      isActiveCards,
      isInput,
      playerActionStatus
    ),
    transition: 'background-color 0.25s ease-in-out',
    borderRadius: '0 20px 20px 0',
    padding: '12px',
    height: '22px',
    lineHeight: '22px',
    cursor: getCursor(
      aiActionStatus,
      currentTurn,
      isActiveCards,
      isInput,
      playerActionStatus
    ),
    fontSize: '22px',
    width: '48px',
    justifyContent: 'center',
    right: '0',
    position: 'relative',
  })
)

export default InputButton
