import { Box, Typography } from '@mui/joy'
import React, { useState } from 'react'

import TextHighlight from '../../__components/TextHighlight'
import TwoSidedLayout from '../../__components/TwoSidedLayout'

interface IProps {
  isFocused: boolean
  isMobile: boolean
}

const LearnHowBlock03 = ({ isFocused, isMobile }: IProps) => {
  const [hasFocused, setHasFocused] = useState(false)

  const phaseTwoElement = (
    <Box textAlign={isMobile ? 'center' : 'left'}>
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        Divine the meaning...
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
        mt={1}
      >
        Phase Two
      </Typography>
      <Typography
        fontSize="lg"
        textColor="text.secondary"
        lineHeight="lg"
        mt={2}
      >
        Once Phase One concludes, the tables turn as the opponent presents a{' '}
        <TextHighlight>sentence for translation</TextHighlight>.
      </Typography>
      <Typography
        fontSize="lg"
        textColor="text.secondary"
        lineHeight="lg"
        mt={2}
      >
        This phase tests your comprehension and translation skills, challenging
        you to accurately <TextHighlight>decipher the meaning</TextHighlight> of
        the sentence.
      </Typography>
      <Typography
        fontSize="lg"
        textColor="text.secondary"
        lineHeight="lg"
        mt={2}
      >
        A successful translation <TextHighlight>blocks</TextHighlight> the
        opponent{"'"}s incoming attack, whereas a failure will result in you{' '}
        <TextHighlight>taking damage</TextHighlight>!
      </Typography>
    </Box>
  )

  return (
    <TwoSidedLayout
      isFocused={isFocused}
      hasFocused={hasFocused}
      setHasFocused={setHasFocused}
      heroElement={phaseTwoElement}
    >
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        Plan your attack...
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Phase One
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        In the first phase of each round, you must{' '}
        <TextHighlight>craft a sentence</TextHighlight> using the cards in your
        hand.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Every word used <TextHighlight>deals damage</TextHighlight> to your
        opponent based on the word{"'"}s difficulty level.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        It{"'"}s a delicate balance of linguistic prowess and tactical
        decision-making. But beware,{' '}
        <TextHighlight>a single mistake</TextHighlight> could result in your
        opponent <TextHighlight>blocking</TextHighlight> your attack.
      </Typography>
    </TwoSidedLayout>
  )
}

export default LearnHowBlock03
