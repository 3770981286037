import { Box, Typography } from '@mui/joy'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface IProps {
  colourScheme: string | undefined
}

const FooterBar = ({ colourScheme }: IProps) => (
  <Box
    display="flex"
    alignItems="center"
    height="64px"
    width="100%"
    position="relative"
    bottom={0}
    borderTop={`1px solid ${colourScheme === 'light' ? '#b2bccd' : '#9fa6ad'}`}
    px={3}
    mt={4}
    zIndex={1}
    sx={(theme) => ({
      pointerEvents: 'none',
      [theme.breakpoints.down(834)]: {
        height: '60px',
      },
      [theme.breakpoints.up(1680)]: {
        height: '80px',
      },
    })}
  >
    <Typography
      level="body-xs"
      sx={(theme) => ({
        [theme.breakpoints.down(834)]: {
          ml: 3,
        },
      })}
    >
      © Scraiku {new Date().getFullYear()}
    </Typography>
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      sx={(theme) => ({
        pointerEvents: 'all',
        [theme.breakpoints.down(834)]: {
          mr: 3,
          textAlign: 'left',
        },
      })}
    >
      <RouterLink to={'/privacy-policy'} style={{ textDecoration: 'none' }}>
        <Typography level="body-xs" ml={3}>
          Privacy Policy
        </Typography>
      </RouterLink>
      <RouterLink
        to={'/terms-and-conditions'}
        style={{ textDecoration: 'none' }}
      >
        <Typography level="body-xs" ml={3}>
          Terms & Conditions
        </Typography>
      </RouterLink>
    </Box>
  </Box>
)

export default FooterBar
