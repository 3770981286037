import styled from '@emotion/styled'
import React from 'react'

const CardBackImage = () => (
  <CardBackImageImg src={require('../../assets/images/crystal-ball.png')} />
)

const CardBackImageImg = styled.img`
  height: 35%;
  filter: contrast(0.45);
  align-self: center;
  pointer-events: none;
`

export default CardBackImage
