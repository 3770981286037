import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { Position } from '../../enums'

interface IProps {
  children: ReactNode
  position: Position
}

interface ISideBarContainerDivProps {
  position: Position
}

const SideBarContainer = ({ children, position }: IProps) => (
  <SideBarContainerDiv position={position}>{children}</SideBarContainerDiv>
)

const SideBarContainerDiv = styled.div<ISideBarContainerDivProps>(
  ({ position }) => ({
    [position === Position.Right ? 'right' : 'left']: 0,
    position: 'absolute',
    height: '100vh',
    width: '200px',
    display: 'flex',
    flexDirection: position === Position.Right ? 'column-reverse' : 'column',
    justifyContent: 'flex-start',
    alignItems: position === Position.Right ? 'flex-end' : 'flex-start',
    margin: position === Position.Right ? '0 40px 40px 0' : '40px 0 0 40px',
    color: '#fff',
    fontSize: '28px',
    fontWeight: '600',
    zIndex: '10',
    pointerEvents: 'none',
    textAlign: position === Position.Right ? 'end' : 'start',
  })
)

export default SideBarContainer
