import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const CardBack = ({ children }: IProps) => <CardBackDiv>{children}</CardBackDiv>

const CardBackDiv = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  position: absolute;
  transform: rotateX(0deg);
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%);
  border: 6px solid #383838;
  border-radius: 20px 0px 20px 20px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
`

export default CardBack
