import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
  show: boolean
}

interface IHandMaskProps {
  show: boolean
}

const HandMask = ({ children, show }: IProps) => (
  <HandMaskDiv show={show}>{children}</HandMaskDiv>
)

const HandMaskDiv = styled.div<IHandMaskProps>(({ show }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  height: '100%',
  width: '100vw',
  position: 'absolute',
  background: show ? 'rgba(32, 32, 32, 0.95)' : 'rgba(32, 32, 32, 0)',
  zIndex: '2',
  pointerEvents: 'none',
  transition: 'background 0.5s ease-in-out',
}))

export default HandMask
