import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React from 'react'

import { CurrentTurn } from '../../enums'
import { IDamageResult } from '../../types'
import FeedbackText from './FeedbackText'

interface IProps {
  damageResult: IDamageResult
  show: boolean
}

const FeedbackWrapper = ({ damageResult, show }: IProps) => {
  const getFeedbackText = () => {
    const { damageDealer, total } = damageResult

    if (
      (damageDealer === CurrentTurn.AI && !total) ||
      (damageDealer === CurrentTurn.Player && total)
    ) {
      if (damageResult.total > 20) {
        return 'SUPER!'
      }

      if (damageResult.total > 10) {
        return 'Great!'
      }

      return 'Nice!'
    }

    return 'Oops...'
  }

  return show ? (
    <FeedbackWrapperDiv>
      <motion.div
        initial={{ x: '-150vw' }}
        animate={{ x: '-250px' }}
        transition={{
          duration: 2,
          ease: 'circInOut',
        }}
      >
        <FeedbackText text={getFeedbackText()} />
      </motion.div>
    </FeedbackWrapperDiv>
  ) : null
}

const FeedbackWrapperDiv = styled.div`
  overflow: hidden;
  width: 150vw;
`

export default FeedbackWrapper
