import Cookies from 'js-cookie'
import React from 'react'
import { Navigate } from 'react-router-dom'

interface IProps {
  children: React.ReactNode
}

const ProtectedRoute = ({ children }: IProps) => {
  const token = Cookies.get('token')

  if (!token) {
    // User is not authenticated, redirect to the login page
    return (
      <Navigate to="/login" replace state={{ openSignInByDefault: true }} />
    )
  }

  // User is authenticated, render the protected route content
  return <>{children}</>
}

export default ProtectedRoute
