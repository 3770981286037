export const palette = {
  blue: '#439ab5',
  green: '#3bb78f',
  red: '#bd4f6c',
}

// export interface ICard {
//   dealt?: boolean
//   difficulty: number
//   title: string
//   show?: boolean
// }

// export interface ICardPool {
//   [key: string]: ICard[]
// }

// export const cardPool: ICardPool = {
//   afrikaans: [
//     { title: 'hallo', difficulty: 2, translation: 'hello' },
//     { title: 'goeie', difficulty: 3, translation: 'good' },
//     { title: 'dag', difficulty: 2, translation: 'day' },
//     { title: 'jy', difficulty: 4, translation: 'you' },
//     { title: 'ek', difficulty: 3, translation: 'I' },
//     { title: 'is', difficulty: 2, translation: 'is' },
//     { title: 'nie', difficulty: 3, translation: 'not' },
//     { title: 'hulle', difficulty: 4, translation: 'they' },
//     { title: 'ons', difficulty: 3, translation: 'we' },
//     { title: 'ja', difficulty: 2, translation: 'yes' },
//     { title: 'nee', difficulty: 3, translation: 'no' },
//     { title: 'vir', difficulty: 4, translation: 'for' },
//     { title: 'met', difficulty: 3, translation: 'with' },
//     { title: 'op', difficulty: 2, translation: 'on' },
//     { title: 'in', difficulty: 3, translation: 'in' },
//     { title: 'gaan', difficulty: 4, translation: 'go' },
//     { title: 'kan', difficulty: 3, translation: 'can' },
//     { title: 'wees', difficulty: 2, translation: 'be' },
//     { title: 'het', difficulty: 3, translation: 'have' },
//     { title: 'maar', difficulty: 4, translation: 'but' },
//     { title: 'van', difficulty: 3, translation: 'of' },
//     { title: 'wat', difficulty: 3, translation: 'what' },
//     { title: 'hierdie', difficulty: 4, translation: 'this' },
//     { title: 'sal', difficulty: 3, translation: 'will' },
//     { title: 'hier', difficulty: 2, translation: 'here' },
//     { title: 'weer', difficulty: 3, translation: 'again' },
//     { title: 'hoe', difficulty: 4, translation: 'how' },
//     { title: 'so', difficulty: 3, translation: 'so' },
//     { title: 'as', difficulty: 2, translation: 'as' },
//     { title: 'een', difficulty: 3, translation: 'one' },
//     { title: 'laat', difficulty: 3, translation: 'let' },
//     { title: 'af', difficulty: 2, translation: 'off' },
//     { title: 'mense', difficulty: 4, translation: 'people' },
//     { title: 'sê', difficulty: 3, translation: 'say' },
//     { title: 'sy', difficulty: 3, translation: 'she' },
//     { title: 'baie', difficulty: 2, translation: 'very' },
//     { title: 'my', difficulty: 3, translation: 'my' },
//     { title: 'jou', difficulty: 2, translation: 'your' },
//     { title: 'sien', difficulty: 3, translation: 'see' },
//     { title: 'doen', difficulty: 2, translation: 'do' },
//     { title: 'sou', difficulty: 3, translation: 'would' },
//     { title: 'verstaan', difficulty: 5, translation: 'understand' },
//     { title: 'moet', difficulty: 6, translation: 'must' },
//     { title: 'werk', difficulty: 7, translation: 'work' },
//     { title: 'probleem', difficulty: 8, translation: 'problem' },
//     { title: 'moeilik', difficulty: 9, translation: 'difficult' },
//     { title: 'besig', difficulty: 10, translation: 'busy' },
//     { title: 'sirkel', difficulty: 5, translation: 'circle' },
//     { title: 'vraag', difficulty: 6, translation: 'ask' },
//     { title: 'antwoord', difficulty: 7, translation: 'answer' },
//     { title: 'huis', difficulty: 8, translation: 'house' },
//     { title: 'groot', difficulty: 9, translation: 'big' },
//     { title: 'klein', difficulty: 10, translation: 'small' },
//     { title: 'saam', difficulty: 5, translation: 'together' },
//     { title: 'verby', difficulty: 6, translation: 'past' },
//     { title: 'leer', difficulty: 7, translation: 'learn' },
//     { title: 'skool', difficulty: 8, translation: 'school' },
//     { title: 'reis', difficulty: 9, translation: 'travel' },
//     { title: 'beweeg', difficulty: 10, translation: 'move' },
//     { title: 'nuwe', difficulty: 5, translation: 'new' },
//     { title: 'oud', difficulty: 6, translation: 'old' },
//     { title: 'hond', difficulty: 4, translation: 'dog' },
//     { title: 'kat', difficulty: 5, translation: 'cat' },
//     { title: 'lewe', difficulty: 6, translation: 'life' },
//     { title: 'dink', difficulty: 5, translation: 'think' },
//     { title: 'son', difficulty: 6, translation: 'sun' },
//     { title: 'water', difficulty: 4, translation: 'water' },
//     { title: 'lug', difficulty: 5, translation: 'air' },
//     { title: 'aarde', difficulty: 6, translation: 'earth' },
//     { title: 'kleur', difficulty: 4, translation: 'color' },
//     { title: 'musiek', difficulty: 5, translation: 'music' },
//     { title: 'stil', difficulty: 6, translation: 'quiet' },
//     { title: 'vriend', difficulty: 4, translation: 'friend' },
//     { title: 'tyd', difficulty: 5, translation: 'time' },
//     { title: 'plek', difficulty: 6, translation: 'place' },
//     { title: 'weer', difficulty: 5, translation: 'weather' },
//     { title: 'smaak', difficulty: 6, translation: 'taste' },
//     { title: 'werk', difficulty: 4, translation: 'job' },
//     { title: 'weg', difficulty: 5, translation: 'away' },
//   ],
//   dutch: [
//     { title: 'huis', difficulty: 3, translation: 'house' },
//     { title: 'tafel', difficulty: 4, translation: 'table' },
//     { title: 'stoel', difficulty: 4, translation: 'chair' },
//     { title: 'boek', difficulty: 3, translation: 'book' },
//     { title: 'klok', difficulty: 5, translation: 'clock' },
//     { title: 'auto', difficulty: 4, translation: 'car' },
//     { title: 'fiets', difficulty: 5, translation: 'bike' },
//     { title: 'school', difficulty: 4, translation: 'school' },
//     { title: 'leren', difficulty: 6, translation: 'learn' },
//     { title: 'schrijven', difficulty: 6, translation: 'write' },
//     { title: 'lezen', difficulty: 6, translation: 'read' },
//     { title: 'eten', difficulty: 4, translation: 'eat' },
//     { title: 'drinken', difficulty: 5, translation: 'drink' },
//     { title: 'slapen', difficulty: 4, translation: 'sleep' },
//     { title: 'muziek', difficulty: 5, translation: 'music' },
//     { title: 'film', difficulty: 4, translation: 'movie' },
//     { title: 'stad', difficulty: 5, translation: 'city' },
//     { title: 'natuur', difficulty: 4, translation: 'nature' },
//     { title: 'kleur', difficulty: 5, translation: 'color' },
//     { title: 'groot', difficulty: 6, translation: 'big' },
//     { title: 'klein', difficulty: 6, translation: 'small' },
//     { title: 'oud', difficulty: 5, translation: 'old' },
//     { title: 'nieuw', difficulty: 5, translation: 'new' },
//     { title: 'vrolijk', difficulty: 6, translation: 'happy' },
//     { title: 'verdrietig', difficulty: 6, translation: 'sad' },
//     { title: 'lachen', difficulty: 5, translation: 'laugh' },
//     { title: 'huilen', difficulty: 5, translation: 'cry' },
//     { title: 'werken', difficulty: 6, translation: 'work' },
//     { title: 'spelen', difficulty: 5, translation: 'play' },
//     { title: 'vriend', difficulty: 6, translation: 'friend' },
//     { title: 'familie', difficulty: 6, translation: 'family' },
//     { title: 'hond', difficulty: 5, translation: 'dog' },
//     { title: 'kat', difficulty: 5, translation: 'cat' },
//     { title: 'vogel', difficulty: 6, translation: 'bird' },
//     { title: 'zomer', difficulty: 5, translation: 'summer' },
//     { title: 'winter', difficulty: 5, translation: 'winter' },
//     { title: 'zon', difficulty: 6, translation: 'sun' },
//     { title: 'maan', difficulty: 6, translation: 'moon' },
//     { title: 'ster', difficulty: 5, translation: 'star' },
//     { title: 'strand', difficulty: 6, translation: 'beach' },
//     { title: 'berg', difficulty: 5, translation: 'mountain' },
//     { title: 'water', difficulty: 6, translation: 'water' },
//     { title: 'lucht', difficulty: 5, translation: 'air' },
//     { title: 'vuur', difficulty: 6, translation: 'fire' },
//     { title: 'aarde', difficulty: 6, translation: 'earth' },
//     { title: 'schip', difficulty: 5, translation: 'ship' },
//     { title: 'trein', difficulty: 6, translation: 'train' },
//     { title: 'vliegtuig', difficulty: 6, translation: 'airplane' },
//     { title: 'auto', difficulty: 5, translation: 'car' },
//     { title: 'fiets', difficulty: 6, translation: 'bike' },
//     { title: 'computer', difficulty: 1, translation: 'computer' },
//     { title: 'telefoon', difficulty: 2, translation: 'phone' },
//     { title: 'internet', difficulty: 3, translation: 'internet' },
//     { title: 'camera', difficulty: 4, translation: 'camera' },
//     { title: 'restaurant', difficulty: 5, translation: 'restaurant' },
//     { title: 'universiteit', difficulty: 6, translation: 'university' },
//     { title: 'wetenschap', difficulty: 7, translation: 'science' },
//     { title: 'technologie', difficulty: 7, translation: 'technology' },
//     { title: 'fysica', difficulty: 8, translation: 'physics' },
//     { title: 'chemie', difficulty: 8, translation: 'chemistry' },
//     { title: 'biologie', difficulty: 8, translation: 'biology' },
//     { title: 'ingenieur', difficulty: 9, translation: 'engineer' },
//     { title: 'architectuur', difficulty: 9, translation: 'architecture' },
//     { title: 'filosofie', difficulty: 10, translation: 'philosophy' },
//     { title: 'psychologie', difficulty: 10, translation: 'psychology' },
//     { title: 'geneeskunde', difficulty: 10, translation: 'medicine' },
//   ],
//   french: [
//     { title: 'maison', difficulty: 3, translation: 'house' },
//     { title: 'table', difficulty: 4, translation: 'table' },
//     { title: 'chaise', difficulty: 4, translation: 'chair' },
//     { title: 'livre', difficulty: 3, translation: 'book' },
//     { title: 'horloge', difficulty: 5, translation: 'clock' },
//     { title: 'voiture', difficulty: 4, translation: 'car' },
//     { title: 'vélo', difficulty: 5, translation: 'bike' },
//     { title: 'école', difficulty: 4, translation: 'school' },
//     { title: 'apprendre', difficulty: 6, translation: 'learn' },
//     { title: 'écrire', difficulty: 6, translation: 'write' },
//     { title: 'lire', difficulty: 6, translation: 'read' },
//     { title: 'manger', difficulty: 4, translation: 'eat' },
//     { title: 'boire', difficulty: 5, translation: 'drink' },
//     { title: 'dormir', difficulty: 4, translation: 'sleep' },
//     { title: 'musique', difficulty: 5, translation: 'music' },
//     { title: 'film', difficulty: 4, translation: 'movie' },
//     { title: 'ville', difficulty: 5, translation: 'city' },
//     { title: 'nature', difficulty: 4, translation: 'nature' },
//     { title: 'couleur', difficulty: 5, translation: 'color' },
//     { title: 'grand', difficulty: 6, translation: 'big' },
//     { title: 'petit', difficulty: 6, translation: 'small' },
//     { title: 'vieux', difficulty: 5, translation: 'old' },
//     { title: 'nouveau', difficulty: 5, translation: 'new' },
//     { title: 'heureux', difficulty: 6, translation: 'happy' },
//     { title: 'triste', difficulty: 6, translation: 'sad' },
//     { title: 'rire', difficulty: 5, translation: 'laugh' },
//     { title: 'pleurer', difficulty: 5, translation: 'cry' },
//     { title: 'travailler', difficulty: 6, translation: 'work' },
//     { title: 'jouer', difficulty: 5, translation: 'play' },
//     { title: 'ami', difficulty: 6, translation: 'friend' },
//     { title: 'famille', difficulty: 6, translation: 'family' },
//     { title: 'chien', difficulty: 5, translation: 'dog' },
//     { title: 'chat', difficulty: 5, translation: 'cat' },
//     { title: 'oiseau', difficulty: 6, translation: 'bird' },
//     { title: 'été', difficulty: 5, translation: 'summer' },
//     { title: 'hiver', difficulty: 5, translation: 'winter' },
//     { title: 'soleil', difficulty: 6, translation: 'sun' },
//     { title: 'lune', difficulty: 6, translation: 'moon' },
//     { title: 'étoile', difficulty: 5, translation: 'star' },
//     { title: 'plage', difficulty: 6, translation: 'beach' },
//     { title: 'montagne', difficulty: 5, translation: 'mountain' },
//     { title: 'eau', difficulty: 6, translation: 'water' },
//     { title: 'air', difficulty: 5, translation: 'air' },
//     { title: 'feu', difficulty: 6, translation: 'fire' },
//     { title: 'terre', difficulty: 6, translation: 'earth' },
//     { title: 'bateau', difficulty: 5, translation: 'ship' },
//     { title: 'train', difficulty: 6, translation: 'train' },
//     { title: 'avion', difficulty: 6, translation: 'airplane' },
//     { title: 'voiture', difficulty: 5, translation: 'car' },
//     { title: 'vélo', difficulty: 6, translation: 'bike' },
//     { title: 'ordinateur', difficulty: 1, translation: 'computer' },
//     { title: 'téléphone', difficulty: 2, translation: 'phone' },
//     { title: 'internet', difficulty: 3, translation: 'internet' },
//     { title: 'appareil photo', difficulty: 4, translation: 'camera' },
//     { title: 'restaurant', difficulty: 5, translation: 'restaurant' },
//     { title: 'université', difficulty: 6, translation: 'university' },
//     { title: 'science', difficulty: 7, translation: 'science' },
//     { title: 'technologie', difficulty: 7, translation: 'technology' },
//     { title: 'physique', difficulty: 8, translation: 'physics' },
//     { title: 'chimie', difficulty: 8, translation: 'chemistry' },
//     { title: 'biologie', difficulty: 8, translation: 'biology' },
//     { title: 'ingénieur', difficulty: 9, translation: 'engineer' },
//     { title: 'architecture', difficulty: 9, translation: 'architecture' },
//     { title: 'philosophie', difficulty: 10, translation: 'philosophy' },
//     { title: 'psychologie', difficulty: 10, translation: 'psychology' },
//     { title: 'médecine', difficulty: 10, translation: 'medicine' },
//   ],
//   german: [
//     { title: 'Haus', difficulty: 3, translation: 'house' },
//     { title: 'Tisch', difficulty: 4, translation: 'table' },
//     { title: 'Stuhl', difficulty: 4, translation: 'chair' },
//     { title: 'Buch', difficulty: 3, translation: 'book' },
//     { title: 'Uhr', difficulty: 5, translation: 'clock' },
//     { title: 'Auto', difficulty: 4, translation: 'car' },
//     { title: 'Fahrrad', difficulty: 5, translation: 'bike' },
//     { title: 'Schule', difficulty: 4, translation: 'school' },
//     { title: 'lernen', difficulty: 6, translation: 'learn' },
//     { title: 'schreiben', difficulty: 6, translation: 'write' },
//     { title: 'lesen', difficulty: 6, translation: 'read' },
//     { title: 'essen', difficulty: 4, translation: 'eat' },
//     { title: 'trinken', difficulty: 5, translation: 'drink' },
//     { title: 'schlafen', difficulty: 4, translation: 'sleep' },
//     { title: 'Musik', difficulty: 5, translation: 'music' },
//     { title: 'Film', difficulty: 4, translation: 'movie' },
//     { title: 'Stadt', difficulty: 5, translation: 'city' },
//     { title: 'Natur', difficulty: 4, translation: 'nature' },
//     { title: 'Farbe', difficulty: 5, translation: 'color' },
//     { title: 'groß', difficulty: 6, translation: 'big' },
//     { title: 'klein', difficulty: 6, translation: 'small' },
//     { title: 'alt', difficulty: 5, translation: 'old' },
//     { title: 'neu', difficulty: 5, translation: 'new' },
//     { title: 'glücklich', difficulty: 6, translation: 'happy' },
//     { title: 'traurig', difficulty: 6, translation: 'sad' },
//     { title: 'lachen', difficulty: 5, translation: 'laugh' },
//     { title: 'weinen', difficulty: 5, translation: 'cry' },
//     { title: 'arbeiten', difficulty: 6, translation: 'work' },
//     { title: 'spielen', difficulty: 5, translation: 'play' },
//     { title: 'Freund', difficulty: 6, translation: 'friend' },
//     { title: 'Familie', difficulty: 6, translation: 'family' },
//     { title: 'Hund', difficulty: 5, translation: 'dog' },
//     { title: 'Katze', difficulty: 5, translation: 'cat' },
//     { title: 'Vogel', difficulty: 6, translation: 'bird' },
//     { title: 'Sommer', difficulty: 5, translation: 'summer' },
//     { title: 'Winter', difficulty: 5, translation: 'winter' },
//     { title: 'Sonne', difficulty: 6, translation: 'sun' },
//     { title: 'Mond', difficulty: 6, translation: 'moon' },
//     { title: 'Stern', difficulty: 5, translation: 'star' },
//     { title: 'Strand', difficulty: 6, translation: 'beach' },
//     { title: 'Berg', difficulty: 5, translation: 'mountain' },
//     { title: 'Wasser', difficulty: 6, translation: 'water' },
//     { title: 'Luft', difficulty: 5, translation: 'air' },
//     { title: 'Feuer', difficulty: 6, translation: 'fire' },
//     { title: 'Erde', difficulty: 6, translation: 'earth' },
//     { title: 'Schiff', difficulty: 5, translation: 'ship' },
//     { title: 'Zug', difficulty: 6, translation: 'train' },
//     { title: 'Flugzeug', difficulty: 6, translation: 'airplane' },
//     { title: 'Auto', difficulty: 5, translation: 'car' },
//     { title: 'Fahrrad', difficulty: 6, translation: 'bike' },
//     { title: 'Computer', difficulty: 1, translation: 'computer' },
//     { title: 'Telefon', difficulty: 2, translation: 'phone' },
//     { title: 'Internet', difficulty: 3, translation: 'internet' },
//     { title: 'Kamera', difficulty: 4, translation: 'camera' },
//     { title: 'Restaurant', difficulty: 5, translation: 'restaurant' },
//     { title: 'Universität', difficulty: 6, translation: 'university' },
//     { title: 'Wissenschaft', difficulty: 7, translation: 'science' },
//     { title: 'Technologie', difficulty: 7, translation: 'technology' },
//     { title: 'Physik', difficulty: 8, translation: 'physics' },
//     { title: 'Chemie', difficulty: 8, translation: 'chemistry' },
//     { title: 'Biologie', difficulty: 8, translation: 'biology' },
//     { title: 'Ingenieur', difficulty: 9, translation: 'engineer' },
//     { title: 'Architektur', difficulty: 9, translation: 'architecture' },
//     { title: 'Philosophie', difficulty: 10, translation: 'philosophy' },
//   ],
//   italian: [
//     { title: 'casa', difficulty: 3, translation: 'house' },
//     { title: 'tavolo', difficulty: 4, translation: 'table' },
//     { title: 'sedia', difficulty: 4, translation: 'chair' },
//     { title: 'libro', difficulty: 3, translation: 'book' },
//     { title: 'orologio', difficulty: 5, translation: 'clock' },
//     { title: 'auto', difficulty: 4, translation: 'car' },
//     { title: 'bicicletta', difficulty: 5, translation: 'bike' },
//     { title: 'scuola', difficulty: 4, translation: 'school' },
//     { title: 'imparare', difficulty: 6, translation: 'learn' },
//     { title: 'scrivere', difficulty: 6, translation: 'write' },
//     { title: 'leggere', difficulty: 6, translation: 'read' },
//     { title: 'mangiare', difficulty: 4, translation: 'eat' },
//     { title: 'bere', difficulty: 5, translation: 'drink' },
//     { title: 'dormire', difficulty: 4, translation: 'sleep' },
//     { title: 'musica', difficulty: 5, translation: 'music' },
//     { title: 'film', difficulty: 4, translation: 'movie' },
//     { title: 'città', difficulty: 5, translation: 'city' },
//     { title: 'natura', difficulty: 4, translation: 'nature' },
//     { title: 'colore', difficulty: 5, translation: 'color' },
//     { title: 'grande', difficulty: 6, translation: 'big' },
//     { title: 'piccolo', difficulty: 6, translation: 'small' },
//     { title: 'vecchio', difficulty: 5, translation: 'old' },
//     { title: 'nuovo', difficulty: 5, translation: 'new' },
//     { title: 'felice', difficulty: 6, translation: 'happy' },
//     { title: 'triste', difficulty: 6, translation: 'sad' },
//     { title: 'ridere', difficulty: 5, translation: 'laugh' },
//     { title: 'piangere', difficulty: 5, translation: 'cry' },
//     { title: 'lavorare', difficulty: 6, translation: 'work' },
//     { title: 'giocare', difficulty: 5, translation: 'play' },
//     { title: 'amico', difficulty: 6, translation: 'friend' },
//     { title: 'famiglia', difficulty: 6, translation: 'family' },
//     { title: 'cane', difficulty: 5, translation: 'dog' },
//     { title: 'gatto', difficulty: 5, translation: 'cat' },
//     { title: 'uccello', difficulty: 6, translation: 'bird' },
//     { title: 'estate', difficulty: 5, translation: 'summer' },
//     { title: 'inverno', difficulty: 5, translation: 'winter' },
//     { title: 'sole', difficulty: 6, translation: 'sun' },
//     { title: 'luna', difficulty: 6, translation: 'moon' },
//     { title: 'stella', difficulty: 5, translation: 'star' },
//     { title: 'spiaggia', difficulty: 6, translation: 'beach' },
//     { title: 'montagna', difficulty: 5, translation: 'mountain' },
//     { title: 'acqua', difficulty: 6, translation: 'water' },
//     { title: 'aria', difficulty: 5, translation: 'air' },
//     { title: 'fuoco', difficulty: 6, translation: 'fire' },
//     { title: 'terra', difficulty: 6, translation: 'earth' },
//     { title: 'nave', difficulty: 5, translation: 'ship' },
//     { title: 'treno', difficulty: 6, translation: 'train' },
//     { title: 'aereo', difficulty: 6, translation: 'airplane' },
//     { title: 'auto', difficulty: 5, translation: 'car' },
//     { title: 'bicicletta', difficulty: 6, translation: 'bike' },
//     { title: 'computer', difficulty: 1, translation: 'computer' },
//     { title: 'telefono', difficulty: 2, translation: 'phone' },
//     { title: 'internet', difficulty: 3, translation: 'internet' },
//     { title: 'macchina fotografica', difficulty: 4, translation: 'camera' },
//     { title: 'ristorante', difficulty: 5, translation: 'restaurant' },
//     { title: 'università', difficulty: 6, translation: 'university' },
//     { title: 'scienza', difficulty: 7, translation: 'science' },
//     { title: 'tecnologia', difficulty: 7, translation: 'technology' },
//     { title: 'fisica', difficulty: 8, translation: 'physics' },
//     { title: 'chimica', difficulty: 8, translation: 'chemistry' },
//     { title: 'biologia', difficulty: 8, translation: 'biology' },
//     { title: 'ingegnere', difficulty: 9, translation: 'engineer' },
//     { title: 'architettura', difficulty: 9, translation: 'architecture' },
//     { title: 'filosofia', difficulty: 10, translation: 'philosophy' },
//     { title: 'psicologia', difficulty: 10, translation: 'psychology' },
//     { title: 'medicina', difficulty: 10, translation: 'medicine' },
//   ],
//   spanish: [
//     { title: 'casa', difficulty: 3, translation: 'house' },
//     { title: 'mesa', difficulty: 4, translation: 'table' },
//     { title: 'silla', difficulty: 4, translation: 'chair' },
//     { title: 'libro', difficulty: 3, translation: 'book' },
//     { title: 'reloj', difficulty: 5, translation: 'clock' },
//     { title: 'auto', difficulty: 4, translation: 'car' },
//     { title: 'bicicleta', difficulty: 5, translation: 'bike' },
//     { title: 'escuela', difficulty: 4, translation: 'school' },
//     { title: 'aprender', difficulty: 6, translation: 'learn' },
//     { title: 'escribir', difficulty: 6, translation: 'write' },
//     { title: 'leer', difficulty: 6, translation: 'read' },
//     { title: 'comer', difficulty: 4, translation: 'eat' },
//     { title: 'beber', difficulty: 5, translation: 'drink' },
//     { title: 'dormir', difficulty: 4, translation: 'sleep' },
//     { title: 'música', difficulty: 5, translation: 'music' },
//     { title: 'película', difficulty: 4, translation: 'movie' },
//     { title: 'ciudad', difficulty: 5, translation: 'city' },
//     { title: 'naturaleza', difficulty: 4, translation: 'nature' },
//     { title: 'color', difficulty: 5, translation: 'color' },
//     { title: 'grande', difficulty: 6, translation: 'big' },
//     { title: 'pequeño', difficulty: 6, translation: 'small' },
//     { title: 'viejo', difficulty: 5, translation: 'old' },
//     { title: 'nuevo', difficulty: 5, translation: 'new' },
//     { title: 'feliz', difficulty: 6, translation: 'happy' },
//     { title: 'triste', difficulty: 6, translation: 'sad' },
//     { title: 'reír', difficulty: 5, translation: 'laugh' },
//     { title: 'llorar', difficulty: 5, translation: 'cry' },
//     { title: 'trabajar', difficulty: 6, translation: 'work' },
//     { title: 'jugar', difficulty: 5, translation: 'play' },
//     { title: 'amigo', difficulty: 6, translation: 'friend' },
//     { title: 'familia', difficulty: 6, translation: 'family' },
//     { title: 'perro', difficulty: 5, translation: 'dog' },
//     { title: 'gato', difficulty: 5, translation: 'cat' },
//     { title: 'pájaro', difficulty: 6, translation: 'bird' },
//     { title: 'verano', difficulty: 5, translation: 'summer' },
//     { title: 'invierno', difficulty: 5, translation: 'winter' },
//     { title: 'sol', difficulty: 6, translation: 'sun' },
//     { title: 'luna', difficulty: 6, translation: 'moon' },
//     { title: 'estrella', difficulty: 5, translation: 'star' },
//     { title: 'playa', difficulty: 6, translation: 'beach' },
//     { title: 'montaña', difficulty: 5, translation: 'mountain' },
//     { title: 'agua', difficulty: 6, translation: 'water' },
//     { title: 'aire', difficulty: 5, translation: 'air' },
//     { title: 'fuego', difficulty: 6, translation: 'fire' },
//     { title: 'tierra', difficulty: 6, translation: 'earth' },
//     { title: 'barco', difficulty: 5, translation: 'ship' },
//     { title: 'tren', difficulty: 6, translation: 'train' },
//     { title: 'avión', difficulty: 6, translation: 'airplane' },
//     { title: 'coche', difficulty: 5, translation: 'car' },
//     { title: 'bicicleta', difficulty: 6, translation: 'bike' },
//     { title: 'computadora', difficulty: 1, translation: 'computer' },
//     { title: 'teléfono', difficulty: 2, translation: 'phone' },
//     { title: 'internet', difficulty: 3, translation: 'internet' },
//     { title: 'cámara', difficulty: 4, translation: 'camera' },
//     { title: 'restaurante', difficulty: 5, translation: 'restaurant' },
//     { title: 'universidad', difficulty: 6, translation: 'university' },
//     { title: 'ciencia', difficulty: 7, translation: 'science' },
//     { title: 'tecnología', difficulty: 7, translation: 'technology' },
//     { title: 'física', difficulty: 8, translation: 'physics' },
//     { title: 'química', difficulty: 8, translation: 'chemistry' },
//     { title: 'biología', difficulty: 8, translation: 'biology' },
//     { title: 'ingeniero', difficulty: 9, translation: 'engineer' },
//     { title: 'arquitectura', difficulty: 9, translation: 'architecture' },
//     { title: 'filosofía', difficulty: 10, translation: 'philosophy' },
//     { title: 'psicología', difficulty: 10, translation: 'psychology' },
//     { title: 'medicina', difficulty: 10, translation: 'medicine' },
//   ],
// }
