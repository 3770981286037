import { keyframes } from '@emotion/react'
import { ExpandMoreRounded } from '@mui/icons-material'
import { Box } from '@mui/joy'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

interface IProps {
  showScrollArrow: boolean
}

const ScrollArrow = ({ showScrollArrow }: IProps) => {
  const scrollChevronAnimation = keyframes`
    0% {
        filter: drop-shadow(1px 4px 6px #000);
    }
    50% {
        filter: drop-shadow(2px 14px 20px #000);
    }
    100% {
        filter: drop-shadow(1px 4px 6px #000);
    }
`

  const handleScrollToTop = () => {
    document.getElementById('topBox')?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <AnimatePresence>
      {showScrollArrow && (
        <Box
          position="absolute"
          bottom={80}
          right={20}
          zIndex={5}
          onClick={handleScrollToTop}
        >
          <motion.div
            key="expandMore"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            <ExpandMoreRounded
              sx={(theme) => ({
                cursor: 'pointer',
                height: '100px',
                width: '100px',
                transform: 'rotate(180deg)',
                animation: `${scrollChevronAnimation} 2s infinite`,
                [theme.breakpoints.up(1680)]: {
                  height: '120px',
                  width: '120px',
                },
              })}
            />
          </motion.div>
        </Box>
      )}
    </AnimatePresence>
  )
}

export default ScrollArrow
