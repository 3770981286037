import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { CurrentTurn, Position } from '../../enums'
import { IDamageResult } from '../../types'

interface IProps {
  children: ReactNode
  damageResult: IDamageResult
  position: Position
}

interface IHandContainerProps {
  damageResult: IDamageResult
  position: Position
}

const getRattleAnimationByTurn = (
  damageRattleAnimation: {},
  damageResult: IDamageResult,
  position: Position
) =>
  (position === Position.Top &&
    damageResult.damageDealer === CurrentTurn.Player &&
    damageResult.total) ||
  (position === Position.Bottom &&
    damageResult.damageDealer === CurrentTurn.AI &&
    damageResult.total)
    ? `${damageRattleAnimation} 0.25s ease-in-out`
    : ''

const HandContainer = ({ children, damageResult, position }: IProps) => (
  <HandContainerDiv damageResult={damageResult} position={position}>
    {children}
  </HandContainerDiv>
)

const marginTop = keyframes`
  0% { margin-top: 0; margin-left: 0; }
  10% { margin-top: -5px; margin-left: 0; }
  20% { margin-top: 0; margin-left: -5px; }
  30% { margin-top: 5px; margin-left: 0; }
  40% { margin-top: 0; margin-left: 5px; }
  50% { margin-top: -2px; margin-left: 0; }
  60% { margin-top: 0; margin-left: -2px; }
  70% { margin-top: 2px; margin-left: 0; }
  80% { margin-top: 0; margin-left: 2px; }
  90% { margin-top: -1px; margin-left: 0; }
  100% { margin-top: 0; margin-left: 0; }
`

const HandContainerDiv = styled.div<IHandContainerProps>(
  ({ damageResult, position }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    perspective: '1000px',
    animation: getRattleAnimationByTurn(marginTop, damageResult, position),
    animationDelay: '0.75s',
    padding: '25px',
    zIndex: 1,
  })
)

export default HandContainer
