import styled from '@emotion/styled'
import React from 'react'

interface IProps {
  icon?: string
  show: boolean
}

const Icon = ({ icon, show }: IProps) => (show ? <IconImg src={icon} /> : null)

const IconImg = styled.img`
  align-self: center;
  filter: invert(1);
  position: absolute;
  height: 24px;
  margin-left: 7px;
  padding: 7px 0 9px 0;
`

export default Icon
