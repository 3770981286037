import React, { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'

import { RequestStatus } from '../../enums'
import { selectCurrentTurn } from '../../state/gameStateSelectors'
import { IActionStatus, ICard } from '../../types'
import ButtonImage from './ButtonImage'
import InputButton from './InputButton'
import InputControlsContainer from './InputControlsContainer'
import InputField from './InputField'
import InputsWrapper from './InputsWrapper'
import Spinner from './Spinner'

interface IProps {
  aiActionStatus: IActionStatus
  gameStarted: boolean
  currentInput: string
  handleAttack: () => void
  handleDefence: () => void
  handleReviewContinue: () => void
  playerActionStatus: IActionStatus
  playerActiveCards: ICard[]
  playerHand: ICard[]
  setPlayerActiveCards: Dispatch<SetStateAction<ICard[]>>
  setCurrentInput: Dispatch<SetStateAction<string>>
}

const InputControls = ({
  aiActionStatus,
  gameStarted,
  currentInput,
  handleAttack,
  handleDefence,
  handleReviewContinue,
  playerActionStatus,
  playerActiveCards,
  playerHand,
  setPlayerActiveCards,
  setCurrentInput,
}: IProps) => {
  const currentTurn = useSelector(selectCurrentTurn)

  return (
    <InputControlsContainer show={gameStarted}>
      <InputsWrapper>
        <InputField
          aiActionStatus={aiActionStatus}
          currentInput={currentInput}
          currentTurn={currentTurn}
          handleAttack={handleAttack}
          handleDefence={handleDefence}
          handleReviewContinue={handleReviewContinue}
          playerActionStatus={playerActionStatus}
          playerActiveCards={playerActiveCards}
          playerHand={playerHand}
          setPlayerActiveCards={setPlayerActiveCards}
          setCurrentInput={setCurrentInput}
        />
        <InputButton
          aiActionStatus={aiActionStatus}
          currentTurn={currentTurn}
          handleAttack={handleAttack}
          handleDefence={handleDefence}
          isActiveCards={!!playerActiveCards.length}
          isInput={!!currentInput.length}
          playerActionStatus={playerActionStatus}
        >
          {aiActionStatus.requestStatus === RequestStatus.Sent ||
          playerActionStatus.requestStatus === RequestStatus.Sent ? (
            <Spinner />
          ) : (
            <ButtonImage
              aiActionStatus={aiActionStatus}
              currentTurn={currentTurn}
              isActiveCards={!!playerActiveCards.length}
              isInput={!!currentInput}
            />
          )}
        </InputButton>
      </InputsWrapper>
    </InputControlsContainer>
  )
}

export default InputControls
