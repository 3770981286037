import { Box, useColorScheme } from '@mui/joy'
import React from 'react'

interface IProps {
  currentBoxIndex: number
  numberOfBoxes: number
}

const ScrollIndicator = ({ currentBoxIndex, numberOfBoxes }: IProps) => {
  const { mode } = useColorScheme()

  const scrollBars = Array.from({ length: numberOfBoxes }, (_, index) => (
    <Box
      key={`box-${index}`}
      width={currentBoxIndex === index ? '100%' : '66%'}
      height={`${100 / numberOfBoxes}%`}
      bgcolor={mode === 'light' ? '#34373f' : '#d0d6dd'}
      sx={{
        opacity: currentBoxIndex === index ? '1' : '0.4',
      }}
    />
  ))

  return (
    <Box
      gap={1}
      position="absolute"
      right={0}
      height="100%"
      width="3px"
      display="flex"
      flexDirection="column"
      zIndex={2}
    >
      {scrollBars}
    </Box>
  )
}

export default ScrollIndicator
