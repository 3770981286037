import styled from '@emotion/styled'
import React from 'react'

import { CardRarity } from '../../enums'

interface IProps {
  rarity: CardRarity
  title: string
}

interface ITitleDivProps {
  rarity: CardRarity
}

const Title = ({ rarity, title }: IProps) => (
  <TitleDiv rarity={rarity}>{title}</TitleDiv>
)

const TitleDiv = styled.div<ITitleDivProps>(({ rarity }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#ffffff',
  fontSize: `clamp(16px, calc(10px + (36 - 20) * (100vw - 768px) / (1920 - 768)), 48px)`,
  fontWeight: 600,
  position: 'absolute',
  borderBottom: `3px solid ${rarity === CardRarity.Rare ? '#ffc988' : '#fff'}`,
  width: '80%',
  height: '50%',
  zIndex: 1,
}))

export default Title
