import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const HandCards = ({ children }: IProps) => (
  <HandCardsDiv>{children}</HandCardsDiv>
)

const HandCardsDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export default HandCards
