import styled from '@emotion/styled'
import { FestivalRounded } from '@mui/icons-material'
import { Box, CssBaseline, CssVarsProvider, useColorScheme } from '@mui/joy'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { IMenuItem } from '../../types'
import HeaderBar from '../__components/HeaderBar'
import ScrollArrow from '../__components/ScrollArrow'
import ScrollIndicator from '../__components/ScrollIndicator'
import SnapBoxWrapper from '../__components/SnapBoxWrapper'
import framesxTheme from '../theme'
import SeasonsBlock01 from './blocks/SeasonsBlock01'

interface IBackgroundGradientDivProps {
  colourScheme: string | undefined
  isMobile?: boolean
  isReversed?: boolean
}

const Seasons = () => {
  const { mode: colourScheme } = useColorScheme()

  const [currentBoxIndex, setCurrentBoxIndex] = useState<number>(0)
  const [showHeaderBar, setShowHeaderBar] = useState<boolean>(false)
  const [showScrollArrow, setShowScrollArrow] = useState<boolean>(false)

  const isMobile = window.innerWidth < 834
  const menuItems: IMenuItem[] = [
    {
      label: 'My Camp',
      url: '/my-camp',
      icon: <FestivalRounded />,
      protectedRoute: true,
      showBottomDivider: true,
    },
    {
      label: 'Scraiku',
      url: '/',
    },
    {
      label: 'Learn to Play',
      url: '/learn-how',
    },
    {
      label: 'Explore Seasons',
      url: '/seasons',
    },
  ]

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      {/* <ScrollArrow currentBoxIndex={currentBoxIndex} /> */}
      {/* {!isMobile && (
        <ScrollIndicator currentBoxIndex={currentBoxIndex} numberOfBoxes={1} />
      )} */}
      <CssBaseline />
      <HeaderBar
        colourScheme={colourScheme}
        currentPage={'/seasons'}
        isMobile={isMobile}
        menuItems={menuItems}
        show={showHeaderBar}
      />
      <SnapBoxWrapper
        isMobile={isMobile}
        setCurrentBoxIndex={setCurrentBoxIndex}
        setShowHeaderBar={setShowHeaderBar}
        setShowScrollArrow={setShowScrollArrow}
      >
        <Box
          id={'topBox'}
          position="relative"
          bgcolor={colourScheme === 'light' ? '#fff' : '#202020'}
        >
          <BackgroundGradientDiv
            colourScheme={colourScheme}
            isMobile={isMobile}
          />
          <SeasonsBlock01 isFocused={currentBoxIndex === 0} />
        </Box>
      </SnapBoxWrapper>
    </CssVarsProvider>
  )
}

const BackgroundGradientDiv = styled.div<IBackgroundGradientDivProps>(
  ({ colourScheme, isMobile, isReversed }) => ({
    position: 'absolute',
    width: isMobile ? '100%' : '50%',
    height: '100%',
    left: !isMobile && isReversed ? '50%' : '',
    backgroundColor: colourScheme === 'light' ? '#b8c6db' : '#33658A',
    backgroundImage:
      colourScheme === 'light'
        ? 'linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)'
        : 'linear-gradient(315deg, #33658A 0%, #170e13 74%)',
    zIndex: '0',
  }),
)

export default Seasons
