import { Typography, useColorScheme } from '@mui/joy'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ActionButton from '../../__components/ActionButton'
import FooterBar from '../../__components/FooterBar'
import TextHighlight from '../../__components/TextHighlight'
import TwoSidedLayout from '../../__components/TwoSidedLayout'

interface IProps {
  isFocused: boolean
}

const LearnHowBlock05 = ({ isFocused }: IProps) => {
  const { mode: colourScheme } = useColorScheme()
  const [hasFocused, setHasFocused] = useState(false)

  return (
    <>
      <TwoSidedLayout
        isFocused={isFocused}
        hasFocused={hasFocused}
        setHasFocused={setHasFocused}
        heroImage={require('../../../assets/images/learn-how-5.jpg')}
      >
        <Typography color="primary" fontSize="lg" fontWeight="lg">
          The journey ahead.
        </Typography>
        <Typography
          level="h1"
          fontWeight="xl"
          fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
        >
          Deck Building and Progression
        </Typography>
        <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
          As you immerse yourself in the world of{' '}
          <Typography fontWeight="lg">Scraiku</Typography>, you{"'"}ll embark on
          a <TextHighlight>unique journey</TextHighlight>.
        </Typography>
        <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
          <TextHighlight>Customize your deck</TextHighlight> with words tailored
          to your interests and objectives , crafting a formidable arsenal that
          reflects your unique learning path.
        </Typography>
        <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
          Are you ready to rise to the challenge and{' '}
          <TextHighlight>become the hero</TextHighlight> of your language
          learning adventure?
        </Typography>
        <RouterLink
          to={'/login'}
          state={{ openSignInByDefault: false }}
          style={{ marginTop: '20px' }}
        >
          <ActionButton text={'Join Now'} />
        </RouterLink>
      </TwoSidedLayout>
      <FooterBar colourScheme={colourScheme} />
    </>
  )
}

export default LearnHowBlock05
