import { Typography } from '@mui/joy'
import React, { useState } from 'react'

import TextHighlight from '../../__components/TextHighlight'
import TwoSidedLayout from '../../__components/TwoSidedLayout'

interface IProps {
  isFocused: boolean
}

const HeroBlock03 = ({ isFocused }: IProps) => {
  const [hasFocused, setHasFocused] = useState(false)

  return (
    <TwoSidedLayout
      isFocused={isFocused}
      hasFocused={hasFocused}
      setHasFocused={setHasFocused}
      heroImage={require('../../../assets/images/hero-3.jpg')}
    >
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        Unleash your potential.
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Master Words, Master Worlds!
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Simply memorizing words won&apos;t lead you to victory. A multitude of
        opponents await, each presenting{' '}
        <TextHighlight>unique challenges</TextHighlight> that will put your
        language skills to the test.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        From casual encounters to intense battles, you&apos;ll master diverse
        contexts and situations,
        <TextHighlight> honing your skills</TextHighlight> along the way.
      </Typography>
      <Typography
        fontSize="lg"
        textColor="text.secondary"
        lineHeight="lg"
        mb={2}
      >
        Are you ready to conquer them all and emerge as the{' '}
        <TextHighlight>ultimate language champion</TextHighlight>?
      </Typography>
    </TwoSidedLayout>
  )
}

export default HeroBlock03
