import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const InputsWrapper = ({ children }: IProps) => (
  <InputsWrapperDiv>{children}</InputsWrapperDiv>
)

const InputsWrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  margin: 10px 0;
`
export default InputsWrapper
