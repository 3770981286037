import styled from '@emotion/styled'
import React from 'react'

interface IProps {
  text: string
}

const Label = ({ text }: IProps) => <LabelSpan>{text}</LabelSpan>

const LabelSpan = styled.span`
  font-size: 20px;
  margin-top: 5px;
`

export default Label
