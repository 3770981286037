import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { Position } from '../../enums'

interface IProps {
  children: ReactNode
  position: Position
}

interface IHitPointsWrapperDivProps {
  position: Position
}

const HitPointsWrapper = ({ children, position }: IProps) => (
  <HitPointsWrapperDiv position={position}>{children}</HitPointsWrapperDiv>
)

const HitPointsWrapperDiv = styled.div<IHitPointsWrapperDivProps>(
  ({ position }) => ({
    display: 'flex',
    justifyContent: position === Position.Right ? 'flex-end' : 'flex-start',
    alignItems: 'baseline',
    userSelect: 'none',
    borderBottom: '1px solid #383838',
    paddingBottom: '5px',
    marginTop: '15px',
    width: '100px',
    
    '& > div:first-of-type': {
      marginRight: '5px',
    },
  })
)

export default HitPointsWrapper
