import { CookieRounded, Key } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Input,
  LinearProgress,
  Link,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  useColorScheme,
} from '@mui/joy'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import Cookies from 'js-cookie'
import React, { FormEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import { signUp } from '../api'
import { IUserLoginResult, SignUpFormElement } from '../types'
import { decodeJwtToken } from '../utils'

interface IProps {
  password: string
  passwordConfirm: string
  minPasswordLength: number
  toggleSignInForm: () => void
  setPassword: (value: string) => void
  setPasswordConfirm: (value: string) => void
}

interface ISignUpData {
  email: string
  password?: string
  isGoogle?: boolean
}

const SignUp = ({
  password,
  passwordConfirm,
  minPasswordLength,
  toggleSignInForm,
  setPassword,
  setPasswordConfirm,
}: IProps) => {
  const token = Cookies.get('token')

  const { mode } = useColorScheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [userCreationResult, setUserCreationResult] =
    useState<IUserLoginResult>({
      error: '',
      tokenReceived: false,
    })
  const [showCookiesDialog, setShowCookiesDialog] = useState(false)
  const [signUpData, setSignUpData] = useState<ISignUpData>({
    email: '',
    password: '',
  })
  const [emailAddress, setEmailAddress] = useState('')
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)

  const isMobile = window.innerWidth <= 834

  useEffect(() => {
    const { tokenReceived } = userCreationResult

    if (tokenReceived) {
      navigate('/my-camp')
    }
  }, [dispatch, navigate, userCreationResult])

  const googleAuthResponse = (response: CredentialResponse) => {
    if (response.credential) {
      const decodedGoogleAuthToken = decodeJwtToken(response.credential)
      const googleAuthData: ISignUpData = {
        email: decodedGoogleAuthToken.email,
        isGoogle: true,
      }

      setSignUpData(googleAuthData)
      setShowCookiesDialog(true)
    } else {
      googleAuthError()
    }
  }

  const googleAuthError = () => {
    setUserCreationResult({
      error: 'Google authentication failed',
      tokenReceived: false,
    })
  }

  const handleEmailAddressUpdate = (email: string) => {
    if (email.length <= 100) {
      setEmailAddress(email)
    }
  }

  const { error: userCreationError } = userCreationResult

  return (
    <>
      <Stack
        gap={4}
        sx={(theme) => ({
          userSelect: 'none',
          [theme.breakpoints.up(1680)]: {
            mb: 2,
          },
        })}
      >
        <Stack gap={1}>
          <Typography component="h1" level="h3">
            Sign up
          </Typography>
          <Typography level="body-sm">
            Already registered?{' '}
            <Link
              level="title-sm"
              onClick={() =>
                token ? navigate('/my-camp') : toggleSignInForm()
              }
            >
              Sign in!
            </Link>
          </Typography>
        </Stack>
        <Box
          display="light"
          justifyContent="center"
          sx={{
            colorScheme: 'light',
          }}
        >
          <GoogleLogin
            onSuccess={googleAuthResponse}
            onError={googleAuthError}
            locale="en"
            shape="rectangular"
            text="signup_with"
            theme={mode === 'light' ? 'outline' : 'filled_black'}
            width={isMobile ? 320 : 400}
          />
        </Box>
      </Stack>
      <Divider
        sx={(theme) => ({
          [theme.getColorSchemeSelector('light')]: {
            color: { xs: '#FFF', md: 'text.tertiary' },
          },
          userSelect: 'none',
        })}
      >
        or
      </Divider>
      <Stack
        gap={4}
        sx={(theme) => ({
          userSelect: 'none',
          [theme.breakpoints.up(1680)]: {
            mt: 2,
          },
        })}
      >
        <form
          onSubmit={(event: FormEvent<SignUpFormElement>) => {
            event.preventDefault()
            const formElements = event.currentTarget.elements
            const data = {
              email: formElements.email.value,
              password: formElements.password.value,
            }

            setSignUpData(data)
            setShowCookiesDialog(true)
          }}
        >
          <FormControl required>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              autoFocus={!isMobile}
              value={emailAddress}
              onChange={(e) => handleEmailAddressUpdate(e.target.value)}
            />
          </FormControl>
          <FormControl required>
            <Stack
              spacing={0.5}
              sx={{
                '--hue': Math.min(password.length * 10, 120),
              }}
            >
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                name="password"
                startDecorator={<Key />}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <LinearProgress
                determinate
                size="sm"
                value={Math.min(
                  (password.length * 100) / minPasswordLength,
                  100,
                )}
                sx={{
                  bgcolor: 'background.level3',
                  color: 'hsl(var(--hue) 80% 40%)',
                }}
              />
              <Typography
                level="body-xs"
                sx={{
                  alignSelf: 'flex-end',
                  color: mode === 'light' ? '#c41c1c' : '#e47474',
                }}
              >
                {password.length < 8 && 'Minimum of 8 characters'}
                {password.length >= 8 && <Box sx={{ height: '18px' }} />}
              </Typography>
              <FormLabel>Confirm Password</FormLabel>
            </Stack>
          </FormControl>
          <Input
            type="password"
            name="confirmPassword"
            startDecorator={<Key />}
            value={passwordConfirm}
            color={
              password === ''
                ? 'neutral'
                : password === passwordConfirm
                  ? 'success'
                  : 'danger'
            }
            onChange={(event) => setPasswordConfirm(event.target.value)}
          />
          <Box display="flex" flexDirection="row" alignItems="center">
            <Checkbox
              sx={{ mr: 0.5 }}
              checked={isTermsAccepted}
              onChange={() => setIsTermsAccepted(!isTermsAccepted)}
              size="sm"
              label="I accept the"
            />
            <RouterLink
              to={'/terms-and-conditions'}
              target="_blank"
              rel="noopener noreferrer"
              style={{ paddingBottom: '2px' }}
            >
              <Link level="title-sm">Terms & Conditions</Link>
            </RouterLink>
          </Box>
          {userCreationError && (
            <Typography color="danger" level="body-sm">
              {userCreationError}.
            </Typography>
          )}
          <Button
            type="submit"
            disabled={
              !emailAddress ||
              !isTermsAccepted ||
              password !== passwordConfirm ||
              !password ||
              password.length < 8
            }
            sx={{ userSelect: 'none', mt: 2 }}
            fullWidth
          >
            Register
          </Button>
        </form>
      </Stack>
      <Modal open={showCookiesDialog}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          sx={{ backgroundColor: mode === 'light' ? '#fbfcfe' : '#202020' }}
        >
          <DialogTitle>
            <CookieRounded />
            This website uses cookies
          </DialogTitle>
          <Divider />
          <DialogContent>
            This website uses cookies to store your authentication tokens (JWT)
            for maintaining your login session.
          </DialogContent>
          <DialogContent>
            By accepting, you agree to the use of these cookies.
            <br /> If you reject, you will not be able to log in or maintain
            your session.
          </DialogContent>
          <Box display="flex" flexDirection="row" alignItems="baseline">
            <Typography level="title-sm">Learn more here:</Typography>
            <RouterLink
              to={'/privacy-policy'}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '4px' }}
            >
              <Link level="title-md">Privacy Policy</Link>
            </RouterLink>
          </Box>
          <DialogActions>
            <Button
              variant="solid"
              color="primary"
              onClick={() =>
                signUp({
                  ...signUpData,
                  setShowCookiesDialog,
                  setUserCreationResult,
                })
              }
            >
              I accept
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setShowCookiesDialog(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  )
}

export default SignUp
