import styled from '@emotion/styled'
import React from 'react'

interface IProps {
  show: boolean
  onClick: () => void
  text: string
}

interface ICorrectionButtonSpanProps {
  show: boolean
}

const CorrectionButton = ({ show, onClick, text }: IProps) => (
  <CorrectionButtonSpan show={show} onClick={onClick}>
    {text}
  </CorrectionButtonSpan>
)

const CorrectionButtonSpan = styled.span<ICorrectionButtonSpanProps>(
  ({ show }) => ({
    color: '#fff',
    backgroundColor: '#3bb78f',
    backgroundImage: 'linear-gradient(315deg, #3bb78f 0%, #0bab64 74%)',
    borderRadius: '25px 0px 25px 25px',
    padding: '12px 24px',
    lineHeight: '32px',
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: '22px',
    boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)`,
    opacity: show ? '1' : '0',
    transition: 'opacity 0.5s ease-in-out',
    textAlign: 'center',
    marginTop: '30px',
    pointerEvents: 'auto',
  })
)

export default CorrectionButton
