import { ArrowForward } from '@mui/icons-material'
import { Button } from '@mui/joy'
import React, { useState } from 'react'

interface IProps {
  text: string
  alternateColour?: boolean
  width?: number
}

const ActionButton = ({ text, alternateColour, width }: IProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <Button
      size="lg"
      endDecorator={
        <ArrowForward
          sx={{
            transition: 'transform 0.3s ease',
            transform: isHovered ? 'translateX(5px)' : 'translateX(0)',
          }}
        />
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        backgroundColor: alternateColour ? '#00bfb2' : '#06bcfb',
        backgroundImage: alternateColour
          ? 'linear-gradient(315deg, #00bfb2 0%, #028090 74%)'
          : 'linear-gradient(315deg, #06bcfb 0%, #4884ee 74%)',
        width: width && `${width}px`,
      }}
    >
      {text}
    </Button>
  )
}

export default ActionButton
