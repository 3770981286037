import styled from '@emotion/styled'
import { Box, Typography } from '@mui/joy'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { IMenuItem } from '../../types'
import Menu from './Menu'

interface IProps {
  colourScheme: string | undefined
  currentPage: string
  isMobile: boolean
  menuItems: IMenuItem[]
  show: boolean
}

const HeaderBar = ({
  colourScheme,
  currentPage,
  isMobile,
  menuItems,
  show,
}: IProps) => (
  <Box
    py={1}
    px={3}
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    position="absolute"
    zIndex={2}
    width="100%"
    bgcolor={
      show
        ? colourScheme === 'light'
          ? '#e8eaf3'
          : '#1c1c1c'
        : isMobile
          ? ''
          : colourScheme === 'light'
            ? 'rgb(0, 0, 0, 0.05)'
            : 'rgb(255, 255, 255, 0.05)'
    }
    sx={(theme) => ({
      borderBottom: show
        ? `1px solid ${colourScheme === 'light' ? '#b6bacf' : '#9fa6ad'}`
        : '',
      transition: 'background-color 0.25s ease',
      pointerEvents: 'none',
      [theme.breakpoints.up(1680)]: {
        py: isMobile ? 1 : 3,
      },
    })}
  >
    <RouterLink
      to={'/'}
      style={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        userSelect: 'none',
        pointerEvents: 'all',
      }}
    >
      <LogoImg src={require('../../assets/images/logo.png')} />
      <Typography level="title-lg">Scraiku</Typography>
    </RouterLink>
    <Box sx={{ pointerEvents: 'all' }}>
      <Menu currentPage={currentPage} items={menuItems} />
    </Box>
  </Box>
)

const LogoImg = styled.img`
  height: 20px;
  margin-right: 10px;
`

export default HeaderBar
