import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  active: boolean
  children: ReactNode
  dealt: boolean
  index: number
  onCardClick: (active: boolean, title: string) => void
  show: boolean
  title: string
}

interface ICardContainerDivProps {
  dealt: boolean
  index: number
  show: boolean
}

const CardContainer = ({
  active,
  children,
  dealt,
  index,
  onCardClick,
  show,
  title,
}: IProps) => (
  <CardContainerDiv
    dealt={dealt}
    index={index}
    onClick={() => onCardClick(active, title)}
    show={show}
  >
    {children}
  </CardContainerDiv>
)

const CardContainerDiv = styled.div<ICardContainerDivProps>(
  ({ dealt, index, show }) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    maxWidth: '230px',
    aspectRatio: '0.75',
    userSelect: 'none',
    transform: dealt && show ? 'rotateY(180deg)' : '',
    transformStyle: 'preserve-3d',
    transition: `transform 0.75s`,
    transitionProperty: 'transform',
    transitionDelay: `${200 + index * 100}ms`,
    margin: '0',
    ':not(:last-child)': {
      margin: '0 32px 0 0',
    },

    '@media (max-width: 1280px), (max-height: 800px)': {
      maxWidth: '180px',
    },
  })
)

export default CardContainer
