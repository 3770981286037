import { FavoriteBorderRounded, FavoriteRounded } from '@mui/icons-material'
import {
  AspectRatio,
  Avatar,
  Box,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Typography,
} from '@mui/joy'
import React from 'react'

import { IUserProfile } from '../../../../state/types'

interface IProps {
  userProfile: Partial<IUserProfile>
}

const ProfileBox = ({ userProfile }: IProps) => {
  const { avatar, currentLanguage, username } = userProfile

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={(theme) => ({
          [theme.breakpoints.down(900)]: {
            flexDirection: 'column',
          },
        })}
      >
        <AspectRatio
          objectFit="contain"
          ratio="1/1"
          variant="outlined"
          sx={(theme) => ({
            borderRadius: 'md',
            [theme.breakpoints.up(600)]: {
              width: '40%',
            },
          })}
        >
          <Skeleton animation="wave" loading={!avatar}>
            {avatar && (
              <img
                src={require(`../../../../assets/images/profile_${avatar}.jpg`)}
                alt="avatar_image"
              />
            )}
          </Skeleton>
        </AspectRatio>
        <Box
          sx={(theme) => ({
            mt: 2,
            [theme.breakpoints.up(900)]: {
              mt: 0,
              pl: 2,
              width: '-webkit-fill-available',
            },
          })}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="end"
            alignSelf="flex-start"
            justifyContent="space-between"
          >
            <Typography
              fontSize="lg"
              fontWeight="md"
              lineHeight="100%"
              overflow="hidden"
              textAlign="left"
              mr={1}
              textOverflow="ellipsis"
              sx={{
                overflowX: 'hidden',
              }}
            >
              {username}
            </Typography>
            <AspectRatio
              objectFit="contain"
              ratio="1/1"
              sx={(theme) => ({
                alignSelf: 'flex-start',
                borderRadius: 'md',
                width: 32,
                height: 32,
                [theme.breakpoints.up(1680)]: {
                  width: 40,
                  height: 40,
                },
              })}
            >
              <Skeleton animation="wave" loading={!currentLanguage}>
                {currentLanguage && (
                  <img
                    src={`/images/${currentLanguage}.svg`}
                    alt="current_language"
                  />
                )}
              </Skeleton>
            </AspectRatio>
          </Box>
          {/* <Divider sx={{ mt: 1 }} /> */}
          <Box display="flex" flexDirection="column" mt={4}>
            <Typography fontWeight="lg" textAlign="start" mb={1}>
              Fatigue
            </Typography>
            <Box display="flex" flexDirection="row">
              <FavoriteRounded sx={{ color: '#d8585c' }} />
              <FavoriteRounded sx={{ color: '#d8585c' }} />
              <FavoriteRounded sx={{ color: '#d8585c' }} />
              <FavoriteRounded sx={{ color: '#d8585c' }} />
              <FavoriteBorderRounded />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 4 }} />
      <Box display="flex" flexDirection="column">
        <Typography fontWeight="lg" textAlign="start" ml={3}>
          Level
        </Typography>
        <Box display="flex" flexDirection="row">
          <Typography fontSize="lg" fontWeight="lg">
            1
          </Typography>
          <LinearProgress
            determinate
            value={25}
            variant="soft"
            size="lg"
            sx={{
              mx: 2,
              height: '16px',
              alignSelf: 'center',
              '&::before': {
                backgroundImage:
                  'linear-gradient(315deg, #06bcfb 0%, #4884ee 74%)',
              },
            }}
          />
          <Typography fontSize="lg" fontWeight="lg">
            2
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 4 }} />
      <Box display="flex" flexWrap="wrap" position="relative">
        <Box
          height="100%"
          width="100%"
          position="absolute"
          zIndex="2"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backdropFilter: 'blur(8px)',
          }}
        >
          <Typography fontSize="lg" fontWeight="lg">
            Achievements - Coming soon!
          </Typography>
        </Box>
        <Grid container columns={{ xs: 4 }} flexGrow={1} height="100%">
          {Array.from({ length: 12 }, (_, index) => `A${index + 1}`).map(
            (value, index) => (
              <Grid
                key={index}
                xs={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Avatar color="neutral" variant="soft" sx={{ mb: 2 }}>
                  {value}
                </Avatar>
              </Grid>
            ),
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default ProfileBox
