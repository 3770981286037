import { FestivalRounded } from '@mui/icons-material'
import {
  Box,
  Container,
  CssBaseline,
  Link,
  Typography,
  useColorScheme,
} from '@mui/joy'
import React, { useEffect, useRef, useState } from 'react'

import { IMenuItem } from '../../types'
import FooterBar from '../__components/FooterBar'
import HeaderBar from '../__components/HeaderBar'

const PrivacyPolicy = () => {
  const { mode: colourScheme } = useColorScheme()
  const boxRef = useRef(null)

  const [showHeaderBar, setShowHeaderBar] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      const box = boxRef.current as HTMLDivElement | null

      if (!box) return

      const scrollTop = box.scrollTop

      if (scrollTop > 40) {
        setShowHeaderBar(true)
      } else {
        setShowHeaderBar(false)
      }
    }

    const box = boxRef.current as HTMLDivElement | null

    if (box) {
      box.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (box) {
        box.removeEventListener('scroll', handleScroll)
      }
    }
  }, [setShowHeaderBar])

  const isMobile = window.innerWidth < 834
  const menuItems: IMenuItem[] = [
    {
      label: 'My Camp',
      url: '/my-camp',
      icon: <FestivalRounded />,
      protectedRoute: true,
      showBottomDivider: true,
    },
    {
      label: 'Scraiku',
      url: '/',
    },
    {
      label: 'Learn to Play',
      url: '/learn-how',
    },
    {
      label: 'Explore Seasons',
      url: '/seasons',
    },
  ]

  return (
    <Box
      ref={boxRef}
      style={{
        backgroundColor: colourScheme === 'light' ? '#fff' : '#202020',
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <CssBaseline />
      <HeaderBar
        colourScheme={colourScheme}
        currentPage={'/privacy-policy'}
        isMobile={isMobile}
        menuItems={menuItems}
        show={showHeaderBar}
      />
      <Container
        sx={(theme) => ({
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 4,
          gap: 4,
          [theme.breakpoints.down(834)]: {
            justifyContent: 'space-evenly',
          },
          [theme.breakpoints.up(834)]: {
            gap: 6,
          },
          [theme.breakpoints.up(1199)]: {
            gap: 12,
          },
        })}
      >
        <Box>
          <Box mt={10}>
            <Typography level="title-lg">Privacy Policy</Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Introduction
            </Typography>
            <Typography level="title-sm">
              Welcome to Scraiku. At Scraiku, we prioritize your privacy and are
              committed to protecting your personal information. This Privacy
              Policy outlines the types of information we collect from you, how
              we use and protect that information, and your rights regarding
              your personal data. By using our website, you consent to the data
              practices described in this policy.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Information We Collect
            </Typography>
            <Typography level="title-sm">
              We collect your email address when you create an account on our
              website. This information is essential for facilitating the login
              process and ensuring secure access to your account.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Use of Collected Information
            </Typography>
            <Typography level="title-sm">
              We use your email address solely for the purpose of:
              <br />
              <br />
              <li>Facilitating the login process</li>
              <li>Ensuring secure access to your account</li>
              <br />
              We do not use your email address for any other purposes, such as
              marketing or sharing with third parties.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Cookies
            </Typography>
            <Typography level="title-sm">
              Our website uses cookies for the purpose of storing JSON Web
              Tokens (JWT) to facilitate user login. These cookies are essential
              for the proper functioning of our website, allowing you to stay
              logged in as you navigate through our pages.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              User Consent
            </Typography>
            <Typography level="title-sm">
              By creating an account and using our website, you agree to our use
              of cookies. The cookies are necessary to provide you with a
              seamless and secure login experience. If you do not accept the use
              of cookies, you will not be able to create an account or use the
              site.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Data Protection
            </Typography>
            <Typography level="title-sm">
              We implement a variety of security measures to ensure the safety
              of your personal information. Your email address is stored in our
              secure database, and we take all necessary precautions to protect
              it from unauthorized access, alteration, or disclosure.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Your Rights
            </Typography>
            <Typography level="title-sm">
              You have the right to:
              <br />
              <br />
              <li>Access the personal information we hold about you</li>
              <li>Request the correction of inaccurate personal information</li>
              <li>Request the deletion of your personal information</li>
              <br />
              To exercise any of these rights, please contact us at{' '}
              <Link
                level="title-sm"
                href="mailto: privacy@scraiku.com"
                sx={{ ml: '2px' }}
              >
                privacy@scraiku.com
              </Link>
              .
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Changes to This Privacy Policy
            </Typography>
            <Typography level="title-sm">
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on
              this page. You are advised to review this Privacy Policy
              periodically for any changes. Changes to this Privacy Policy are
              effective when they are posted on this page.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Contact Us
            </Typography>
            <Typography level="title-sm" pb={4}>
              If you have any questions about this Privacy Policy, please
              contact us at:
              <br />
              <br />
              Scraiku
              <br />
              Email:{' '}
              <Link
                level="title-sm"
                href="mailto: privacy@scraiku.com"
                sx={{ ml: '2px' }}
              >
                privacy@scraiku.com
              </Link>
              <br />
              <br />
              Thank you for trusting Scraiku with your personal information. We
              are committed to keeping it safe and secure. posted on this page.
            </Typography>
          </Box>
        </Box>
      </Container>
      <FooterBar colourScheme={colourScheme} />
    </Box>
  )
}

export default PrivacyPolicy
