import styled from '@emotion/styled'
import React, { Dispatch, KeyboardEvent, SetStateAction, useRef } from 'react'

import { CurrentTurn, RequestStatus, TurnStatus } from '../../enums'
import { IActionStatus, ICard } from '../../types'
import { getActiveCards } from '../../utils'

interface IProps {
  aiActionStatus: IActionStatus
  currentInput: string
  currentTurn: CurrentTurn
  handleAttack: () => void
  handleDefence: () => void
  handleReviewContinue: () => void
  playerActionStatus: IActionStatus
  playerActiveCards: ICard[]
  playerHand: ICard[]
  setPlayerActiveCards: Dispatch<SetStateAction<ICard[]>>
  setCurrentInput: Dispatch<SetStateAction<string>>
}

interface IInputFieldInputProps {
  aiActionStatus: IActionStatus
  currentTurn: CurrentTurn
  playerActionStatus: IActionStatus
}

const isInputDisabled = (
  aiActionStatus: IActionStatus,
  currentTurn: CurrentTurn,
  playerActionStatus: IActionStatus
) =>
  aiActionStatus.requestStatus !== RequestStatus.Idle ||
  playerActionStatus.requestStatus !== RequestStatus.Idle ||
  (currentTurn === CurrentTurn.AI &&
    aiActionStatus.turnStatus !== TurnStatus.Started) ||
  (currentTurn === CurrentTurn.Player &&
    playerActionStatus.turnStatus !== TurnStatus.Started)

const InputField = ({
  aiActionStatus,
  currentInput,
  currentTurn,
  handleAttack,
  handleDefence,
  handleReviewContinue,
  playerActionStatus,
  playerActiveCards,
  playerHand,
  setCurrentInput,
  setPlayerActiveCards,
}: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const getInputPlaceHolder = (
    currentTurn: CurrentTurn,
    aiActionStatus: IActionStatus,
    playerActionStatus: IActionStatus
  ) => {
    if (
      aiActionStatus.turnStatus === TurnStatus.Ended &&
      playerActionStatus.turnStatus === TurnStatus.Ended
    ) {
      return 'Game over!'
    }

    if (currentTurn === CurrentTurn.Player) {
      if (playerActionStatus.turnStatus === TurnStatus.Paused) {
        return 'Attack blocked!'
      }

      return 'Craft a sentence to Attack...'
    }

    if (currentTurn === CurrentTurn.AI) {
      if (aiActionStatus.turnStatus === TurnStatus.Paused) {
        return 'Defence breached!'
      }

      return 'Translate the sentence to Defend...'
    }
  }

  const handleInputBlur = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 0)
  }

  const handleInputChange = (input: string) => {
    // Don't allow input while review is in progress or requests are processing
    // Still allows Enter key to continue from review
    if (
      aiActionStatus.requestStatus === RequestStatus.Idle &&
      playerActionStatus.requestStatus === RequestStatus.Idle &&
      (aiActionStatus.turnStatus === TurnStatus.Started ||
        playerActionStatus.turnStatus === TurnStatus.Started)
    ) {
      setCurrentInput(input)

      const currentActiveCards = getActiveCards(input, playerHand)
      setPlayerActiveCards(currentActiveCards)
    }
  }

  const handleInputSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (
        aiActionStatus.turnStatus === TurnStatus.Paused ||
        playerActionStatus.turnStatus === TurnStatus.Paused
      ) {
        handleReviewContinue()
      } else if (
        currentTurn === CurrentTurn.Player &&
        playerActionStatus.requestStatus === RequestStatus.Idle &&
        playerActionStatus.turnStatus === TurnStatus.Started &&
        !!playerActiveCards.length
      ) {
        handleAttack()
      } else if (
        currentTurn === CurrentTurn.AI &&
        aiActionStatus.requestStatus === RequestStatus.Idle &&
        aiActionStatus.turnStatus === TurnStatus.Started &&
        playerActionStatus.requestStatus === RequestStatus.Idle &&
        !!currentInput.length
      ) {
        handleDefence()
      }
    }
  }

  return (
    <InputFieldInput
      aiActionStatus={aiActionStatus}
      autoFocus
      currentTurn={currentTurn}
      onBlur={handleInputBlur}
      onChange={(event) => handleInputChange(event.target.value)}
      onKeyDown={handleInputSubmit}
      placeholder={getInputPlaceHolder(
        currentTurn,
        aiActionStatus,
        playerActionStatus
      )}
      playerActionStatus={playerActionStatus}
      ref={inputRef}
      spellCheck={false}
      value={currentInput}
    />
  )
}

const InputFieldInput = styled.input<IInputFieldInputProps>(
  ({ aiActionStatus, currentTurn, playerActionStatus }) => ({
    width: '100%',
    height: '40px',
    backgroundColor: '#202020',
    border: '3px solid #383838',
    borderRadius: '20px 0 0 20px',
    padding: '0 10px 0 20px',
    fontSize:
      'clamp(20px, calc(12px + (36 - 20) * (100vw - 768px) / (1920 - 768)), 28px)',
    lineHeight:
      'clamp(20px, calc(12px + (36 - 20) * (100vw - 768px) / (1920 - 768)), 28px)',
    color: '#ffffff',
    caretColor: isInputDisabled(aiActionStatus, currentTurn, playerActionStatus)
      ? 'transparent'
      : '',
    cursor: isInputDisabled(aiActionStatus, currentTurn, playerActionStatus)
      ? 'default'
      : 'text',
    '&:focus': {
      outline: 'none',
    },
  })
)

export default InputField
