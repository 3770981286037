import { Box, CircularProgress } from '@mui/joy'
import Cookies from 'js-cookie'
import { isEmpty, isNotEmpty, pathOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import PlayArea from '../../components/playArea'
import { GameStatus } from '../../enums'
import { getLanguageByCode } from '../../utils'
import { getActiveDeck } from './api'
import { IGetDeckResult } from './types'

const Play = () => {
  const token = Cookies.get('token')

  const location = useLocation()
  const currentLanguage: string | undefined = pathOr(
    undefined,
    ['state', 'currentLanguage'],
    location,
  )

  const [deckResult, setGetDeckResult] = useState<IGetDeckResult>()
  const [gameStatus, setGameStatus] = useState<GameStatus>(GameStatus.PreGame)

  useEffect(() => {
    if (token) {
      getActiveDeck({ token, setGetDeckResult })
    }
  }, [token])

  const endGame = () => {
    setGameStatus(GameStatus.PostGame)
  }

  const deck = pathOr([], ['deck'], deckResult)

  return (
    <Box
      display="flex"
      justifyContent="center"
      height="100vh"
      width="100vw"
      overflow="hidden"
      bgcolor="#202020"
    >
      {isEmpty(deck) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          height="100%"
          width="100%"
          zIndex="10"
          sx={{
            backdropFilter: 'blur(8px)',
          }}
        >
          <CircularProgress color="neutral" size="lg" variant="plain" />
        </Box>
      )}
      {isNotEmpty(deck) && currentLanguage && (
        <PlayArea
          cardPool={deck}
          endGame={endGame}
          selectedLanguage={getLanguageByCode(currentLanguage)}
        />
      )}
    </Box>
  )
}

export default Play
