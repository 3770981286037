import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
  show: boolean
}

const Correction = ({ children, show }: IProps) =>
  show ? <CorrectionDiv>{children}</CorrectionDiv> : null

const CorrectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

export default Correction
