import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IUserProfile, IUserState } from './types'

const initialState: IUserState = {
  userProfile: {},
  wasRedirected: false,
}

export const serverSlice = createSlice({
  name: 'userState',
  initialState,
  reducers: {
    updateUserProfile: (
      state,
      action: PayloadAction<Partial<IUserProfile>>,
    ) => {
      state.userProfile = action.payload
    },
    updateWasRedirected: (state, action: PayloadAction<boolean>) => {
      state.wasRedirected = action.payload
    },
  },
})

export const { updateUserProfile, updateWasRedirected } = serverSlice.actions

export default serverSlice.reducer
