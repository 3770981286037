import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { NotificationType, Position } from '../../enums'

interface IProps {
  children: ReactNode
  colour: string
  position: Position
  type: NotificationType
}

interface IToastContainerDivProps {
  colour: string
  position: Position
  type: NotificationType
}

const ToastContainer = ({ children, colour, position, type }: IProps) => (
  <ToastContainerDiv colour={colour} position={position} type={type}>
    {children}
  </ToastContainerDiv>
)

const ToastContainerDiv = styled.div<IToastContainerDivProps>(
  ({ colour, position, type }) => ({
    position: 'relative',
    background: colour,
    height: '40px',
    width: '150px',
    textAlign: 'center',
    lineHeight: '40px',
    margin: position === Position.Left ? '20px 0 0 -50px' : '0 -50px 20px 0',
    overflow: 'hidden',
    borderRadius: position === Position.Left ? '0 25px 0 0' : '25px 0 0 0',
    fontSize: type === NotificationType.Defence ? '18px' : '28px',
    boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '0',
      right: position === Position.Left ? '0' : '',
      left: position === Position.Right ? '0' : '',
      borderWidth:
        position === Position.Left ? '0 16px 16px 0' : '0px 0px 16px 16px',
      borderStyle: 'solid',
      borderColor: `${colour} transparent`,
      borderRadius: position === Position.Left ? '0 0 0 5px' : '0 0 5px 0',
      filter: 'drop-shadow(2px 4px 6px #000)',
    },
  })
)

export default ToastContainer
