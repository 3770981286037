import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { Position } from '../../enums'

interface IProps {
  children: ReactNode
  position: Position
}

interface INotificationsWrapperDivProps {
  position: Position
}

const NotificationsWrapper = ({ children, position }: IProps) => (
  <NotificationsWrapperDiv position={position}>
    {children}
  </NotificationsWrapperDiv>
)

const NotificationsWrapperDiv = styled.div<INotificationsWrapperDivProps>(
  ({ position }) => ({
    display: 'flex',
    flexDirection: position === Position.Left ? 'column' : 'column-reverse',
  })
)

export default NotificationsWrapper
