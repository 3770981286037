import { CheckCircleRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Input,
  Modal,
  ModalDialog,
  Radio,
  radioClasses,
  RadioGroup,
  Sheet,
  Tooltip,
  Typography,
  useColorScheme,
} from '@mui/joy'
import Cookies from 'js-cookie'
import { isNotEmpty } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import profileAvatar1 from '../../../../assets/images/profile_avatar_1.jpg'
import profileAvatar2 from '../../../../assets/images/profile_avatar_2.jpg'
import profileAvatar3 from '../../../../assets/images/profile_avatar_3.jpg'
import profileAvatar4 from '../../../../assets/images/profile_avatar_4.jpg'
import profileAvatar5 from '../../../../assets/images/profile_avatar_5.jpg'
import { updateUserProfile } from '../../../../state/userState'
import { getLanguageByCode } from '../../../../utils'
import TextHighlight from '../../../__components/TextHighlight'
import { createProfile, getLanguages } from './api'
import { ICreateProfileResult, IGetLanguagesResult } from './types'

interface IProps {
  token: string | undefined
}

const ProfileCreationDialog = ({ token }: IProps) => {
  const { mode } = useColorScheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(true)
  const [selectedAvatar, setSelectedAvatar] = useState<string>('avatar_1')
  const [selectedLanguage, setSelectedLanguage] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [getLanguagesResult, setGetLanguagesResult] =
    useState<IGetLanguagesResult>({ error: '', languages: [] })
  const [createProfileResult, setCreateProfileResult] =
    useState<ICreateProfileResult>({ error: '', userProfile: {} })
  const [isProfileCreating, setIsProfileCreating] = useState(false)

  const isMobile = window.innerWidth <= 834

  useEffect(() => {
    getLanguages({ setGetLanguagesResult })
  }, [])

  useEffect(() => {
    const { error, userProfile } = createProfileResult

    if (!error && isNotEmpty(userProfile)) {
      const {
        active,
        active_deck_id,
        avatar,
        current_language,
        id,
        user_id,
        username,
      } = userProfile

      dispatch(
        updateUserProfile({
          active,
          avatar,
          id,
          username,
          activeDeckId: active_deck_id,
          currentLanguage: current_language,
          userId: user_id,
        }),
      )
      setIsModalOpen(false)
    }
  }, [dispatch, createProfileResult])

  const handleCancel = () => {
    Cookies.remove('token')
    navigate('/login', { state: { openSignInByDefault: true } })
  }

  const handleUsernameUpdate = (username: string) => {
    if (username.length <= 20) {
      setUsername(username)
    }
  }

  const handleCreateProfile = () => {
    if (token) {
      setIsProfileCreating(true)
      createProfile({
        token,
        username,
        selectedAvatar,
        selectedLanguage,
        setCreateProfileResult,
      })
    }
  }

  const getProfileAvatar = (avatar: string) => {
    switch (avatar) {
      case 'avatar_1':
        return profileAvatar1
      case 'avatar_2':
        return profileAvatar2
      case 'avatar_3':
        return profileAvatar3
      case 'avatar_4':
        return profileAvatar4
      case 'avatar_5':
        return profileAvatar5
      default:
        return profileAvatar1
    }
  }

  const avatars = ['avatar_1', 'avatar_2', 'avatar_3', 'avatar_4', 'avatar_5']
  const { languages } = getLanguagesResult

  return (
    <Modal open={isModalOpen}>
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        sx={{ backgroundColor: mode === 'light' ? '#fbfcfe' : '#202020' }}
      >
        <DialogTitle>Welcome, Hero!</DialogTitle>
        <Divider />
        <DialogContent>
          Your quest begins here!
          <br />
          But first, let&apos;s learn a bit more about you...
        </DialogContent>
        <Box>
          {!username && !selectedLanguage && (
            <Typography fontWeight="sm">I am...</Typography>
          )}
          {!!username && !selectedLanguage && (
            <Typography fontWeight="sm">
              I am the Hero <TextHighlight isBold>{username}</TextHighlight>!
            </Typography>
          )}
          {!username && !!selectedLanguage && (
            <Typography fontWeight="sm">
              It is my destiny to learn{' '}
              <TextHighlight isBold>
                {getLanguageByCode(selectedLanguage)}
              </TextHighlight>
              !
            </Typography>
          )}
          {!!username && !!selectedLanguage && (
            <Typography fontWeight="sm">
              I am the Hero <TextHighlight isBold>{username}</TextHighlight>,
              and it is my destiny to learn{' '}
              <TextHighlight isBold>
                {getLanguageByCode(selectedLanguage)}
              </TextHighlight>
              !
            </Typography>
          )}
        </Box>
        <RadioGroup
          aria-label="avatars"
          defaultValue={selectedAvatar}
          overlay
          name="avatars"
          sx={{
            flexDirection: 'row',
            gap: 2,
            overflowX: 'auto',
            py: 1,
            pb: 3,
            px: 0.5,
            [`& .${radioClasses.checked}`]: {
              [`& .${radioClasses.action}`]: {
                inset: -1,
                border: '3px solid',
                borderColor: '#606b6c',
              },
            },
            [`& .${radioClasses.radio}`]: {
              display: 'contents',
              '& > svg': {
                zIndex: 2,
                position: 'absolute',
                top: '-8px',
                right: '-8px',
                bgcolor: 'background.surface',
                borderRadius: '50%',
                fill: mode === 'light' ? '#606b6c' : '#fff',
              },
            },
          }}
        >
          {avatars.map((avatar) => (
            <Sheet
              key={avatar}
              variant="outlined"
              sx={{
                backgroundImage: `url(${getProfileAvatar(avatar)})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: 'md',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1.5,
                p: 2,
                minWidth: 100,
                minHeight: 100,
                boxShadow: selectedAvatar === avatar ? 'md' : 'sm',
                transform:
                  selectedAvatar === avatar ? 'scale(1.05)' : 'scale(1)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  boxShadow: 'md',
                  transform: 'scale(1.05)',
                },
              }}
            >
              <Radio
                id={avatar}
                value={avatar}
                checkedIcon={<CheckCircleRounded />}
                onChange={() => setSelectedAvatar(avatar)}
              />
            </Sheet>
          ))}
        </RadioGroup>
        <Input
          type="text"
          name="profile-username"
          placeholder="My name is..."
          onChange={(e) => handleUsernameUpdate(e.target.value)}
          value={username}
          autoFocus={!isMobile}
          sx={{ mb: 2 }}
        />
        <Typography fontWeight="sm">I want to learn...</Typography>
        <RadioGroup
          aria-label="languages"
          overlay
          sx={{
            gap: 2,
            flexWrap: 'wrap',
            flexDirection: 'row',
            [`& .${radioClasses.checked}`]: {
              [`& .${radioClasses.action}`]: {
                inset: -1,
                border: '2px solid',
                borderColor: '#606b6c',
              },
            },
            [`& .${radioClasses.radio}`]: {
              display: 'contents',
              '& > svg': {
                zIndex: 2,
                position: 'absolute',
                top: '-8px',
                right: '-8px',
                bgcolor: 'background.surface',
                borderRadius: '50%',
                fill: mode === 'light' ? '#606b6c' : '#fff',
              },
            },
          }}
        >
          {languages.map(({ code }) => (
            <Tooltip
              key={code}
              title={getLanguageByCode(code)}
              arrow={false}
              color="neutral"
              placement="bottom"
              size="md"
              variant="soft"
            >
              <Sheet
                key={code}
                sx={{
                  backgroundImage: `url(/images/${code}.svg)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  position: 'relative',
                  minWidth: 40,
                  minHeight: 40,
                  flexShrink: 0,
                  borderRadius: 'md',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: selectedLanguage === code ? 'md' : 'sm',
                  transform:
                    selectedLanguage === code ? 'scale(1.05)' : 'scale(1)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: 'md',
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Radio
                  id={code}
                  value={code}
                  checkedIcon={<CheckCircleRounded />}
                  onChange={() => setSelectedLanguage(code)}
                />
              </Sheet>
            </Tooltip>
          ))}
        </RadioGroup>
        <DialogActions>
          <Button
            variant="solid"
            color="primary"
            onClick={handleCreateProfile}
            disabled={!username || !selectedLanguage || isProfileCreating}
          >
            Let&apos;s go!
          </Button>
          <Button variant="plain" color="neutral" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}

export default ProfileCreationDialog
