import React, { useEffect, useState } from 'react'

interface IProps {
  start: number
  end: number
}

const Counter = ({ start, end }: IProps) => {
  const [value, setValue] = useState(start)

  useEffect(() => {
    if (value > end) {
      setTimeout(() => {
        setValue(value - 1)
      }, 100)
    }

    if (start === end) {
      setValue(start)
    }
  }, [start, end, value])

  return <div>{value}</div>
}

export default Counter
