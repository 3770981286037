import { FestivalRounded } from '@mui/icons-material'
import {
  Box,
  Container,
  CssBaseline,
  Link,
  Typography,
  useColorScheme,
} from '@mui/joy'
import React, { useEffect, useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { IMenuItem } from '../../types'
import FooterBar from '../__components/FooterBar'
import HeaderBar from '../__components/HeaderBar'

const TermsAndConditions = () => {
  const { mode: colourScheme } = useColorScheme()
  const boxRef = useRef(null)

  const [showHeaderBar, setShowHeaderBar] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      const box = boxRef.current as HTMLDivElement | null

      if (!box) return

      const scrollTop = box.scrollTop

      if (scrollTop > 40) {
        setShowHeaderBar(true)
      } else {
        setShowHeaderBar(false)
      }
    }

    const box = boxRef.current as HTMLDivElement | null

    if (box) {
      box.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (box) {
        box.removeEventListener('scroll', handleScroll)
      }
    }
  }, [setShowHeaderBar])

  const isMobile = window.innerWidth < 834
  const menuItems: IMenuItem[] = [
    {
      label: 'My Camp',
      url: '/my-camp',
      icon: <FestivalRounded />,
      protectedRoute: true,
      showBottomDivider: true,
    },
    {
      label: 'Scraiku',
      url: '/',
    },
    {
      label: 'Learn to Play',
      url: '/learn-how',
    },
    {
      label: 'Explore Seasons',
      url: '/seasons',
    },
  ]

  return (
    <Box
      ref={boxRef}
      style={{
        backgroundColor: colourScheme === 'light' ? '#fff' : '#202020',
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <CssBaseline />
      <HeaderBar
        colourScheme={colourScheme}
        currentPage={'/terms-and-conditions'}
        isMobile={isMobile}
        menuItems={menuItems}
        show={showHeaderBar}
      />
      <Container
        sx={(theme) => ({
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 4,
          gap: 4,
          [theme.breakpoints.down(834)]: {
            justifyContent: 'space-evenly',
          },
          [theme.breakpoints.up(834)]: {
            gap: 6,
          },
          [theme.breakpoints.up(1199)]: {
            gap: 12,
          },
        })}
      >
        <Box>
          <Box mt={10}>
            <Typography level="title-lg">Terms & Conditions</Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Introduction
            </Typography>
            <Typography level="title-sm">
              Welcome to Scraiku! These Terms and Conditions govern your use of
              our website and services. By accessing or using our website, you
              agree to comply with and be bound by these Terms and Conditions.
              Please read them carefully.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Account Registration
            </Typography>
            <Typography level="title-sm">
              To use certain features of our website, such as our language
              learning card game, you must create an account. You can sign up
              using your email address or through Google Auth. By creating an
              account, you agree to provide accurate and complete information
              and to keep your account information up to date.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Privacy Policy
            </Typography>
            <Typography level="title-sm">
              Your use of our website is also governed by our{' '}
              <RouterLink
                to={'/privacy-policy'}
                style={{ paddingBottom: '2px' }}
              >
                <Link level="title-sm">Privacy Policy</Link>
              </RouterLink>
              , which is incorporated into these Terms and Conditions by this
              reference. Please review our Privacy Policy to understand our
              practices regarding the collection, use, and protection of your
              personal information.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Use of Cookies
            </Typography>
            <Typography level="title-sm">
              Our website uses cookies to store a JSON Web Tokens (JWT) for user
              login purposes. By creating an account and using our site, you
              consent to our use of cookies as described in our Privacy Policy.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Content Generation
            </Typography>
            <Typography level="title-sm">
              Scraiku uses the Anthropic AI API for content generation in our
              language learning card game. The responses generated in the target
              learning language are powered by this AI technology. By using our
              services, you acknowledge and accept the use of this technology.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              User Conduct
            </Typography>
            <Typography level="title-sm">
              You agree to use our website and services in a manner that is
              lawful, respectful, and consistent with these Terms and
              Conditions.
              <br />
              You must not:
              <br />
              <br />
              <li>Use our website for any illegal or unauthorized purpose.</li>
              <li>
                Interfere with or disrupt the functionality of our website.
              </li>
              <li>
                Attempt to gain unauthorized access to any part of our website
                or its related systems.
              </li>
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Intellectual Property
            </Typography>
            <Typography level="title-sm">
              All content on our website, including text, graphics, logos, and
              software, is the property of Scraiku or its content suppliers and
              is protected by intellectual property laws. You may not use,
              reproduce, or distribute any content from our website without our
              express written permission.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Limitation of Liability
            </Typography>
            <Typography level="title-sm">
              Scraiku is not liable for any direct, indirect, incidental, or
              consequential damages arising from your use of our website or
              services. This includes, but is not limited to, damages for loss
              of profits, data, or other intangible losses.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Modifications to Terms and Conditions
            </Typography>
            <Typography level="title-sm">
              We reserve the right to modify these Terms and Conditions at any
              time. Any changes will be posted on this page, and your continued
              use of our website after such changes have been posted constitutes
              your acceptance of the new Terms and Conditions.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Termination
            </Typography>
            <Typography level="title-sm">
              We reserve the right to terminate or suspend your account and
              access to our website at our discretion, without notice, for
              conduct that we believe violates these Terms and Conditions or is
              harmful to other users of the website, us, or third parties, or
              for any other reason.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Governing Law
            </Typography>
            <Typography level="title-sm">
              These Terms and Conditions are governed by and construed in
              accordance with the laws of the jurisdiction in which Scraiku
              operates, without regard to its conflict of law principles.
            </Typography>
            <Typography level="title-md" mt={4} mb={2}>
              Contact Us
            </Typography>
            <Typography level="title-sm" pb={4}>
              If you have any questions about these Terms and Conditions, please
              contact us at: <br />
              <br />
              Scraiku
              <br />
              Email:{' '}
              <Link
                level="title-sm"
                href="mailto: privacy@scraiku.com"
                sx={{ ml: '2px' }}
              >
                support@scraiku.com
              </Link>
              <br />
              <br />
              By using our website and services, you acknowledge that you have
              read, understood, and agree to be bound by these Terms and
              Conditions.
              <br />
              Thank you for choosing Scraiku!
            </Typography>
          </Box>
        </Box>
      </Container>
      <FooterBar colourScheme={colourScheme} />
    </Box>
  )
}

export default TermsAndConditions
