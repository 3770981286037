import styled from '@emotion/styled'
import React from 'react'

import { CurrentTurn, Position } from '../../enums'

interface IProps {
  currentTurn: CurrentTurn
  gameStarted: boolean
  position: Position
}

interface ITurnIndicatorDivProps {
  currentTurn: CurrentTurn
  gameStarted: boolean
  position: Position
}

const getOpacity = (
  currentTurn: CurrentTurn,
  gameStarted: boolean,
  position: Position
) => {
  if (
    gameStarted &&
    ((currentTurn === CurrentTurn.AI && position === Position.Top) ||
      (currentTurn === CurrentTurn.Player && position === Position.Bottom))
  ) {
    return '1'
  }

  return '0'
}

const TurnIndicator = ({ currentTurn, gameStarted, position }: IProps) => (
  <TurnIndicatorDiv
    currentTurn={currentTurn}
    gameStarted={gameStarted}
    position={position}
  />
)

const TurnIndicatorDiv = styled.div<ITurnIndicatorDivProps>(
  ({ currentTurn, gameStarted, position }) => ({
    width: '50%',
    borderRadius: '50%',
    boxShadow:
      '0 0 60px 30px #b3446c,0 0 100px 60px #b3446c,0 0 140px 90px #b3446c',
    position: 'absolute',
    bottom: position === Position.Bottom ? '-75px' : '',
    top: position === Position.Top ? '-75px' : '',
    zIndex: '0',
    opacity: getOpacity(currentTurn, gameStarted, position),
    transition: 'opacity 0.75s ease-in-out',
  })
)

export default TurnIndicator
