import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const DamageContainer = ({ children }: IProps) => (
  <DamageContainerDiv>{children}</DamageContainerDiv>
)

const DamageContainerDiv = styled.div`
  display: flex;
  position: absolute;
  align-self: center;
  height: 50%;
  top: 50%;
  zindex: 1;
`

export default DamageContainer
