import styled from '@emotion/styled'
import React, { PropsWithChildren } from 'react'

interface IProps {
  isBold?: boolean
}

interface ITextHighlightSpan {
  isBold?: boolean
}

const TextHighlight = ({ isBold, children }: PropsWithChildren<IProps>) => (
  <TextHighlightSpan isBold={isBold}>{children}</TextHighlightSpan>
)

const TextHighlightSpan = styled.span<ITextHighlightSpan>(({ isBold }) => ({
  backgroundImage: 'linear-gradient(315deg, #ffab00 0%, #fc575e 74%)',
  '-webkit-background-clip': 'text',
  backgroundClip: 'text',
  color: 'transparent',
  fontWeight: isBold ? '600' : '400',
}))

export default TextHighlight
