import styled from '@emotion/styled'
import React from 'react'

interface IProps {
  difficulty: number
}

const DamageValue = ({ difficulty }: IProps) => (
  <DamageValueDiv>{difficulty}</DamageValueDiv>
)

const DamageValueDiv = styled.div`
  color: #ffffff;
  font-size: clamp(
    16px,
    calc(36px + (36 - 20) * (100vw - 768px) / (1920 - 768)),
    72px
  );
  font-weight: 600;
  align-self: center;
`

export default DamageValue
