import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { CurrentTurn, RequestStatus, TurnStatus } from '../../enums'
import { IActionStatus } from '../../types'

interface IProps {
  aiActionStatus: IActionStatus
  isActiveCards: boolean
  isInput: boolean
  currentTurn: CurrentTurn
}

interface IButtonImageImgProps {
  aiActionStatus: IActionStatus
  isActiveCards: boolean
  isInput: boolean
  currentTurn: CurrentTurn
}

const isButtonEnabled = (
  aiActionStatus: IActionStatus,
  isActiveCards: boolean,
  isInput: boolean,
  currentTurn: CurrentTurn
) =>
  isActiveCards ||
  (currentTurn === CurrentTurn.AI &&
    aiActionStatus.requestStatus === RequestStatus.Idle &&
    aiActionStatus.turnStatus === TurnStatus.Started &&
    isInput)

const ButtonImage = ({
  aiActionStatus,
  isActiveCards,
  isInput,
  currentTurn,
}: IProps) => (
  <ButtonImageImg
    aiActionStatus={aiActionStatus}
    isActiveCards={isActiveCards}
    isInput={isInput}
    currentTurn={currentTurn}
    src={
      currentTurn === CurrentTurn.Player
        ? require('../../assets/images/attack.png')
        : require('../../assets/images/shield.png')
    }
  />
)

const transformScale = keyframes`
    0% {transform: scale(1)}
    50% {transform: scale(1.05)}
    100% {transform: scale(1)}
`

const ButtonImageImg = styled.img<IButtonImageImgProps>(
  ({ aiActionStatus, isActiveCards, isInput, currentTurn }) => ({
    height: '28px',
    alignSelf: 'center',
    filter: 'invert(1)',
    opacity: isButtonEnabled(
      aiActionStatus,
      isActiveCards,
      isInput,
      currentTurn
    )
      ? '1'
      : '0.33',
    transition: 'opacity 0.25s ease-in-out',
    animation: isButtonEnabled(
      aiActionStatus,
      isActiveCards,
      isInput,
      currentTurn
    )
      ? `${transformScale} 0.5s ease-in-out infinite`
      : '',
  })
)

export default ButtonImage
