import { Typography, useColorScheme } from '@mui/joy'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ActionButton from '../../__components/ActionButton'
import FooterBar from '../../__components/FooterBar'
import TextHighlight from '../../__components/TextHighlight'
import TwoSidedLayout from '../../__components/TwoSidedLayout'

interface IProps {
  isFocused: boolean
}

const HeroBlock05 = ({ isFocused }: IProps) => {
  const { mode: colourScheme } = useColorScheme()
  const [hasFocused, setHasFocused] = useState(false)

  return (
    <>
      <TwoSidedLayout
        isFocused={isFocused}
        hasFocused={hasFocused}
        setHasFocused={setHasFocused}
        heroImage={require('../../../assets/images/hero-5.jpg')}
      >
        <Typography color="primary" fontSize="lg" fontWeight="lg">
          Take the leap.
        </Typography>
        <Typography
          level="h1"
          fontWeight="xl"
          fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
        >
          See You in Scraiku!
        </Typography>
        <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
          <TextHighlight>Sign up now</TextHighlight> to unlock your language
          learning adventure today!
        </Typography>
        <RouterLink
          to={'/login'}
          state={{ openSignInByDefault: false }}
          style={{ marginTop: '20px' }}
        >
          <ActionButton text={'Join Now'} />
        </RouterLink>
      </TwoSidedLayout>
      <FooterBar colourScheme={colourScheme} />
    </>
  )
}

export default HeroBlock05
