import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
  show: boolean
}

const PlayAreaMask = ({ children, show }: IProps) =>
  show ? <PlayAreaMaskDiv>{children}</PlayAreaMaskDiv> : null

const PlayAreaMaskDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(32, 32, 32, 0.5);
  z-index: 3;
`

export default PlayAreaMask
