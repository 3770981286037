import { motion } from 'framer-motion'
import React, { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'

import { CurrentTurn, Position, RequestStatus, TurnStatus } from '../../enums'
import { selectPlayerHand } from '../../state/gameStateSelectors'
import { IActionStatus, ICard } from '../../types'
import { getActiveCards } from '../../utils'
import Card from '../card'

interface IProps {
  activeCards: ICard[]
  actionStatus: IActionStatus
  currentInput: string
  currentTurn: CurrentTurn
  dealt: boolean
  difficulty: number
  hand: ICard[]
  index: number
  position: Position
  setCurrentInput: Dispatch<SetStateAction<string>>
  setPlayerActiveCards: Dispatch<SetStateAction<ICard[]>>
  show: boolean | undefined
  title: string
}

const CardWrapper = ({
  activeCards,
  actionStatus,
  currentInput,
  currentTurn,
  dealt,
  difficulty,
  hand,
  index,
  position,
  setCurrentInput,
  setPlayerActiveCards,
  show,
  title,
}: IProps) => {
  const playerHand = useSelector(selectPlayerHand)

  const isCardActive = (
    activeCards: ICard[],
    currentTurn: CurrentTurn,
    playAreaLocation: Position,
    title: string
  ) =>
    !!activeCards.find((card) => card.title === title) &&
    ((currentTurn === CurrentTurn.AI && playAreaLocation === Position.Top) ||
      (currentTurn === CurrentTurn.Player &&
        playAreaLocation === Position.Bottom))

  const onCardClick = (active: boolean, title: string) => {
    if (
      currentTurn === CurrentTurn.Player &&
      actionStatus.requestStatus === RequestStatus.Idle &&
      actionStatus.turnStatus === TurnStatus.Started
    ) {
      let updatedInput = ''

      if (active) {
        const regex = new RegExp(`(\\b${title}\\b\\s*)`, 'g')
        updatedInput = currentInput.replace(regex, '')

        setCurrentInput(updatedInput)
      } else {
        if (
          !currentInput.length ||
          currentInput.charAt(currentInput.length - 1) === ' '
        ) {
          updatedInput = currentInput + `${title} `
        } else {
          updatedInput = currentInput + ` ${title} `
        }

        setCurrentInput(updatedInput)
      }

      const currentActiveCards = getActiveCards(updatedInput, playerHand)
      setPlayerActiveCards(currentActiveCards)
    }
  }

  return (
    <motion.div
      // This key should be a unique ID, should generate one per card on the card object itself
      // Once unique ID is added, use it in place of all title === title checks
      // Do this once cards are stored in an actual DB
      key={`${title}`}
      initial={{
        opacity: 0,
        y: position === Position.Top ? -200 : 200,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{
        opacity: 0,
        y: position === Position.Top ? -200 : 200,
      }}
      transition={{
        duration: 0.5,
        layout: { duration: 0.75 },
        delay: index * 0.1,
      }}
      layout="position"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        marginRight: index === hand.length - 1 ? 'auto' : '32px',
      }}
    >
      <Card
        actionStatus={actionStatus}
        active={isCardActive(activeCards, currentTurn, position, title)}
        dealt={dealt}
        difficulty={difficulty}
        index={index}
        onCardClick={onCardClick}
        playAreaLocation={position}
        show={show!}
        title={title}
      />
    </motion.div>
  )
}

export default CardWrapper
