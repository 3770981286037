import axios from 'axios'
import { isNotEmpty, pathOr } from 'ramda'

import { IUserProfileResponse } from '../../types'
import { IGetUserProfileResult } from './types'

interface IGetUserProfile {
  token: string
  setGetUserProfileResult: (props: IGetUserProfileResult) => void
}

export const getUserProfile = ({
  token,
  setGetUserProfileResult,
}: IGetUserProfile) => {
  axios
    .get('https://scrai-930ca0ac412a.herokuapp.com/api/get-user-profile', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const userProfile: Partial<IUserProfileResponse> = pathOr(
        {},
        ['data', 'userProfile'],
        res,
      )

      if (isNotEmpty(userProfile)) {
        setGetUserProfileResult({
          error: '',
          isFetched: true,
          userProfile,
        })
      } else {
        setGetUserProfileResult({
          error: 'No user profile created yet',
          isFetched: true,
          userProfile: {},
        })
      }
    })
    .catch((err) => {
      const error = pathOr(
        'Failed to fetch user profile',
        ['response', 'data', 'error'],
        err,
      )
      setGetUserProfileResult({ error, isFetched: false, userProfile: {} })
      console.error(error)
    })
}
