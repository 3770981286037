import styled from '@emotion/styled'
import React from 'react'

interface IProps {
  onClick: () => void
  text: string
}

const Button = ({ onClick, text }: IProps) => (
  <ButtonDiv onClick={onClick}>{text}</ButtonDiv>
)

const ButtonDiv = styled.div`
  align-self: center;
  color: #fff;
  background-color: #3bb78f;
  background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
  border-radius: 25px;
  padding: 12px;
  line-height: 32px;
  cursor: pointer;
  user-select: none;
  font-size: 22px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  width: 160px;
`

export default Button
