export enum CardRarity {
  Common = 'common',
  Uncommon = 'uncommon',
  Rare = 'rare',
}

export enum Position {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
}

export enum CurrentTurn {
  AI = 'ai',
  Player = 'player',
  None = 'none',
}

export enum GameStatus {
  PreGame = 'pre-game',
  Live = 'live',
  PostGame = 'post-game',
}

export enum TurnStatus {
  Idle = 'idle',
  Started = 'started',
  Paused = 'paused',
  Ended = 'ended',
}

export enum RequestStatus {
  Idle = 'idle',
  Sent = 'sent',
  Completed = 'completed',
}

export enum Target {
  AI = 'ai',
  Player = 'player',
  Both = 'both',
}

export enum NotificationType {
  Attack = 'attack',
  Defence = 'defence',
  Cards = 'cards',
}

export enum Victor {
  AI = 'ai',
  Player = 'player',
  Tie = 'tie',
}
