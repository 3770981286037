import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Position, Target } from '../../enums'
import { selectNotifications } from '../../state/gameStateSelectors'
import Counter from '../counter'
import Toast from '../toast'
import DetailsWrapper from './DetailsWrapper'
import HitPointsWrapper from './HitPointsWrapper'
import Label from './Label'
import NotificationsWrapper from './NotificationsWrapper'
import SideBarContainer from './SideBarContainer'

interface IProps {
  hitPoints: number
  position: Position
}

const SideBar = ({ hitPoints, position }: IProps) => {
  const [initialHitPoints] = useState(hitPoints)

  const notifications = useSelector(selectNotifications)
  const filteredNotifications =
    position === Position.Right
      ? notifications.filter(
          (notification) =>
            !notification.shown &&
            (notification.target === Target.Player ||
              notification.target === Target.Both)
        )
      : notifications.filter(
          (notification) =>
            !notification.shown &&
            (notification.target === Target.AI ||
              notification.target === Target.Both)
        )

  return (
    <SideBarContainer position={position}>
      {/* <PlayerAvatarContainer /> */}
      <DetailsWrapper>
        <Label text={position === Position.Right ? 'Player' : 'Oom Nickie'} />
        <HitPointsWrapper position={position}>
          <Counter start={initialHitPoints} end={hitPoints} />
          <Label text={'HP'} />
        </HitPointsWrapper>
      </DetailsWrapper>
      <NotificationsWrapper position={position}>
        <AnimatePresence>
          {filteredNotifications.map((notification) => (
            <motion.div layout key={notification.id}>
              <Toast
                key={notification.id}
                notification={notification}
                position={position}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </NotificationsWrapper>
    </SideBarContainer>
  )
}

// const PlayerAvatarContainer = styled.div`
//   height: 100px;
//   width: 100px;
//   border: 4px solid #383838;
//   background: #868686;
//   border-radius: 20px;
// `

export default SideBar
