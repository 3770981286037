import styled from '@emotion/styled'
import { Chip, Typography, useColorScheme } from '@mui/joy'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ActionButton from '../../__components/ActionButton'
import FooterBar from '../../__components/FooterBar'
import TextHighlight from '../../__components/TextHighlight'
import TwoSidedLayout from '../../__components/TwoSidedLayout'

interface IProps {
  isFocused: boolean
}

const SeasonsBlock01 = ({ isFocused }: IProps) => {
  const { mode: colourScheme } = useColorScheme()
  const [hasFocused, setHasFocused] = useState(false)

  return (
    <>
      <TwoSidedLayout
        fullHeight
        isFocused={isFocused}
        hasFocused={hasFocused}
        setHasFocused={setHasFocused}
        heroImage={require('../../../assets/images/seasons-1.jpg')}
        heroHeaderImage={require('../../../assets/images/seasons-1.jpg')}
      >
        <Typography color="primary" fontSize="lg" fontWeight="lg">
          New beginnings.
        </Typography>
        <Typography
          level="h1"
          fontWeight="xl"
          fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 5rem)"
        >
          Season 1{' '}
          <Chip
            color="primary"
            size="md"
            variant="outlined"
            sx={{
              userSelect: 'none',
              letterSpacing: 'normal',
              paddingBottom: '1px',
            }}
          >
            Live!
          </Chip>
        </Typography>
        <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
          The first season of <Typography fontWeight="lg">Scraiku</Typography>{' '}
          brings support for learning <TextHighlight>3 languages</TextHighlight>
          :
        </Typography>
        <Typography
          fontSize="lg"
          textColor="text.secondary"
          lineHeight="lg"
          textAlign="left"
        >
          <li>
            <FlagImg src="/images/fr.svg" /> French
          </li>
          <li>
            <FlagImg src="/images/de.svg" /> German
          </li>
          <li>
            <FlagImg src="/images/es.svg" /> Spanish
          </li>
        </Typography>
        <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
          Each language will be released with a total of{' '}
          <TextHighlight>500 cards</TextHighlight> to collect and learn.
        </Typography>
        <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
          The first <TextHighlight>4 boss fights</TextHighlight> are also
          available to be undertaken.
        </Typography>
        <RouterLink
          to={'/login'}
          state={{ openSignInByDefault: false }}
          style={{ marginTop: '20px' }}
        >
          <ActionButton text={'Join Now'} />
        </RouterLink>
      </TwoSidedLayout>
      <FooterBar colourScheme={colourScheme} />
    </>
  )
}

const FlagImg = styled.img`
  height: 12px;
  margin: 0 4px;
`

export default SeasonsBlock01
