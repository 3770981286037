import axios from 'axios'
import { isNotEmpty, pathOr } from 'ramda'

import { ICreateProfileResult, IGetLanguagesResult } from './types'

interface IGetLanguages {
  setGetLanguagesResult: (props: IGetLanguagesResult) => void
}

interface ICreateProfile {
  token: string
  username: string
  selectedAvatar: string
  selectedLanguage: string
  setCreateProfileResult: (props: ICreateProfileResult) => void
}

export const getLanguages = ({ setGetLanguagesResult }: IGetLanguages) => {
  axios
    .get('https://scrai-930ca0ac412a.herokuapp.com/api/get-languages')
    .then((res) => {
      const languages = pathOr(undefined, ['data'], res)

      if (languages) {
        setGetLanguagesResult({ error: '', languages })
      } else {
        setGetLanguagesResult({
          error: 'Failed to fetch languages',
          languages: [],
        })
      }
    })
    .catch((err) => {
      const error = pathOr(
        'Failed to fetch languages',
        ['response', 'data', 'error'],
        err,
      )
      setGetLanguagesResult({ error, languages: [] })
      console.error(error)
    })
}

export const createProfile = ({
  token,
  username,
  selectedAvatar,
  selectedLanguage,
  setCreateProfileResult,
}: ICreateProfile) => {
  axios
    .post(
      'https://scrai-930ca0ac412a.herokuapp.com/api/create-profile',
      {
        content: { username, selectedAvatar, selectedLanguage },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      const userProfile = pathOr({}, ['data'], res)

      if (isNotEmpty(userProfile)) {
        setCreateProfileResult({ error: '', userProfile })
      } else {
        setCreateProfileResult({
          error: 'Failed to create profile',
          userProfile: {},
        })
      }
    })
    .catch((err: any) => {
      const error = pathOr(
        'Failed to create profile',
        ['response', 'data', 'error'],
        err,
      )
      setCreateProfileResult({ error, userProfile: {} })
      console.error(error)
    })
}
