import styled from '@emotion/styled'
import React from 'react'

import { Position } from '../../enums'

interface IProps {
  show: boolean
  position: Position
  text: string
}

interface ICorrectionLabelSpanProps {
  show: boolean
  position: Position
}

const CorrectionLabel = ({ show, position, text }: IProps) => (
  <CorrectionLabelSpan show={show} position={position}>
    {text}
  </CorrectionLabelSpan>
)

const CorrectionLabelSpan = styled.span<ICorrectionLabelSpanProps>(
  ({ show, position }) => {
    const colour = position === Position.Top ? '253, 98, 98' : '11, 171, 100'

    return {
      fontSize: '26px',
      color: show ? `rgba(${colour}, 1)` : `rgba(${colour}, 0)`,
      transition: 'color 0.5s ease-in-out',
      width: '80%',
      userSelect: 'none',
      marginTop: position === Position.Bottom ? '15px' : '0px',
    }
  }
)

export default CorrectionLabel
