import styled from '@emotion/styled'
import { FestivalRounded } from '@mui/icons-material'
import {
  Box,
  CssBaseline,
  CssVarsProvider,
  Typography,
  useColorScheme,
} from '@mui/joy'
import Cookies from 'js-cookie'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { updateWasRedirected } from '../../state/userState'
import { selectWasRedirected } from '../../state/userStateSelectors'
import { IMenuItem } from '../../types'
import HeaderBar from '../__components/HeaderBar'
import ScrollArrow from '../__components/ScrollArrow'
import ScrollIndicator from '../__components/ScrollIndicator'
import SnapBoxWrapper from '../__components/SnapBoxWrapper'
import framesxTheme from '../theme'
import HeroBlock01 from './blocks/HeroBlock01'
import HeroBlock02 from './blocks/HeroBlock02'
import HeroBlock03 from './blocks/HeroBlock03'
import HeroBlock04 from './blocks/HeroBlock04'
import HeroBlock05 from './blocks/HeroBlock05'

interface IBackgroundGradientDivProps {
  colourScheme: string | undefined
  isMobile?: boolean
  isReversed?: boolean
}

const Hero = () => {
  const token = Cookies.get('token')

  const { mode: colourScheme } = useColorScheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const wasRedirected = useSelector(selectWasRedirected)

  const languageBoxRef = useRef<HTMLDivElement>(null)

  const [isLanguageBoxVisible, setIsLanguageBoxVisible] = useState(false)
  const [currentBoxIndex, setCurrentBoxIndex] = useState<number>(0)
  const [showHeaderBar, setShowHeaderBar] = useState<boolean>(false)
  const [showScrollArrow, setShowScrollArrow] = useState<boolean>(false)

  // Only redirect from Home page to Dashboard on first landing
  useEffect(() => {
    if (token && !wasRedirected) {
      dispatch(updateWasRedirected(true))
      navigate('/my-camp')
    }
  }, [dispatch, navigate, token, wasRedirected])

  useEffect(() => {
    const observerTarget = languageBoxRef.current

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsLanguageBoxVisible(true)
        }
      },
      { threshold: 0.75 },
    )

    if (observerTarget) {
      observer.observe(observerTarget)
    }

    return () => {
      if (observerTarget) {
        observer.unobserve(observerTarget)
      }
    }
  }, [])

  const isMobile = window.innerWidth < 834
  const menuItems: IMenuItem[] = [
    {
      label: 'My Camp',
      url: '/my-camp',
      icon: <FestivalRounded />,
      protectedRoute: true,
      showBottomDivider: true,
    },
    {
      label: 'Scraiku',
      url: '/',
    },
    {
      label: 'Learn to Play',
      url: '/learn-how',
    },
    {
      label: 'Explore Seasons',
      url: '/seasons',
    },
  ]
  const languages = ['French', 'German', 'Spanish']

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <ScrollArrow showScrollArrow={showScrollArrow} />
      <CssBaseline />
      {!isMobile && (
        <ScrollIndicator currentBoxIndex={currentBoxIndex} numberOfBoxes={3} />
      )}
      <HeaderBar
        colourScheme={colourScheme}
        currentPage={'/'}
        isMobile={isMobile}
        menuItems={menuItems}
        show={showHeaderBar}
      />
      <SnapBoxWrapper
        isMobile={isMobile}
        setCurrentBoxIndex={setCurrentBoxIndex}
        setShowHeaderBar={setShowHeaderBar}
        setShowScrollArrow={setShowScrollArrow}
      >
        <Box
          id={'topBox'}
          position="relative"
          bgcolor={colourScheme === 'light' ? '#fff' : '#202020'}
        >
          <BackgroundGradientDiv
            colourScheme={colourScheme}
            isMobile={isMobile}
          />
          <HeroBlock01 isFocused={currentBoxIndex === 0} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          height="200px"
          bgcolor={colourScheme === 'light' ? '#e8eaf3' : '#1c1c1c'}
        >
          <Typography
            level="h1"
            fontWeight="xl"
            fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 4rem)"
          >
            3 languages available now!
          </Typography>
        </Box>
        <Box
          position="relative"
          bgcolor={colourScheme === 'light' ? '#fff' : '#202020'}
        >
          <BackgroundGradientDiv
            colourScheme={colourScheme}
            isMobile={isMobile}
            isReversed
          />
          <HeroBlock02 isFocused={currentBoxIndex === 1} />
          <HeroBlock03 isFocused={currentBoxIndex === 1} />
        </Box>
        <Box
          ref={languageBoxRef}
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          height="200px"
          bgcolor={colourScheme === 'light' ? '#e8eaf3' : '#1c1c1c'}
        >
          {languages.map((language, index) => (
            <Typography
              key={index}
              level="h1"
              fontWeight="xl"
              fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 4rem)"
              sx={{
                opacity: isLanguageBoxVisible ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out',
                transitionDelay: `${index * 0.5}s`,
              }}
            >
              {language}
            </Typography>
          ))}
        </Box>
        <Box
          position="relative"
          bgcolor={colourScheme === 'light' ? '#fff' : '#202020'}
        >
          <BackgroundGradientDiv
            colourScheme={colourScheme}
            isMobile={isMobile}
          />
          <HeroBlock04 isFocused={currentBoxIndex === 2} />
          <HeroBlock05 isFocused={currentBoxIndex === 2} />
        </Box>
      </SnapBoxWrapper>
    </CssVarsProvider>
  )
}

const BackgroundGradientDiv = styled.div<IBackgroundGradientDivProps>(
  ({ colourScheme, isMobile, isReversed }) => ({
    position: 'absolute',
    width: isMobile ? '100%' : '50%',
    height: '100%',
    left: !isMobile && isReversed ? '50%' : '',
    backgroundColor: colourScheme === 'light' ? '#b8c6db' : '#33658A',
    backgroundImage:
      colourScheme === 'light'
        ? 'linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)'
        : 'linear-gradient(315deg, #33658A 0%, #170e13 74%)',
    zIndex: '0',
  }),
)

export default Hero
