import styled from '@emotion/styled'
import { Box } from '@mui/joy'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import React, { useEffect } from 'react'

interface IProps {
  isFocused: boolean
  hasFocused: boolean
  setHasFocused: (focused: boolean) => void
  setIsImageLoading: (loadiing: boolean) => void
  image?: string
  noImageBorder?: boolean
  isMobile?: boolean
  isReversed?: boolean
}

interface IBackgroundImgProps {
  isLeft?: boolean
}

interface IHeroImgProps {
  isMobile: boolean
  isReversed: boolean
  noImageBorder?: boolean
}

const HeroImageWrapper = ({
  image,
  noImageBorder,
  isFocused,
  hasFocused,
  setHasFocused,
  setIsImageLoading,
  isMobile,
  isReversed,
}: IProps) => {
  const leftImgControls = useAnimation()
  const rightImgControls = useAnimation()

  useEffect(() => {
    if (isFocused && !hasFocused) {
      leftImgControls.start({
        opacity: 1,
        x: 0,
        y: 300,
        transition: { duration: 0.5, delay: 0.15 },
      })

      rightImgControls.start({
        opacity: 1,
        x: 0,
        y: 0,
        transition: { duration: 0.5, delay: 0.25 },
      })

      setTimeout(() => setHasFocused(true), 1000)
    }
  }, [leftImgControls, rightImgControls, isFocused, hasFocused, setHasFocused])

  return (
    <Box minHeight={isMobile ? 160 : 0}>
      <AnimatePresence>
        {!isMobile && (
          <BackgroundImgMotion
            key="blueWrapper"
            initial={{ opacity: 0, x: -25, y: 320 }}
            animate={leftImgControls}
          >
            <BackgroundImg
              src={require('../../assets/images/hero-back-blue-opaque.png')}
              alt="hero-wrapper-blue"
              isLeft
            />
          </BackgroundImgMotion>
        )}
        <HeroImg
          src={image}
          alt="hero-image"
          noImageBorder={noImageBorder}
          onLoad={() => setIsImageLoading(false)}
          isMobile={!!isMobile}
          isReversed={!!isReversed}
        />
        {!isMobile && (
          <BackgroundImgMotion
            key="redWrapper"
            initial={{ opacity: 0, x: 25, y: -20 }}
            animate={rightImgControls}
          >
            <BackgroundImg
              src={require('../../assets/images/hero-back-red-opaque.png')}
              alt="hero-wrapper-red"
            />
          </BackgroundImgMotion>
        )}
      </AnimatePresence>
    </Box>
  )
}

const BackgroundImgMotion = styled(motion.div)`
  width: 100%;
`

const BackgroundImg = styled.img<IBackgroundImgProps>(({ isLeft }) => ({
  maxHeight: '200px',
  width: 'auto',
  position: 'absolute',
  bottom: isLeft ? '-35px' : '',
  left: isLeft ? '55px' : '',
  top: isLeft ? '' : '-35px',
  right: isLeft ? '' : '55px',
  objectFit: 'contain',
}))

const HeroImg = styled.img<IHeroImgProps>(
  ({ isMobile, isReversed, noImageBorder }) => ({
    position: 'absolute',
    zIndex: '1',
    objectFit: 'contain',
    width: 'auto',
    borderRadius: noImageBorder
      ? ''
      : isMobile
        ? isReversed
          ? '0 180px 0 180px'
          : '180px 0 180px 0'
        : '45px 0 45px 0',
    height: isMobile ? '100vw' : '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    filter: isMobile ? '' : 'drop-shadow(#000 2px 4px 10px)',
  }),
)

export default HeroImageWrapper
