import { Typography } from '@mui/joy'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ActionButton from '../../__components/ActionButton'
import TextHighlight from '../../__components/TextHighlight'
import TwoSidedLayout from '../../__components/TwoSidedLayout'

interface IProps {
  isFocused: boolean
}

const HeroBlock04 = ({ isFocused }: IProps) => {
  const [hasFocused, setHasFocused] = useState(false)

  return (
    <TwoSidedLayout
      isReversed
      isFocused={isFocused}
      hasFocused={hasFocused}
      setHasFocused={setHasFocused}
      heroImage={require('../../../assets/images/hero-4.jpg')}
    >
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        Unearth new content and conquests.
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Seasonal Adventures Beckon!
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        An <TextHighlight>ever-expanding</TextHighlight> adventure awaits you in
        the world of <Typography fontWeight="lg">Scraiku</Typography>.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        With each new season comes a fresh wave of content, introducing new
        cards, formidable bosses, and{' '}
        <TextHighlight>challenges to overcome</TextHighlight>.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        As seasons change, so does your language journey – immerse yourself in
        seasonal adventures that propel you{' '}
        <TextHighlight>closer to fluency</TextHighlight> with every encounter.
      </Typography>
      <RouterLink
        to={'/seasons'}
        state={{ openSignInByDefault: false }}
        style={{ marginTop: '20px' }}
      >
        <ActionButton text={'Explore Seasons'} />
      </RouterLink>
    </TwoSidedLayout>
  )
}

export default HeroBlock04
