import styled from '@emotion/styled'
import { Box } from '@mui/joy'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import React, { useEffect } from 'react'
import Image from 'react-image-webp'

interface IProps {
  video: string
  isFocused: boolean
  hasFocused: boolean
  setHasFocused: (focused: boolean) => void
  setIsImageLoading: (loading: boolean) => void
  isMobile?: boolean
}

interface IBackgroundImgProps {
  isLeft?: boolean
}

const HeroVideoWrapper = ({
  video,
  isFocused,
  hasFocused,
  setHasFocused,
  setIsImageLoading,
  isMobile,
}: IProps) => {
  const leftImgControls = useAnimation()
  const rightImgControls = useAnimation()

  useEffect(() => {
    if (isFocused && !hasFocused) {
      leftImgControls.start({
        opacity: 1,
        x: 0,
        y: 300,
        transition: { duration: 0.5, delay: 0.15 },
      })

      rightImgControls.start({
        opacity: 1,
        x: 0,
        y: 0,
        transition: { duration: 0.5, delay: 0.25 },
      })

      setTimeout(() => setHasFocused(true), 1000)
    }
  }, [leftImgControls, rightImgControls, isFocused, hasFocused, setHasFocused])

  const HeroImage = styled(Image)`
    border-radius: ${isMobile ? '' : '45px 0 45px 0'};
    height: auto;
    width: ${isMobile ? '100vw' : '100%'};
    object-fit: contain;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: ${isMobile ? '' : 'drop-shadow(#000 2px 4px 10px)'};
  `

  return (
    <Box>
      <AnimatePresence>
        {!isMobile && (
          <BackgroundImgMotion
            key="blueWrapper"
            initial={{ opacity: 0, x: -25, y: 320 }}
            animate={leftImgControls}
          >
            <BackgroundImg
              src={require('../../assets/images/hero-back-blue-opaque.png')}
              alt="hero-wrapper-blue"
              isLeft
            />
          </BackgroundImgMotion>
        )}
        <HeroImage
          src={video}
          webp={video}
          onLoad={() => setIsImageLoading(false)}
        />
        {!isMobile && (
          <BackgroundImgMotion
            key="redWrapper"
            initial={{ opacity: 0, x: 25, y: -20 }}
            animate={rightImgControls}
          >
            <BackgroundImg
              src={require('../../assets/images/hero-back-red-opaque.png')}
              alt="hero-wrapper-red"
            />
          </BackgroundImgMotion>
        )}
      </AnimatePresence>
    </Box>
  )
}

const BackgroundImgMotion = styled(motion.div)`
  width: 100%;
`

const BackgroundImg = styled.img<IBackgroundImgProps>(({ isLeft }) => ({
  maxHeight: '200px',
  width: 'auto',
  position: 'absolute',
  bottom: isLeft ? '-5px' : '',
  left: isLeft ? '-30px' : '',
  top: isLeft ? '' : '-35px',
  right: isLeft ? '' : '-30px',
  objectFit: 'contain',
}))

export default HeroVideoWrapper
