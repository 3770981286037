import { ICard } from './types'

export const getActiveCards = (input: string, cards: ICard[]) => {
  const activeCards: ICard[] = []
  const inputWords = getCleanString(input).split(' ')

  // Handle words containing apostrophes (there's, we're, etc)
  inputWords.forEach((word) => {
    const foundWord = cards.find((card) =>
      word.includes("'") || word.includes('’')
        ? word.includes(card.title.toLowerCase())
        : word === card.title.toLowerCase(),
    )

    foundWord &&
      !activeCards.find((card) => card.title === foundWord.title) &&
      activeCards.push(foundWord)
  })

  return activeCards
}

export const getCleanString = (value: string) =>
  value
    .replaceAll(/[.,?!]/g, '')
    .toLowerCase()
    .trim()

export const getLanguageByCode = (code: string) => {
  switch (code) {
    case 'af':
      return 'Afrikaans'
    case 'fr':
      return 'French'
    case 'de':
      return 'German'
    case 'es':
      return 'Spanish'
    default:
      return ''
  }
}
