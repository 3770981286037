import { createSelector } from 'reselect'

import { IState } from './types'

const state = (state: IState) => state

export const selectDeck = createSelector(
  state,
  ({ gameState }) => gameState.deck
)

export const selectPlayerHand = createSelector(
  state,
  ({ gameState }) => gameState.playerHand
)

export const selectAIHand = createSelector(
  state,
  ({ gameState }) => gameState.aiHand
)

export const selectCurrentTurn = createSelector(
  state,
  ({ gameState }) => gameState.currentTurn
)

export const selectNotifications = createSelector(
  state,
  ({ gameState }) => gameState.notifications
)
