import styled from '@emotion/styled'
import { Chip, Link, Typography } from '@mui/joy'
import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ActionButton from '../../__components/ActionButton'
import TextHighlight from '../../__components/TextHighlight'
import TwoSidedLayout from '../../__components/TwoSidedLayout'

interface IProps {
  isFocused: boolean
}

const HeroBlock01 = ({ isFocused }: IProps) => {
  const token = Cookies.get('token')

  const [hasFocused, setHasFocused] = useState(false)

  return (
    <TwoSidedLayout
      fullHeight
      isFocused={isFocused}
      hasFocused={hasFocused}
      setHasFocused={setHasFocused}
      heroVideo={require('../../../assets/images/hero-2.webp')}
      heroWebm={require('../../../assets/images/hero-1.webm')}
    >
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        It all starts here...
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 5rem)"
      >
        <LogoImg src={require('../../../assets/images/logo.png')} />
        Scraiku{' '}
        <Chip
          color="primary"
          size="md"
          variant="outlined"
          sx={{
            userSelect: 'none',
            letterSpacing: 'normal',
            paddingBottom: '1px',
          }}
        >
          Beta
        </Chip>
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Are you ready to <TextHighlight>become the hero</TextHighlight> of your
        language learning adventure?
      </Typography>
      <RouterLink
        to={'/login'}
        state={{ openSignInByDefault: false }}
        style={{ marginTop: '20px' }}
      >
        <ActionButton text={'Join Now'} />
      </RouterLink>
      <Typography>
        Already a member?{' '}
        <RouterLink
          to={token ? '/my-camp' : '/login'}
          state={{ openSignInByDefault: true }}
        >
          <Link fontWeight="lg">Sign in</Link>
        </RouterLink>
      </Typography>
    </TwoSidedLayout>
  )
}

const LogoImg = styled.img`
  height: clamp(1.875rem, 1.3636rem + 2.1818vw, 5rem);
  margin-right: 10px;
`

export default HeroBlock01
