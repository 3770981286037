import React from 'react'
import { useSelector } from 'react-redux'

import { CardRarity, Position } from '../../enums'
import { selectCurrentTurn } from '../../state/gameStateSelectors'
import { IActionStatus } from '../../types'
import CardBack from './CardBack'
import CardBackImage from './CardBackImage'
import CardContainer from './CardContainer'
import CardFace from './CardFace'
import CardFaceImage from './CardFaceImage'
import DamageContainer from './DamageContainer'
import DamageValue from './DamageValue'
import ShakeWrapper from './ShakeWrapper'
import ShineOverlay from './ShineOverlay'
import CardTitle from './Title'

interface IProps {
  actionStatus: IActionStatus
  active: boolean
  dealt: boolean
  difficulty: number
  index: number
  onCardClick: (active: boolean, title: string) => void
  playAreaLocation: Position
  show: boolean
  title: string
}

const Card = ({
  actionStatus,
  active,
  dealt,
  difficulty,
  index,
  onCardClick,
  playAreaLocation,
  show,
  title,
}: IProps) => {
  const currentTurn = useSelector(selectCurrentTurn)

  const getRarityByDifficulty = (difficulty: number) => {
    switch (true) {
      case difficulty <= 4:
        return CardRarity.Common
      case difficulty <= 8:
        return CardRarity.Uncommon
      case difficulty > 8:
        return CardRarity.Rare
      default:
        return CardRarity.Common
    }
  }

  const rarity = getRarityByDifficulty(difficulty)

  return (
    <ShakeWrapper active={active} show={show}>
      <CardContainer
        active={active}
        dealt={dealt}
        index={index}
        onCardClick={onCardClick}
        show={show}
        title={title}
      >
        <CardFace
          actionStatus={actionStatus}
          active={active}
          currentTurn={currentTurn}
          playAreaLocation={playAreaLocation}
          rarity={rarity}
        >
          <CardFaceImage rarity={rarity} />
          <ShineOverlay show={rarity === CardRarity.Rare} />
          <CardTitle rarity={rarity} title={title} />
          <DamageContainer>
            <DamageValue difficulty={difficulty} />
          </DamageContainer>
        </CardFace>
        <CardBack>
          <CardBackImage />
        </CardBack>
      </CardContainer>
    </ShakeWrapper>
  )
}

export default Card
