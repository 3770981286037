import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  active: boolean
  children: ReactNode
  show: boolean
}

interface IShakeWrapperDivProps {
  active: boolean
  show: boolean
}

const ShakeWrapper = ({ active, children, show }: IProps) => (
  <ShakeWrapperDiv active={active} show={show}>
    {children}
  </ShakeWrapperDiv>
)

const transform = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(1px, 1px) rotate(1deg); }
  50% { transform: translate(0, 0) rotate(0eg); }
  75% { transform: translate(-1px, 1px) rotate(-1deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
`

const ShakeWrapperDiv = styled.div<IShakeWrapperDivProps>(
  ({ active, show }) => ({
    animation: active && show ? `${transform} 0.5s ease-in-out infinite` : '',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }),
)

export default ShakeWrapper
