import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CurrentTurn } from '../enums'
import { ICard } from '../types'
import { IGameState, INotification } from './types'

const initialState: IGameState = {
  deck: [],
  playerHand: [],
  aiHand: [],
  currentTurn: CurrentTurn.None,
  notifications: [],
}

export const serverSlice = createSlice({
  name: 'gameState',
  initialState,
  reducers: {
    updateDeck: (state, action: PayloadAction<ICard[]>) => {
      state.deck = action.payload
    },
    updatePlayerHand: (state, action: PayloadAction<ICard[]>) => {
      state.playerHand = action.payload
    },
    updateAIHand: (state, action: PayloadAction<ICard[]>) => {
      state.aiHand = action.payload
    },
    updateCurrentTurn: (state, action: PayloadAction<CurrentTurn>) => {
      state.currentTurn = action.payload
    },
    updateNotifications: (state, action: PayloadAction<INotification[]>) => {
      state.notifications = action.payload
    },
    updateNotification: (state, action: PayloadAction<INotification>) => {
      const index = state.notifications.findIndex(
        (item) => item.id === action.payload.id
      )
      if (index !== -1) {
        const newNotifications = [
          ...state.notifications.slice(0, index),
          { ...state.notifications[index], shown: true },
          ...state.notifications.slice(index + 1),
        ]

        state.notifications = newNotifications
      }
    },
  },
})

export const {
  updateDeck,
  updatePlayerHand,
  updateAIHand,
  updateCurrentTurn,
  updateNotifications,
  updateNotification,
} = serverSlice.actions

export default serverSlice.reducer
