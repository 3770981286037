import styled from '@emotion/styled'
import {
  DarkModeRounded,
  LightModeRounded,
  LoginRounded,
  LogoutRounded,
  Menu as MenuIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemDecorator,
  ModalClose,
  Typography,
  useColorScheme,
} from '@mui/joy'
import Cookies from 'js-cookie'
import React, { Fragment, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { IMenuItem } from '../../types'

interface IProps {
  currentPage: string
  items: IMenuItem[]
}

const Menu = ({ currentPage, items }: IProps) => {
  const token = Cookies.get('token')

  const { mode, setMode } = useColorScheme()
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <IconButton
        variant="outlined"
        color="neutral"
        onClick={() => setOpen(true)}
        sx={{
          backgroundColor: mode === 'light' ? '#fff' : '#202020',
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor="right"
        sx={(theme) => ({
          '& .MuiDrawer-content': {
            backgroundColor: mode === 'light' ? '#f5f6fa' : '#202020',
            width: 'min(100vw, 80%)',
            [theme.breakpoints.up(834)]: {
              width: 'min(100vw, 25%)',
            },
            [theme.breakpoints.up(1199)]: {
              width: 'min(100vw, 20%)',
            },
          },
        })}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            gap={0.5}
            ml={3}
            mt={2}
            mr={3}
          >
            <Button
              color="neutral"
              onClick={() => setMode(mode === 'light' ? 'dark' : 'light')}
              sx={{ cursor: 'pointer' }}
              variant="plain"
            >
              {mode === 'light' ? <DarkModeRounded /> : <LightModeRounded />}
            </Button>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap={0.5}
            ml="auto"
            mt={2}
            mr={3}
          >
            <Typography
              component="label"
              htmlFor="close-icon"
              fontSize="sm"
              fontWeight="lg"
              sx={{ cursor: 'pointer' }}
            >
              Close
            </Typography>
            <ModalClose id="close-icon" sx={{ position: 'initial' }} />
          </Box>
        </Box>
        <List
          size="lg"
          component="nav"
          sx={{
            flex: 'none',
            fontSize: 'xl',
            '& > div': { justifyContent: 'center' },
          }}
        >
          <Divider sx={{ mb: 2 }} />
          {items.map(
            (
              {
                label,
                url,
                icon,
                protectedRoute,
                showTopDivider,
                showBottomDivider,
              },
              index,
            ) =>
              (!protectedRoute || (protectedRoute && token)) && (
                <>
                  {showTopDivider && <Divider sx={{ my: 2, mx: 8 }} />}
                  <RouterLinkStyled to={url}>
                    <ListItemButton
                      key={index}
                      sx={{
                        backgroundColor:
                          currentPage === url
                            ? mode === 'light'
                              ? '#f1f3f9'
                              : '#181a1c'
                            : '',
                        width: '100%',
                        justifyContent: 'center',
                      }}
                    >
                      {icon && (
                        <ListItemDecorator sx={{ color: 'inherit' }}>
                          {icon}
                        </ListItemDecorator>
                      )}
                      <Typography>{label}</Typography>
                    </ListItemButton>
                  </RouterLinkStyled>
                  {showBottomDivider && <Divider sx={{ my: 2, mx: 8 }} />}
                </>
              ),
          )}
          <Divider sx={{ my: 2, mx: 8 }} />
          <RouterLinkStyled to={'/login'} state={{ openSignInByDefault: true }}>
            <ListItemButton
              onClick={() => (token ? Cookies.remove('token') : {})}
              sx={{
                backgroundColor:
                  currentPage === '/login'
                    ? mode === 'light'
                      ? '#f1f3f9'
                      : '#181a1c'
                    : '',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <ListItemDecorator sx={{ color: 'inherit' }}>
                {token ? <LogoutRounded /> : <LoginRounded />}
              </ListItemDecorator>{' '}
              <Typography>{token ? 'Sign out' : 'Sign in'}</Typography>
            </ListItemButton>
          </RouterLinkStyled>
        </List>
      </Drawer>
    </Fragment>
  )
}

const RouterLinkStyled = styled(RouterLink)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 100%;
`

export default Menu
