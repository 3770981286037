import { motion } from 'framer-motion'
import React from 'react'
import { useDispatch } from 'react-redux'

import { palette } from '../../contants'
import { NotificationType, Position } from '../../enums'
import { updateNotification } from '../../state/gameState'
import { INotification } from '../../state/types'
import Icon from './Icon'
import ToastContainer from './ToastContainer'

interface IProps {
  notification: INotification
  position: Position
}

const Toast = ({ notification, position }: IProps) => {
  const dispatch = useDispatch()

  const { type, content } = notification

  const getNotificationColour = () => {
    if (type === NotificationType.Attack) {
      return palette.red
    }

    if (type === NotificationType.Defence) {
      return palette.green
    }

    return palette.blue
  }

  const getNotificationIcon = () => {
    if (type === NotificationType.Attack) {
      return require('../../assets/images/attack.png')
    }

    if (type === NotificationType.Defence) {
      return require('../../assets/images/shield.png')
    }

    return require('../../assets/images/cards.png')
  }

  const getNotificationText = () => {
    if (type === NotificationType.Attack) {
      return `- ${content}`
    }

    return content
  }

  const onAnimationComplete = () => {
    dispatch(
      updateNotification({
        ...notification,
        shown: true,
      })
    )
  }

  const colour = getNotificationColour()
  const icon = getNotificationIcon()
  const text = getNotificationText()

  return (
    <motion.div
      initial={{ x: position === Position.Left ? '-150px' : '250px' }}
      animate={{ x: '0px', transitionEnd: { display: 'none' } }}
      transition={{
        duration: 2,
        ease: 'backInOut',
        repeat: 1,
        repeatDelay: 10,
        repeatType: 'reverse',
      }}
      onAnimationComplete={onAnimationComplete}
    >
      <ToastContainer colour={colour} position={position} type={type}>
        {text}
        <Icon show={!!icon} icon={icon} />
      </ToastContainer>
    </motion.div>
  )
}

export default Toast
