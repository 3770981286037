import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

interface IProps {
  show: boolean
}

const ShineOverlay = ({ show }: IProps) => (show ? <ShineOverlayDiv /> : null)

const opacity = keyframes`
    0% {left: 0; opacity: 0;}
    5% {opacity: 0.0}
    48% {opacity: 0.2}
    80% {opacity: 0.0}
    100% {left: 82%}
`

const ShineOverlayDiv = styled.div`
  position: relative;
  opacity: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 70px;
  transform: skew(-37deg, 0deg);
  animation: ${opacity} 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
`

export default ShineOverlay
