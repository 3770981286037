import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import {
  CardRarity,
  CurrentTurn,
  Position,
  RequestStatus,
  TurnStatus,
} from '../../enums'
import { IActionStatus } from '../../types'

interface IProps {
  actionStatus: IActionStatus
  active: boolean
  children: ReactNode
  currentTurn: CurrentTurn
  playAreaLocation: Position
  rarity: CardRarity
}

interface ICardFaceDivProps {
  actionStatus: IActionStatus
  active: boolean
  currentTurn: CurrentTurn
  playAreaLocation: Position
  rarity: CardRarity
}

const getBorderColorByRarity = (rarity: CardRarity) =>
  rarity === CardRarity.Rare ? '#ffc988' : '#383838'

const getBackgroundImageByRarity = (rarity: CardRarity) => {
  switch (rarity) {
    case CardRarity.Common:
      return 'linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%)'
    case CardRarity.Uncommon:
      return 'linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%)'
    case CardRarity.Rare:
      return 'linear-gradient(315deg, #f7b42c 0%, #fc575e 74%)'
    default:
      return 'linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%)'
  }
}

const getBoxShadow = (active: boolean, rarity: CardRarity) =>
  active
    ? `0 0 10px 0 ${getBorderColorByRarity(rarity)}`
    : `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
0 6.7px 5.3px rgba(0, 0, 0, 0.048),
0 12.5px 10px rgba(0, 0, 0, 0.06),
0 22.3px 17.9px rgba(0, 0, 0, 0.072),
0 41.8px 33.4px rgba(0, 0, 0, 0.086),
0 100px 80px rgba(0, 0, 0, 0.12)`

const CardFace = ({
  actionStatus,
  active,
  children,
  currentTurn,
  playAreaLocation,
  rarity,
}: IProps) => (
  <CardFaceDiv
    actionStatus={actionStatus}
    active={active}
    currentTurn={currentTurn}
    playAreaLocation={playAreaLocation}
    rarity={rarity}
  >
    {children}
  </CardFaceDiv>
)

const CardFaceDiv = styled.div<ICardFaceDivProps>(
  ({ actionStatus, active, currentTurn, playAreaLocation, rarity }) => ({
    backgroundImage: getBackgroundImageByRarity(rarity),
    border: `6px solid ${getBorderColorByRarity(rarity)}`,
    borderRadius: '0 20px 20px',
    boxShadow: getBoxShadow(active, rarity),
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    overflow: 'hidden',
    position: 'absolute',
    transform: `rotateY(180deg) ${
      active ? 'scale(1.05)' : 'scale(1)'
    } rotateX(0deg)`,
    transition: 'transform 0.25s ease-in-out',
    height: '100%',
    width: '100%',
    backfaceVisibility: 'hidden',
    cursor:
      currentTurn === CurrentTurn.Player &&
      playAreaLocation === Position.Bottom &&
      actionStatus.requestStatus === RequestStatus.Idle &&
      actionStatus.turnStatus === TurnStatus.Started
        ? 'pointer'
        : '',
    ':hover': {
      transform:
        !active &&
        currentTurn === CurrentTurn.Player &&
        playAreaLocation === Position.Bottom &&
        actionStatus.requestStatus === RequestStatus.Idle &&
        actionStatus.turnStatus === TurnStatus.Started
          ? 'scale(1.05) rotateY(180deg)'
          : '',
    },
  })
)

export default CardFace
