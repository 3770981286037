import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

const Spinner = () => <SpinnerSpan />

const shadow = keyframes`
  0% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, 
    -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, 
    -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
    -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, 
    -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
    -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, 
    -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 
    0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
`

const rotate = keyframes`
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
`

const SpinnerSpan = styled.span`
  color: #ffffff;
  opacity: 0.33;
  font-size: 20px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1.1em;
  height: 1.1em;
  border-radius: 50%;
  position: relative;
  margin-top: 1px;
  transform: translateZ(0);
  animation: ${shadow} 1.7s infinite ease, ${rotate} 1.7s infinite ease;
`

export default Spinner
