import { Box, Typography } from '@mui/joy'
import React, { useState } from 'react'

import Card from '../../../components/card'
import { Position, RequestStatus, TurnStatus } from '../../../enums'
import TextHighlight from '../../__components/TextHighlight'
import TwoSidedLayout from '../../__components/TwoSidedLayout'

interface IProps {
  isFocused: boolean
  isMobile: boolean
}

const LearnHowBlock02 = ({ isFocused, isMobile }: IProps) => {
  const [hasFocused, setHasFocused] = useState(false)

  const card1 = {
    actionStatus: {
      requestStatus: RequestStatus.Idle,
      turnStatus: TurnStatus.Idle,
    },
    active: false,
    dealt: true,
    difficulty: Math.floor(Math.random() * 10) + 1,
    index: 0,
    onCardClick: () => {},
    playAreaLocation: Position.Top,
    show: true,
    title: 'Welcome',
  }

  const card2 = {
    ...card1,
    difficulty: Math.floor(Math.random() * 10) + 1,
    title: 'Hero!',
  }

  const HeroCards = (
    <Box width="100vw" height="100%" display="flex">
      <Box
        sx={{
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
          width: '100%',
        }}
      >
        <Card
          actionStatus={card1.actionStatus}
          active={card1.active}
          dealt={card1.dealt}
          difficulty={card1.difficulty}
          index={card1.index}
          onCardClick={card1.onCardClick}
          playAreaLocation={card1.playAreaLocation}
          show={card1.show}
          title={card1.title}
        />
      </Box>
      {!isMobile && (
        <>
          <div style={{ width: '8px' }} />
          <Box
            width="100%"
            sx={{
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          >
            <Card
              actionStatus={card2.actionStatus}
              active={card2.active}
              dealt={card2.dealt}
              difficulty={card2.difficulty}
              index={card2.index}
              onCardClick={card2.onCardClick}
              playAreaLocation={card2.playAreaLocation}
              show={card2.show}
              title={card2.title}
            />
          </Box>
        </>
      )}
    </Box>
  )

  return (
    <TwoSidedLayout
      isReversed
      isFocused={isFocused}
      hasFocused={hasFocused}
      setHasFocused={setHasFocused}
      heroElement={HeroCards}
      noImageBorder
    >
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        A clash of cards.
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        The Cards
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        At the heart of <Typography fontWeight="lg">Scraiku</Typography> are the
        cards, each bearing <TextHighlight>a word</TextHighlight> and{' '}
        <TextHighlight>a number</TextHighlight> indicating its difficulty level.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        These cards encompass a wide range of vocabulary, from basic nouns and
        verbs to advanced terms, with higher difficulty words{' '}
        <TextHighlight>yielding more power</TextHighlight>.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Assemble your deck strategically, selecting words that align with your{' '}
        <TextHighlight>learning goals</TextHighlight> and play style.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Will you focus on mastering everyday phrases or{' '}
        <TextHighlight>challenge yourself</TextHighlight> with complex
        terminology?
      </Typography>
    </TwoSidedLayout>
  )
}

export default LearnHowBlock02
