import { FestivalRounded } from '@mui/icons-material'
import { Box, CssBaseline, GlobalStyles, Grid, useColorScheme } from '@mui/joy'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Card from '../../components/card'
import { Position, RequestStatus, TurnStatus } from '../../enums'
import { IMenuItem } from '../../types'
import HeaderBar from '../__components/HeaderBar'
import SignIn from './blocks/SignIn'
import SignUp from './blocks/SignUp'

const Login = () => {
  const { mode: colourScheme } = useColorScheme()
  const location = useLocation()
  const openSignInByDefault = location.state?.openSignInByDefault

  const companyName = 'Scraiku'
  const minPasswordLength = 8
  const isMobile = window.innerWidth <= 834
  const menuItems: IMenuItem[] = [
    {
      label: 'My Camp',
      url: '/my-camp',
      icon: <FestivalRounded />,
      protectedRoute: true,
      showBottomDivider: true,
    },
    {
      label: 'Scraiku',
      url: '/',
    },
    {
      label: 'Learn to Play',
      url: '/learn-how',
    },
    {
      label: 'Explore Seasons',
      url: '/seasons',
    },
  ]

  const cardsArrayLength = 12
  const cardsArray = Array.from(
    { length: cardsArrayLength },
    (_, index) => index,
  )
  const welcomesTitles = [
    'Welcome',
    'Bienvenue',
    'Willkommen',
    'Bienvenida',
    'Bienvenido',
  ]
  const randomCards = cardsArray.map((_, index) => ({
    actionStatus: {
      requestStatus: RequestStatus.Idle,
      turnStatus: TurnStatus.Idle,
    },
    active: false,
    dealt: true,
    difficulty: Math.floor(Math.random() * 10) + 1,
    index,
    onCardClick: () => {},
    playAreaLocation: Position.Top,
    show: true,
    title: welcomesTitles[Math.floor(Math.random() * 5)],
  }))

  const [cards] = useState(randomCards)
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [isSignInForm, setIsSignInForm] = useState(openSignInByDefault)
  const [showCards, setShowCards] = useState(
    cardsArray.map(() => Math.random() > 0.25),
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      toggleCardVisibility()
    }, 15 * 1000)

    return () => clearInterval(intervalId)
  })

  const toggleCardVisibility = () => {
    const newShowCards = showCards.map(() => Math.random() > 0.5)
    setShowCards(newShowCards)
  }

  const toggleSignInForm = () => {
    setIsSignInForm(!isSignInForm)
  }

  return (
    <>
      <CssBaseline />
      <HeaderBar
        colourScheme={colourScheme}
        currentPage={'/login'}
        isMobile={isMobile}
        menuItems={menuItems}
        show={false}
      />
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        width={{ xs: '100%', md: '50vw' }}
        position="relative"
        zIndex={1}
        display="flex"
        justifyContent="flex-end"
        bgcolor="rgba(255 255 255 / 0.2)"
        sx={(theme) => ({
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backdropFilter: 'blur(12px)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(90 90 90 / 0.4)',
          },
        })}
      >
        <Box
          display="flex"
          flexDirection="column"
          minHeight="100dvh"
          width="100%"
          px={3}
          pt={4}
        >
          <Box
            component="main"
            my="auto"
            py={2}
            pb={5}
            display="flex"
            flexDirection="column"
            gap={2}
            width={400}
            maxWidth="100%"
            mx="auto"
            borderRadius="sm"
            sx={{
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: 'hidden',
              },
            }}
          >
            {isSignInForm ? (
              <SignIn
                companyName={companyName}
                toggleSignInForm={toggleSignInForm}
              />
            ) : (
              <SignUp
                password={password}
                passwordConfirm={passwordConfirm}
                minPasswordLength={minPasswordLength}
                toggleSignInForm={toggleSignInForm}
                setPassword={setPassword}
                setPasswordConfirm={setPasswordConfirm}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        height="100%"
        position="fixed"
        right={0}
        top={0}
        bottom={0}
        left={{ xs: 0, md: '50vw' }}
        padding="0 18px"
        bgcolor="background.level1"
        sx={(theme) => ({
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)',
          // 'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage: 'linear-gradient(315deg, #33658A 0%, #170e13 74%)',
            // 'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
          },
        })}
      >
        <Grid container flexGrow={1} spacing={2}>
          <Grid xs={12} container justifyContent="center" spacing={2}>
            {cards.map((card, index) => (
              <Grid
                key={index}
                xs={3}
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
                style={{
                  opacity: showCards[index] ? 1 : 0,
                  transition: 'opacity 2s ease-in-out',
                }}
              >
                <motion.div
                  key={'0'}
                  initial={{
                    opacity: 0,
                    y: '110vh',
                  }}
                  animate={{
                    opacity: 1,
                    y: '-1500px',
                  }}
                  transition={{
                    duration: 15,
                    repeat: Infinity,
                    delay: index * (15 / cards.length),
                    repeatType: 'loop',
                  }}
                  style={{
                    width: '100%',
                  }}
                >
                  <Card
                    actionStatus={card.actionStatus}
                    active={card.active}
                    dealt={card.dealt}
                    difficulty={card.difficulty}
                    index={card.index}
                    onCardClick={card.onCardClick}
                    playAreaLocation={card.playAreaLocation}
                    show={card.show}
                    title={card.title}
                  />
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Login
