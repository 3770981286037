import axios from 'axios'
import Cookies from 'js-cookie'
import { pathOr } from 'ramda'

import { IUserLoginResult } from './types'

interface ILoginData {
  email: string
  password?: string
  persistent?: boolean
  isGoogle?: boolean
  setUserLoginResult: (props: IUserLoginResult) => void
}

interface ISignUpData {
  email: string
  password?: string
  isGoogle?: boolean
  setShowCookiesDialog: (show: boolean) => void
  setUserCreationResult: (props: IUserLoginResult) => void
}

export const login = ({
  email,
  password,
  persistent,
  isGoogle,
  setUserLoginResult,
}: ILoginData) => {
  axios
    .post('https://scrai-930ca0ac412a.herokuapp.com/api/login', {
      content: { email, password, persistent, isGoogle },
    })
    .then((res) => {
      const jwtToken = pathOr(undefined, ['data', 'token'], res)
      const userProfile = pathOr({}, ['data', 'userProfile'], res)

      if (jwtToken) {
        Cookies.set('token', jwtToken, { secure: true, sameSite: 'strict' })
        setUserLoginResult({
          error: '',
          tokenReceived: true,
          userProfile,
        })
      } else {
        setUserLoginResult({
          error: 'Failed to login',
          tokenReceived: false,
          userProfile,
        })
      }
    })
    .catch((err: any) => {
      const error = pathOr(
        'Failed to login',
        ['response', 'data', 'error'],
        err,
      )
      setUserLoginResult({
        error,
        tokenReceived: false,
        userProfile: {},
      })
      console.error(error)
    })
}

export const signUp = ({
  email,
  password,
  isGoogle,
  setShowCookiesDialog,
  setUserCreationResult,
}: ISignUpData) => {
  axios
    .post('https://scrai-930ca0ac412a.herokuapp.com/api/sign-up', {
      content: { email, password, isGoogle },
    })
    .then((res) => {
      const jwtToken = pathOr(undefined, ['data', 'token'], res)

      if (jwtToken) {
        Cookies.set('token', jwtToken, { secure: true, sameSite: 'strict' })
        setUserCreationResult({
          error: '',
          tokenReceived: true,
        })
      } else {
        setUserCreationResult({
          error: 'Failed to sign up',
          tokenReceived: false,
        })
      }
    })
    .catch((err: any) => {
      const error = pathOr(
        'Failed to sign up',
        ['response', 'data', 'error'],
        err,
      )
      setUserCreationResult({
        error,
        tokenReceived: false,
      })
      console.error(error)
    })

  setShowCookiesDialog(false)
}
