import styled from '@emotion/styled'
import React from 'react'

interface IProps {
  text: string
}

const EndGameText = ({ text }: IProps) => (
  <EndGameTextSpan>{text}</EndGameTextSpan>
)

const EndGameTextSpan = styled.span`
  display: inline-block;
  white-space: nowrap;
  color: #fff;
  font-size: 72px;
  font-style: italic;
  margin-bottom: 20px;
`

export default EndGameText
