import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  CurrentTurn,
  NotificationType,
  Position,
  RequestStatus,
  Target,
  TurnStatus,
  Victor,
} from '../../enums'
import {
  updateAIHand,
  updateCurrentTurn,
  updateDeck,
  updateNotifications,
  updatePlayerHand,
} from '../../state/gameState'
import {
  selectCurrentTurn,
  selectNotifications,
} from '../../state/gameStateSelectors'
import { INotification } from '../../state/types'
import { ICard, ILanguage, IPlayerActionResponse } from '../../types'
import { getCleanString } from '../../utils'
import Button from '../button'
import Hand from '../hand'
import InputControls from '../inputControls'
import SideBar from '../sideBar'
import EndGameText from './EndGameText'
import PlayAreaContainer from './PlayAreaContainer'
import PlayAreaMask from './PlayAreaMask'
import TurnIndicator from './TurnIndicator'
import { IActionStatus, IDamageResult } from './types'
import {
  getAttackDamage,
  getCardsFromDeck,
  getRandomCardsFromPool,
  removeActiveCardsFromHand,
} from './utils'

interface IProps {
  cardPool: ICard[]
  endGame: () => void
  selectedLanguage: string
}

const PlayArea = ({ cardPool, endGame, selectedLanguage }: IProps) => {
  const dispatch = useDispatch()

  const currentTurn = useSelector(selectCurrentTurn)
  const notifications = useSelector(selectNotifications)

  // General state
  const [deck, setDeck] = useState<ICard[]>([])
  const [currentInput, setCurrentInput] = useState<string>('')
  const [lastInput, setLastInput] = useState<string>('')
  const [gameStarted, setGameStarted] = useState<boolean>(false)
  const [damageResult, setDamageResult] = useState<IDamageResult>({
    total: 0,
    damageDealer: CurrentTurn.Player,
  })
  const [showFeedback, setShowFeedback] = useState<boolean>(false)
  const [victor, setVictor] = useState<Victor>()

  // Player state
  const [playerHitPoints, setPlayerHitPoints] = useState<number>(100)
  const [playerHand, setPlayerHand] = useState<ICard[]>([])
  const [playerActiveCards, setPlayerActiveCards] = useState<ICard[]>([])
  const [playerAttackResponse, setPlayerAttackResponse] =
    useState<IPlayerActionResponse>()
  const [playerDefenceResponse, setPlayerDefenceResponse] =
    useState<IPlayerActionResponse>()
  const [playerActionStatus, setPlayerActionStatus] = useState<IActionStatus>({
    requestStatus: RequestStatus.Idle,
    turnStatus: TurnStatus.Idle,
  })

  // AI state
  const [aiHitPoints, setAIHitPoints] = useState<number>(100)
  const [aiHand, setAIHand] = useState<ICard[]>([])
  const [aiActiveCards, setAIActiveCards] = useState<ICard[]>([])
  const [aiAttackResponse, setAIAttackResponse] = useState<string>('')
  const [aiActionStatus, setAIActionStatus] = useState<IActionStatus>({
    requestStatus: RequestStatus.Idle,
    turnStatus: TurnStatus.Idle,
  })

  useEffect(() => {
    startGame()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fix this whole useEffect
  useEffect(() => {
    if (!aiHitPoints || !playerHitPoints || (gameStarted && !deck.length)) {
      endGame()

      if (
        aiActionStatus.turnStatus !== TurnStatus.Paused &&
        aiActionStatus.turnStatus !== TurnStatus.Ended
      ) {
        setAIActionStatus({
          requestStatus: RequestStatus.Idle,
          turnStatus: TurnStatus.Ended,
        })
      }

      setPlayerActionStatus({
        requestStatus: RequestStatus.Idle,
        turnStatus: TurnStatus.Ended,
      })

      if (aiHitPoints < playerHitPoints) {
        setVictor(Victor.Player)
      }

      if (aiHitPoints > playerHitPoints) {
        setVictor(Victor.AI)
      }

      if (aiHitPoints === playerHitPoints) {
        setVictor(Victor.Tie)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiActionStatus, aiHitPoints, playerHitPoints, deck, gameStarted])

  useEffect(() => {
    if (currentTurn === CurrentTurn.AI) {
      let requestStatus = RequestStatus.Sent
      let turnStatus = TurnStatus.Idle

      setAIAttackResponse('')
      setAIActionStatus({
        requestStatus,
        turnStatus,
      })

      // Choose a random number of cards between 1 and 3 for the AI to attack with
      const numberOfCards = Math.floor(Math.random() * 3) + 1
      const aiCards = getRandomCardsFromPool(aiHand, numberOfCards)

      axios
        .post('https://scrai-930ca0ac412a.herokuapp.com/api/ai-attack', {
          content: { cards: aiCards, language: selectedLanguage },
        })
        .then((res) => {
          requestStatus = RequestStatus.Completed

          setAIAttackResponse(res.data.sentence)
          setAIActionStatus({
            requestStatus,
            turnStatus,
          })
        })
        .catch((err) => {
          console.error(err)
        })

      setTimeout(() => {
        setAIActiveCards(aiCards)

        turnStatus = TurnStatus.Started

        setTimeout(() => {
          setAIActionStatus({
            requestStatus:
              requestStatus === RequestStatus.Completed ||
              requestStatus === RequestStatus.Idle
                ? RequestStatus.Idle
                : RequestStatus.Sent,
            turnStatus,
          })
        }, 2000)
      }, 1500)
    } else if (currentTurn === CurrentTurn.Player) {
      setPlayerActionStatus({
        requestStatus: RequestStatus.Idle,
        turnStatus: TurnStatus.Started,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTurn])

  useEffect(() => {
    if (
      aiHand.some((card) => !card.dealt) ||
      playerHand.some((card) => !card.dealt)
    ) {
      setTimeout(() => {
        const dealtAIHand = aiHand.map((card) => ({
          ...card,
          dealt: true,
          show: currentTurn === CurrentTurn.AI,
        }))
        const dealtPlayerHand = playerHand.map((card) => ({
          ...card,
          dealt: true,
          show: currentTurn === CurrentTurn.Player,
        }))

        setPlayerHand(dealtPlayerHand)
        setAIHand(dealtAIHand)

        dispatch(updatePlayerHand(dealtPlayerHand))
        dispatch(updateAIHand(dealtAIHand))
      }, 250)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiHand, playerHand])

  useEffect(() => {
    if (aiAttackResponse) {
      setAIActionStatus({
        ...aiActionStatus,
        requestStatus: RequestStatus.Idle,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiAttackResponse])

  useEffect(() => {
    if (playerAttackResponse) {
      const { correct } = playerAttackResponse
      const newNotifications: INotification[] = []

      if (correct) {
        const damage = getAttackDamage(playerActiveCards)
        const newHitPoints = aiHitPoints - damage
        const hitPointsToDisplay = newHitPoints >= 0 ? newHitPoints : 0

        newNotifications.push({
          id: notifications.length + newNotifications.length + 1,
          shown: false,
          type: NotificationType.Attack,
          content: `${damage}`,
          target: Target.AI,
        })

        setTimeout(() => {
          setAIHitPoints(hitPointsToDisplay)
        }, 750)

        setDamageResult({ total: damage, damageDealer: CurrentTurn.Player })

        if (hitPointsToDisplay) {
          dispatch(updateCurrentTurn(CurrentTurn.AI))
        }
      } else {
        // Attack failed
        newNotifications.push({
          id: notifications.length + newNotifications.length + 1,
          shown: false,
          type: NotificationType.Defence,
          content: 'Block!',
          target: Target.AI,
        })

        setDamageResult({ total: 0, damageDealer: CurrentTurn.Player })
        setLastInput(currentInput)
      }

      const updatedPlayerHand = removeActiveCardsFromHand(
        playerHand,
        playerActiveCards,
      )

      setShowFeedback(true)
      setPlayerHand(updatedPlayerHand)
      dispatch(updatePlayerHand(updatedPlayerHand))

      const newCards = getCardsFromDeck(deck, playerActiveCards.length)

      newNotifications.push({
        id: notifications.length + newNotifications.length + 1,
        shown: false,
        type: NotificationType.Cards,
        content: `${newCards.length}`,
        target: Target.Player,
      })

      dispatch(updateNotifications([...notifications, ...newNotifications]))

      setTimeout(() => {
        setDamageResult({ ...damageResult, total: 0 })
        setShowFeedback(false)
        setPlayerHand([...updatedPlayerHand, ...newCards])
        dispatch(updatePlayerHand([...updatedPlayerHand, ...newCards]))
      }, 2000)

      const updatedDeck = getCardsFromDeck(
        deck,
        deck.length,
        playerActiveCards.length,
      )

      setDeck(updatedDeck)
      setPlayerActiveCards([])
      setCurrentInput('')
      setPlayerActionStatus({
        requestStatus: RequestStatus.Idle,
        turnStatus: correct ? TurnStatus.Ended : TurnStatus.Paused,
      })

      dispatch(updateDeck(updatedDeck))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerAttackResponse])

  useEffect(() => {
    if (playerDefenceResponse) {
      const { correct } = playerDefenceResponse
      const newNotifications: INotification[] = []

      if (correct) {
        newNotifications.push({
          id: notifications.length + newNotifications.length + 1,
          shown: false,
          type: NotificationType.Defence,
          content: 'Block!',
          target: Target.Player,
        })

        setDamageResult({ total: 0, damageDealer: CurrentTurn.AI })

        dispatch(updateCurrentTurn(CurrentTurn.Player))
      } else {
        // Defence failed
        const damage = getAttackDamage(aiActiveCards)
        const newHitPoints = playerHitPoints - damage
        const hitPointsToDisplay = newHitPoints >= 0 ? newHitPoints : 0

        newNotifications.push({
          id: notifications.length + newNotifications.length + 1,
          shown: false,
          type: NotificationType.Attack,
          content: `${damage}`,
          target: Target.Player,
        })

        setTimeout(() => {
          setPlayerHitPoints(hitPointsToDisplay)
        }, 750)

        setDamageResult({ total: damage, damageDealer: CurrentTurn.AI })
        setLastInput(currentInput)
      }

      const updatedAIHand = removeActiveCardsFromHand(aiHand, aiActiveCards)

      setShowFeedback(true)
      setAIHand(updatedAIHand)
      dispatch(updateAIHand(updatedAIHand))

      const newCards = getCardsFromDeck(deck, aiActiveCards.length)

      newNotifications.push({
        id: notifications.length + newNotifications.length + 1,
        shown: false,
        type: NotificationType.Cards,
        content: `${newCards.length}`,
        target: Target.AI,
      })

      dispatch(updateNotifications([...notifications, ...newNotifications]))

      setTimeout(() => {
        setDamageResult({ ...damageResult, total: 0 })
        setShowFeedback(false)
        setAIHand([...updatedAIHand, ...newCards])
        dispatch(updateAIHand([...updatedAIHand, ...newCards]))
      }, 2000)

      const updatedDeck = getCardsFromDeck(
        deck,
        deck.length,
        aiActiveCards.length,
      )

      setDeck(updatedDeck)
      setAIActiveCards([])
      setPlayerActiveCards([])
      setCurrentInput('')
      setAIActionStatus({
        requestStatus: RequestStatus.Idle,
        turnStatus: correct ? TurnStatus.Ended : TurnStatus.Paused,
      })
      setPlayerActionStatus({
        ...playerActionStatus,
        requestStatus: RequestStatus.Idle,
      })

      dispatch(updateDeck(updatedDeck))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerDefenceResponse])

  const startGame = () => {
    // Create the deck for the game
    const startingDeck = getRandomCardsFromPool(cardPool, 60)

    // Draw the top 5 cards from the deck into the players hand
    const startingPlayerHand = getCardsFromDeck(startingDeck, 5)
    let updatedDeck = getCardsFromDeck(startingDeck, startingDeck.length, 5)

    // Draw the next 5 cards from the deck into the AI's hand
    const startingAIHand = getCardsFromDeck(updatedDeck, 5)
    updatedDeck = getCardsFromDeck(updatedDeck, updatedDeck.length, 5)

    setDeck(updatedDeck)
    setPlayerHand(startingPlayerHand)
    setAIHand(startingAIHand)
    setPlayerActionStatus({
      ...playerActionStatus,
      turnStatus: TurnStatus.Started,
    })

    dispatch(updateDeck(updatedDeck))
    dispatch(updatePlayerHand(startingPlayerHand))
    dispatch(updateAIHand(startingAIHand))
    dispatch(updateCurrentTurn(CurrentTurn.Player))
    dispatch(
      updateNotifications([
        ...notifications,
        {
          id: notifications.length + 1,
          shown: false,
          type: NotificationType.Cards,
          content: '5',
          target: Target.Both,
        },
      ]),
    )

    setTimeout(() => {
      setGameStarted(true)
    }, 750)
  }

  const resetGame = () => {
    setDeck([])
    setCurrentInput('')
    setLastInput('')
    setGameStarted(false)
    setDamageResult({
      total: 0,
      damageDealer: CurrentTurn.Player,
    })

    setPlayerHitPoints(100)
    setPlayerHand([])
    setPlayerActiveCards([])
    setPlayerAttackResponse(undefined)
    setPlayerDefenceResponse(undefined)
    setPlayerActionStatus({
      requestStatus: RequestStatus.Idle,
      turnStatus: TurnStatus.Idle,
    })

    setAIHitPoints(100)
    setAIHand([])
    setAIActiveCards([])
    setAIAttackResponse('')
    setAIActionStatus({
      requestStatus: RequestStatus.Idle,
      turnStatus: TurnStatus.Idle,
    })

    startGame()
  }

  const handleAttack = () => {
    setPlayerActionStatus({
      ...playerActionStatus,
      requestStatus: RequestStatus.Sent,
    })

    axios
      .post('https://scrai-930ca0ac412a.herokuapp.com/api/player-attack', {
        content: { input: currentInput, language: selectedLanguage },
      })
      .then((res) => {
        let { correct, correction } = res.data as IPlayerActionResponse

        if (!correct) {
          correct = checkCorrection(correction)
        }

        setPlayerAttackResponse({ correct, correction })
        setPlayerActionStatus({
          ...playerActionStatus,
          requestStatus: RequestStatus.Completed,
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const handleDefence = () => {
    setPlayerActionStatus({
      ...playerActionStatus,
      requestStatus: RequestStatus.Sent,
    })

    axios
      .post('https://scrai-930ca0ac412a.herokuapp.com/api/player-defence', {
        content: {
          input: currentInput,
          sentence: aiAttackResponse,
          language: selectedLanguage,
        },
      })
      .then((res) => {
        let { correct, correction } = res.data as IPlayerActionResponse

        if (!correct) {
          correct = checkCorrection(correction)
        }

        setPlayerDefenceResponse({ correct, correction })
        setPlayerActionStatus({
          ...playerActionStatus,
          requestStatus: RequestStatus.Completed,
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  // Simplify this code
  const handleReviewContinue = () => {
    if (currentTurn === CurrentTurn.Player) {
      setPlayerActionStatus({
        ...playerActionStatus,
        turnStatus: TurnStatus.Ended,
      })

      setTimeout(() => {
        const dealtPlayerHand = playerHand.map((card) => ({
          ...card,
          show: false,
        }))

        setPlayerHand(dealtPlayerHand)
        dispatch(updatePlayerHand(dealtPlayerHand))

        const dealtAIHand = aiHand.map((card) => ({
          ...card,
          show: true,
        }))

        setAIHand(dealtAIHand)
        dispatch(updateAIHand(dealtAIHand))
      }, 250)

      dispatch(updateCurrentTurn(CurrentTurn.AI))
    } else {
      setAIActionStatus({
        ...aiActionStatus,
        turnStatus: TurnStatus.Ended,
      })

      setTimeout(() => {
        const dealtAIHand = aiHand.map((card) => ({
          ...card,
          show: false,
        }))

        setAIHand(dealtAIHand)
        dispatch(updateAIHand(dealtAIHand))

        const dealtPlayerHand = playerHand.map((card) => ({
          ...card,
          show: true,
        }))

        setPlayerHand(dealtPlayerHand)
        dispatch(updatePlayerHand(dealtPlayerHand))
      }, 250)

      if (playerHitPoints) {
        dispatch(updateCurrentTurn(CurrentTurn.Player))
      }
    }
  }

  const checkCorrection = (correction: string) => {
    console.log('correction: ', correction, ' input: ', currentInput)
    console.log(
      'clean correction: ',
      getCleanString(correction),
      ' clean input: ',
      getCleanString(currentInput),
    )
    return getCleanString(correction) === getCleanString(currentInput)
  }

  const getEndGameText = () => {
    if (victor === Victor.Player) {
      return 'Victory!'
    }

    if (victor == Victor.AI) {
      return 'Defeat!'
    }

    return 'Tie!'
  }

  return (
    <PlayAreaContainer>
      <PlayAreaMask
        show={
          aiActionStatus.turnStatus === TurnStatus.Ended &&
          playerActionStatus.turnStatus === TurnStatus.Ended
        }
      >
        <EndGameText text={getEndGameText()} />
        <Button onClick={resetGame} text={'Play again'} />
      </PlayAreaMask>
      <SideBar hitPoints={aiHitPoints} position={Position.Left} />
      <TurnIndicator
        currentTurn={currentTurn}
        gameStarted={gameStarted}
        position={Position.Top}
      />
      <Hand
        key="aiCards"
        actionStatus={aiActionStatus}
        activeCards={aiActiveCards}
        currentInput={currentInput}
        aiAttackResponse={aiAttackResponse}
        damageResult={damageResult}
        hand={aiHand}
        handleReviewContinue={handleReviewContinue}
        lastInput={lastInput}
        position={Position.Top}
        playerActionResponse={playerDefenceResponse}
        setCurrentInput={setCurrentInput}
        setPlayerActiveCards={setPlayerActiveCards}
        showFeedback={showFeedback}
      />
      <InputControls
        aiActionStatus={aiActionStatus}
        gameStarted={gameStarted}
        currentInput={currentInput}
        handleAttack={handleAttack}
        handleDefence={handleDefence}
        handleReviewContinue={handleReviewContinue}
        playerActionStatus={playerActionStatus}
        playerActiveCards={playerActiveCards}
        playerHand={playerHand}
        setPlayerActiveCards={setPlayerActiveCards}
        setCurrentInput={setCurrentInput}
      />
      <Hand
        key="playerCards"
        actionStatus={playerActionStatus}
        activeCards={playerActiveCards}
        currentInput={currentInput}
        damageResult={damageResult}
        hand={playerHand}
        handleReviewContinue={handleReviewContinue}
        lastInput={lastInput}
        position={Position.Bottom}
        playerActionResponse={playerAttackResponse}
        setCurrentInput={setCurrentInput}
        setPlayerActiveCards={setPlayerActiveCards}
        showFeedback={showFeedback}
      />
      <TurnIndicator
        currentTurn={currentTurn}
        gameStarted={gameStarted}
        position={Position.Bottom}
      />
      <SideBar hitPoints={playerHitPoints} position={Position.Right} />
    </PlayAreaContainer>
  )
}

export default PlayArea
