import styled from '@emotion/styled'
import React from 'react'

interface IProps {
  positionAbsolute?: boolean
  show: boolean
  text: string
}

interface IReponseTextSpanProps {
  positionAbsolute?: boolean
  show: boolean
}

const ReponseText = ({ positionAbsolute, show, text }: IProps) => (
  <ReponseTextSpan positionAbsolute={positionAbsolute} show={show}>
    {text}
  </ReponseTextSpan>
)

const ReponseTextSpan = styled.span<IReponseTextSpanProps>(
  ({ positionAbsolute, show }) => ({
    fontSize: '34px',
    color: show ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0)',
    transition: 'color 0.5s ease-in-out',
    width: '80%',
    userSelect: 'none',
    position: positionAbsolute ? 'absolute' : 'relative',
  })
)

export default ReponseText
