import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
  show: boolean
}

interface IInputControlsContainerDivProps {
  show: boolean
}

const InputControlsContainer = ({ children, show }: IProps) => (
  <InputControlsContainerDiv show={show}>{children}</InputControlsContainerDiv>
)

const InputControlsContainerDiv = styled.div<IInputControlsContainerDivProps>(
  ({ show }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    opacity: show ? '1' : '0',
    transition: 'opacity 0.75s ease-in-out',
  })
)

export default InputControlsContainer
