import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  createTransform,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

import { gameStateReducer, userStateReducer } from './state'

const userStateTransform = createTransform<any, any>(
  (inboundState: any, key) => {
    if (key === 'wasRedirected') {
      return inboundState
    }
  },
  (outboundState: any, key) => {
    return outboundState
  },
)

const userPersistConfig = {
  key: 'userState',
  storage: storageSession,
  transforms: [userStateTransform],
}

const rootReducer = combineReducers({
  gameState: gameStateReducer,
  userState: persistReducer(userPersistConfig, userStateReducer),
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)
export default store
