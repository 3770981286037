import { Box } from '@mui/joy'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

interface IProps {
  isMobile: boolean
  setCurrentBoxIndex: (value: number) => void
  setShowHeaderBar: (value: boolean) => void
  setShowScrollArrow: (value: boolean) => void
}

const SnapBoxWrapper = ({
  isMobile,
  setCurrentBoxIndex,
  setShowHeaderBar,
  setShowScrollArrow,
  children,
}: PropsWithChildren<IProps>) => {
  const [currentSnapIndex, setCurrentSnapIndex] = useState(0)
  const boxRef = useRef(null)

  useEffect(() => {
    const box = boxRef.current as HTMLDivElement | null

    const handleScroll = () => {
      if (!box) return

      const scrollTop = box.scrollTop
      const boxHeight = box.scrollHeight
      const snapIndex = Math.round(scrollTop / (boxHeight / 3))

      if (scrollTop > 40) {
        setShowHeaderBar(true)
      } else {
        setShowHeaderBar(false)
      }

      if (boxHeight - scrollTop <= box.offsetHeight + (isMobile ? 0 : 100)) {
        setShowScrollArrow(true)
      } else {
        setShowScrollArrow(false)
      }

      if (snapIndex !== currentSnapIndex) {
        setCurrentSnapIndex(snapIndex)
        setCurrentBoxIndex(snapIndex)
      }
    }

    if (box) {
      box.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (box) {
        box.removeEventListener('scroll', handleScroll)
      }
    }
  }, [
    currentSnapIndex,
    isMobile,
    setCurrentBoxIndex,
    setShowHeaderBar,
    setShowScrollArrow,
  ])

  return (
    <Box
      ref={boxRef}
      height="100vh"
      sx={{
        overflowX: 'hidden',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
      }}
    >
      {children}
    </Box>
  )
}

export default SnapBoxWrapper
