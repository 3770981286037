import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const PlayAreaContainer = ({ children }: IProps) => (
  <PlayAreaContainerDiv>{children}</PlayAreaContainerDiv>
)

const PlayAreaContainerDiv = styled.div`
  background-color: #202020;
  width: 1280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 200px;

  @media (max-width: 1280px), (max-height: 800px) {
    width: 1024px;
  }
`

export default PlayAreaContainer
