import { Typography } from '@mui/joy'
import React, { useState } from 'react'

import TextHighlight from '../../__components/TextHighlight'
import TwoSidedLayout from '../../__components/TwoSidedLayout'

interface IProps {
  isFocused: boolean
}

const LearnHowBlock01 = ({ isFocused }: IProps) => {
  const [hasFocused, setHasFocused] = useState(false)

  return (
    <TwoSidedLayout
      fullHeight
      isFocused={isFocused}
      hasFocused={hasFocused}
      setHasFocused={setHasFocused}
      heroImage={require('../../../assets/images/learn-how-1.jpg')}
      heroHeaderImage={require('../../../assets/images/learn-how-1.jpg')}
    >
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        The quest begins...
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 5rem)"
      >
        Introduction
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Welcome to <Typography fontWeight="lg">Scraiku</Typography>, the
        language learning card game!
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Prepare to embark on an exciting journey where you must{' '}
        <TextHighlight>harness the power</TextHighlight> of words in order to
        master new languages.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Rounds of <Typography fontWeight="lg">Scraiku</Typography> are broken up
        into <TextHighlight>two phases</TextHighlight>, that test your language
        skills in different ways.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Are you ready to dive into the world of{' '}
        <TextHighlight>linguistic strategy</TextHighlight> and{' '}
        <TextHighlight>deck building</TextHighlight>?
      </Typography>
    </TwoSidedLayout>
  )
}

export default LearnHowBlock01
