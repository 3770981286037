import styled from '@emotion/styled'
import { FestivalRounded } from '@mui/icons-material'
import {
  Box,
  CssBaseline,
  CssVarsProvider,
  Typography,
  useColorScheme,
} from '@mui/joy'
import React, { useState } from 'react'

import { IMenuItem } from '../../types'
import HeaderBar from '../__components/HeaderBar'
import ScrollArrow from '../__components/ScrollArrow'
import ScrollIndicator from '../__components/ScrollIndicator'
import SnapBoxWrapper from '../__components/SnapBoxWrapper'
import framesxTheme from '../theme'
import LearnHowBlock01 from './blocks/LearnHowBlock01'
import LearnHowBlock02 from './blocks/LearnHowBlock02'
import LearnHowBlock03 from './blocks/LearnHowBlock03'
import LearnHowBlock05 from './blocks/LearnHowBlock05'

interface IBackgroundGradientDivProps {
  colourScheme: string | undefined
  isMobile?: boolean
  isReversed?: boolean
}

const LearnHow = () => {
  const { mode: colourScheme } = useColorScheme()

  const [currentBoxIndex, setCurrentBoxIndex] = useState<number>(0)
  const [showHeaderBar, setShowHeaderBar] = useState<boolean>(false)
  const [showScrollArrow, setShowScrollArrow] = useState<boolean>(false)

  const isMobile = window.innerWidth < 834
  const menuItems: IMenuItem[] = [
    {
      label: 'My Camp',
      url: '/my-camp',
      icon: <FestivalRounded />,
      protectedRoute: true,
      showBottomDivider: true,
    },
    {
      label: 'Scraiku',
      url: '/',
    },
    {
      label: 'Learn to Play',
      url: '/learn-how',
    },
    {
      label: 'Explore Seasons',
      url: '/seasons',
    },
  ]

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <ScrollArrow showScrollArrow={showScrollArrow} />
      {!isMobile && (
        <ScrollIndicator currentBoxIndex={currentBoxIndex} numberOfBoxes={3} />
      )}
      <CssBaseline />
      <HeaderBar
        colourScheme={colourScheme}
        currentPage={'/learn-how'}
        isMobile={isMobile}
        menuItems={menuItems}
        show={showHeaderBar}
      />
      <SnapBoxWrapper
        isMobile={isMobile}
        setCurrentBoxIndex={setCurrentBoxIndex}
        setShowHeaderBar={setShowHeaderBar}
        setShowScrollArrow={setShowScrollArrow}
      >
        <Box
          id={'topBox'}
          position="relative"
          bgcolor={colourScheme === 'light' ? '#fff' : '#202020'}
        >
          <BackgroundGradientDiv
            colourScheme={colourScheme}
            isMobile={isMobile}
          />
          <LearnHowBlock01 isFocused={currentBoxIndex === 0} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          height="200px"
          bgcolor={colourScheme === 'light' ? '#e8eaf3' : '#1c1c1c'}
        >
          <Typography
            level="h1"
            fontWeight="xl"
            fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 4rem)"
          >
            How it works
          </Typography>
        </Box>
        <Box
          position="relative"
          bgcolor={colourScheme === 'light' ? '#fff' : '#202020'}
        >
          <BackgroundGradientDiv
            colourScheme={colourScheme}
            isMobile={isMobile}
            isReversed
          />
          <LearnHowBlock02
            isFocused={currentBoxIndex === 1}
            isMobile={isMobile}
          />
          <LearnHowBlock03
            isFocused={currentBoxIndex === 1}
            isMobile={isMobile}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          height="200px"
          bgcolor={colourScheme === 'light' ? '#e8eaf3' : '#1c1c1c'}
        >
          <Typography
            level="h1"
            fontWeight="xl"
            fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 4rem)"
          >
            It doesn&apos;t end there!
          </Typography>
        </Box>
        <Box
          position="relative"
          bgcolor={colourScheme === 'light' ? '#fff' : '#202020'}
        >
          <BackgroundGradientDiv
            colourScheme={colourScheme}
            isMobile={isMobile}
          />
          <LearnHowBlock05 isFocused={currentBoxIndex === 2} />
        </Box>
      </SnapBoxWrapper>
    </CssVarsProvider>
  )
}

const BackgroundGradientDiv = styled.div<IBackgroundGradientDivProps>(
  ({ colourScheme, isMobile, isReversed }) => ({
    position: 'absolute',
    width: isMobile ? '100%' : '50%',
    height: '100%',
    left: !isMobile && isReversed ? '50%' : '',
    backgroundColor: colourScheme === 'light' ? '#b8c6db' : '#33658A',
    backgroundImage:
      colourScheme === 'light'
        ? 'linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)'
        : 'linear-gradient(315deg, #33658A 0%, #170e13 74%)',
    zIndex: '0',
  }),
)

export default LearnHow
