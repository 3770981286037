import { Box, Divider, Typography } from '@mui/joy'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { IUserProfile } from '../../../../state/types'
import ActionButton from '../../../__components/ActionButton'

interface IProps {
  userProfile: Partial<IUserProfile>
}

const PlayBox = ({ userProfile }: IProps) => {
  const { currentLanguage } = userProfile

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box mt={2}>
        <Typography fontSize="lg" fontWeight="lg" mb={2}>
          Face your first opponent!
        </Typography>
        <RouterLink to={'/play'} state={{ currentLanguage }}>
          <ActionButton text={'Begin battle'} width={200} />
        </RouterLink>
      </Box>
      <Divider sx={{ my: 4 }} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        position="relative"
        height="100%"
      >
        <Box
          display="flex"
          justifyContent="center"
          height="100%"
          width="100%"
          position="absolute"
          zIndex={2}
          sx={{
            backdropFilter: 'blur(8px)',
          }}
        >
          <Typography fontSize="lg" fontWeight="lg">
            Side Quests - Coming soon!
          </Typography>
        </Box>
        <Box mb={4} mt={4}>
          <Typography fontSize="lg" fontWeight="lg" mb={2}>
            Take on a Side Quest!
          </Typography>
          <RouterLink to={'/my-camp'}>
            <ActionButton
              alternateColour
              text={'View side quests'}
              width={200}
            />
          </RouterLink>
        </Box>
        <Box mb={4}>
          <Typography fontSize="lg" fontWeight="lg" mb={2}>
            Attempt Daily Challenge!
          </Typography>
          <RouterLink to={'/my-camp'}>
            <ActionButton
              alternateColour
              text={'Begin challenge'}
              width={200}
            />
          </RouterLink>
        </Box>
      </Box>
    </Box>
  )
}

export default PlayBox
