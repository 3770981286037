import { Box, Divider, Typography } from '@mui/joy'
import React from 'react'

import { IUserProfile } from '../../../../state/types'
import ActionButton from '../../../__components/ActionButton'

interface IProps {
  userProfile: Partial<IUserProfile>
}

const CardsBox = ({ userProfile }: IProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box mt={2}>
        <Typography fontSize="lg" fontWeight="lg" mb={2}>
          My cards
        </Typography>
        <ActionButton text={'Manage decks'} width={200} />
      </Box>
      <Divider sx={{ my: 4 }} />
      <Box height="100%" position="relative">
        <Box
          display="flex"
          justifyContent="center"
          height="100%"
          width="100%"
          position="absolute"
          zIndex={2}
          sx={{
            backdropFilter: 'blur(8px)',
          }}
        >
          <Typography fontSize="lg" fontWeight="lg">
            Marketplace - Coming soon!
          </Typography>
        </Box>
        {/* <Typography fontSize="lg" fontWeight="lg" mb={2}>
          Marketplace
        </Typography> */}
        <Box
          display="flex"
          justifyContent="space-evenly"
          height="80%"
          mt={4}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            maxWidth="250px"
            width="100%"
            borderRadius={8}
            boxShadow="md"
            mr={4}
            sx={{
              backgroundImage:
                'linear-gradient(315deg, #ff4e00 0%, #ec9f05 74%)',
            }}
          >
            <Typography fontSize="lg" fontWeight="lg">
              Booster Pack 1
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            maxWidth="250px"
            width="100%"
            borderRadius={8}
            boxShadow="md"
            sx={{
              backgroundImage:
                'linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%)',
            }}
          >
            <Typography fontSize="lg" fontWeight="lg">
              Booster Pack 2
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CardsBox
