import { Typography } from '@mui/joy'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ActionButton from '../../__components/ActionButton'
import TextHighlight from '../../__components/TextHighlight'
import TwoSidedLayout from '../../__components/TwoSidedLayout'

interface IProps {
  isFocused: boolean
}

const HeroBlock02 = ({ isFocused }: IProps) => {
  const [hasFocused, setHasFocused] = useState(false)

  return (
    <TwoSidedLayout
      isReversed
      isFocused={isFocused}
      hasFocused={hasFocused}
      setHasFocused={setHasFocused}
      heroImage={require('../../../assets/images/hero-1.jpg')}
    >
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        Will you accept the challenge?
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Forge Your Path to Fluency!
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Welcome to a world where language learning meets{' '}
        <TextHighlight>epic card battles</TextHighlight>!
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        No more dull memorization – immerse yourself in{' '}
        <TextHighlight>dynamic encounters</TextHighlight> where every sentence
        crafted and translated is a step closer to fluency.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Defeat opponents, <TextHighlight>gather cards</TextHighlight>, construct
        your deck and venture forth into the world of{' '}
        <Typography fontWeight="lg">Scraiku</Typography>!
      </Typography>
      <RouterLink to={'/learn-how'} style={{ marginTop: '20px' }}>
        <ActionButton text={'Learn How'} />
      </RouterLink>
    </TwoSidedLayout>
  )
}

export default HeroBlock02
